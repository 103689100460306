import React, { FunctionComponent, ReactNode } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { Divider } from '@wh-components/core/Divider/Divider'
import { FlexItemProps } from '@wh-components/system/flexbox'
import { DisplayProps } from '@wh-components/system/layout'
import { SpaceProps } from '@wh-components/system/space'
import { printDisplayBlockFirefoxWorkaroundCss } from '@wh/common/chapter/components/Printing/printDisplayBlockFirefoxWorkaroundCss'
import { isValueObject } from '@wh-components/system'

export interface TwoColumnRowProps {
    separator?: boolean
    left: ReactNode
    right: ReactNode
    layout?: '300px 1fr' | '1fr 1fr' | '1fr 300px'
    mobileColumnReverse?: boolean
}

export const TwoColumnRow: FunctionComponent<TwoColumnRowProps & Pick<FlexItemProps, 'order'> & DisplayProps & SpaceProps> = ({
    order,
    separator = false,
    mobileColumnReverse = false,
    layout = '1fr 300px',
    left,
    right,
    display = 'flex',
    ...space
}) => {
    const flex = (() => {
        switch (layout) {
            case '300px 1fr':
                return {
                    left: { print: '1 1 auto', phone: '1 1 auto', tablet: '0 0 300px' },
                    right: { print: '1 1 auto', phone: '1 1 auto', tablet: '1 1 100%' },
                }
            case '1fr 1fr':
                return { left: { phone: '1 0 auto', print: '1 0 auto' }, right: { phone: '1 0 auto', print: '1 0 auto' } }
            case '1fr 300px':
                return {
                    left: { print: '1 1 auto', phone: '1 1 auto', tablet: '1 1 100%' },
                    right: { print: '1 1 auto', phone: '1 1 auto', tablet: '0 0 300px' },
                }
        }
    })()

    return (
        <Box
            as="section"
            order={order}
            display={display}
            flexDirection="column"
            {...space}
            css={
                display === 'flex' || (isValueObject(display) && display.print === 'flex')
                    ? printDisplayBlockFirefoxWorkaroundCss
                    : undefined
            }
        >
            <Box
                marginBottom={separator ? 'l' : ''}
                display="flex"
                flexDirection={{ phone: 'column', tablet: 'row', print: 'column' }}
                css={printDisplayBlockFirefoxWorkaroundCss}
            >
                <Box
                    order={mobileColumnReverse ? { phone: 1, tablet: 0 } : 0}
                    flex={flex.left}
                    overflow="hidden"
                    marginBottom={mobileColumnReverse ? undefined : { phone: 'm', tablet: '0', print: 'm' }}
                >
                    {left}
                </Box>
                <Box
                    order={mobileColumnReverse ? { phone: 0, tablet: 1 } : 1}
                    flex={flex.right}
                    overflow="hidden"
                    marginBottom={mobileColumnReverse ? { phone: 'm', tablet: '0', print: 'm' } : undefined}
                    marginLeft={{ tablet: 'm', print: 0 }}
                >
                    {right}
                </Box>
            </Box>
            {separator && <Divider />}
        </Box>
    )
}

TwoColumnRow.defaultProps = {
    marginBottom: 'm',
}

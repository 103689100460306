import { useResponsiveValue } from '@wh-components/core/utilities/responsive'
import { scrollToElement } from '@wh/common/chapter/lib/commonHelpers'
import { FIXED_HEADER_HEIGHT_PHONE } from '@wh/common/chapter/lib/config/constants'
import { FormikErrors } from 'formik'
import { useCallback, useEffect } from 'react'
import { errorSelector, errorSelectorFallback } from './useErrorFocus.selector'

/** taken from https://gist.github.com/dphrag/4db3b453e02567a0bb52592679554a5b#gistcomment-3398497 */
const keyifyFormikErrors = (obj: { [key: string]: unknown }, prefix = ''): Array<string> =>
    Object.keys(obj).reduce((res, el) => {
        // we do not need Arrays as errors should be nested in objects
        // this should not happen and is just a saveguard
        if (Array.isArray(obj[el])) {
            return res
        }

        // resolve nested objects with with prefixed name
        if (typeof obj[el] === 'object' && obj[el] !== null) {
            return [...res, ...keyifyFormikErrors(obj[el] as { [key: string]: unknown }, `${prefix}${el}.`)]
        }

        return [...res, prefix + el]
    }, [] as Array<string>)

/** Based on: https://robinvdvleuten.nl/blog/scroll-a-react-component-into-view/ */
export const useErrorFocus = (isSubmitting: boolean, isValidating: boolean, errors: FormikErrors<unknown>) => {
    // get headerHeight for scrolling offset
    const headerHeight = useResponsiveValue({ phone: FIXED_HEADER_HEIGHT_PHONE, tablet: 100 }, 0)

    const scrollToFirstInvalidField = useCallback(() => {
        // Get all keys of the error messages.
        const keys = keyifyFormikErrors(errors)

        if (keys.length > 0) {
            let errorElement: HTMLElement | null
            try {
                // craft selector that matches all error elements
                // data-fieldid is matched with `~=` because that allows us to match one of multiple attributes in a space separated list
                // open details fields are not matched, because then we rather want to scroll to the contained field that has a validation error
                const allErrorsSelector = keys.map(errorSelector).join(', ')
                // We grab the first element that error by its name.
                errorElement = document.querySelector(allErrorsSelector)
            } catch (_e) {
                // fallback for browsers that do not yet support `:has`
                const allErrorsSelector = keys.map(errorSelectorFallback).join(', ')
                errorElement = document.querySelector(allErrorsSelector)
            }

            // When there is an input, scroll this input into view.
            if (errorElement) {
                // add 35 px to account for default label height, we cant directly scroll to the label since not all inputs have them implemented in the same way
                const offsetTop = headerHeight + 35
                scrollToElement(errorElement, 'start', 'always', offsetTop).then(() => {
                    errorElement.focus()
                })
            }
        }
    }, [errors, headerHeight])

    useEffect(() => {
        // Whenever there are errors and the form is submitting but finished validating.
        if (isSubmitting && !isValidating) {
            scrollToFirstInvalidField()
        }
    }, [isSubmitting, isValidating, scrollToFirstInvalidField])

    return { scrollToFirstInvalidField }
}

import React, { FunctionComponent, PropsWithChildren } from 'react'
import { css } from 'styled-components'
import { SpaceProps } from '@wh-components/system/space'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { Badge } from '@wh-components/core/Badge/Badge'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { crossBrowserBreakWordStyles } from '@wh-components/core/Text/Text'

interface SellerInfoButtonProps extends SpaceProps, PropsWithChildren {
    link: string
    activeAdCount?: number
    clickTaggingActionEvent: 'addetail_more_ads_click' | 'addetail_dealer_profile_click'
    taggingData: TaggingData
    testIdPrefix?: string
    onClick?: () => void
}

export const SellerInfoButton: FunctionComponent<SellerInfoButtonProps> = ({
    link,
    activeAdCount,
    clickTaggingActionEvent,
    taggingData,
    testIdPrefix,
    children,
    onClick,
    ...props
}) => (
    <ClientRoutingAnchorLink
        href={link}
        type="button"
        display={{ print: 'none' }}
        size="small"
        variant="outline"
        testId={`${testIdPrefix}-contact-box-seller-info-button`}
        onClick={() => {
            callActionEvent(clickTaggingActionEvent, taggingData)
            onClick?.()
        }}
        css={css`
            /* styling for multiline button */
            white-space: normal;
            height: auto;
            min-height: 32px;
            padding-top: 6px;
            padding-bottom: 6px;
            text-align: center;
            ${crossBrowserBreakWordStyles}
        `}
        {...props}
    >
        {children}
        {!!activeAdCount && activeAdCount > 1 && (
            <Badge
                fontSize="xs"
                paddingVertical={0}
                paddingHorizontal="xs"
                marginLeft="xs"
                css={css`
                    flex-shrink: 0;
                `}
            >
                {String(activeAdCount - 1)}
            </Badge>
        )}
    </ClientRoutingAnchorLink>
)

import React, { FunctionComponent, useMemo } from 'react'
import { SpaceProps } from '@wh-components/system/space'
import { LayoutProps } from '@wh-components/system/layout'
import { AdvertDetailsWithFormattedAttributes } from '@bbx/common/types/ad-detail/AdvertDetails'
import { getContactData } from '@bbx/search-journey/sub-domains/ad-detail/lib/getContactData'
import { findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { useProfileTrustSignals } from '@bbx/search-journey/common/lib/useProfileTrustSignals'
import { Card } from '@wh-components/core/Card/Card'
import { Box } from '@wh-components/core/Box/Box'
import { Divider } from '@wh-components/core/Divider/Divider'
import { PriceBox } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/PriceBox'
import { TextLinks } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/TextLinks'
import { PrivateProfile } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/PrivateProfile'
import { AddressBox } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/AddressBox'
import { ContactButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/ContactButton'
import { PhoneNumberButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/PhoneNumberButton'
import { SellerInfoButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/SellerInfoButton'
import { getAutoMotorAdvertPrice } from '@bbx/search-journey/common/lib/getAutoMotorAdvertPrice'
import { getOrganisationProfileWeblink } from '@bbx/search-journey/sub-domains/ad-detail/lib/getLinkToDealerProfile'
import { SellerTypeInfoBox } from '@bbx/search-journey/sub-domains/ad-detail/components/common/SellerTypeInfoBox/SellerTypeInfoBox'

interface AutoMotorContactBoxPrivateTopProps extends SpaceProps, LayoutProps {
    advertDetails: AdvertDetailsWithFormattedAttributes
}

export const AutoMotorContactBoxPrivateTop: FunctionComponent<AutoMotorContactBoxPrivateTopProps> = ({ advertDetails, ...props }) => {
    const { name, contactName, image, userSince, address, phoneNumbers, activeAdCount, contactFirstName } = useMemo(
        () => getContactData(advertDetails),
        [advertDetails],
    )
    const { mainPrice, additionalPrices } = useMemo(
        () => getAutoMotorAdvertPrice(advertDetails.advertStatus, advertDetails.formattedAttributes),
        [advertDetails],
    )

    const trustProfileLink = findContextLinkWithId('trustProfileLink', advertDetails.contextLinkList)
    const { replyTime, presenceText } = useProfileTrustSignals(trustProfileLink)

    const organisationProfileWeblink = getOrganisationProfileWeblink(advertDetails.organisationDetails.organisationDetailLinkList)
    const sellerProfileWebLink =
        organisationProfileWeblink ?? `/iad/gebrauchtwagen/verkaeuferprofil/${advertDetails.organisationDetails.id}`

    return (
        <Card padding={0} testId="ad-detail-contact-box-private-top" {...props}>
            <Box display="flex" flexDirection="column" gap="xs" padding="m">
                <PriceBox mainPrice={mainPrice} additionalPrices={additionalPrices} priceLabelDisplay="row" />
                <TextLinks landingPageLinks={advertDetails.adServiceDesktopLandingPageLinks} />
            </Box>
            <Box display={{ phone: 'none', tablet: 'block' }}>
                <Divider marginHorizontal="m" />
                <PrivateProfile
                    name={contactName || name}
                    image={image}
                    userSince={userSince}
                    replyTime={replyTime}
                    presenceText={presenceText}
                    testIdPrefix="top"
                />
                {address && <AddressBox address={address} paddingHorizontal="m" testIdPrefix="top" />}
                <Box display="flex" flexDirection="column" gap="s" padding="m">
                    <ContactButton
                        contactType={advertDetails.contactOption.contactType}
                        taggingData={advertDetails.taggingData}
                        adStatus={advertDetails.advertStatus}
                    >
                        Nachricht
                    </ContactButton>
                    <PhoneNumberButton
                        phoneNumbers={phoneNumbers}
                        toggleWithContext={true}
                        adId={advertDetails.id}
                        taggingData={advertDetails.taggingData}
                        testIdPrefix="top"
                    />
                    <SellerInfoButton
                        link={sellerProfileWebLink}
                        activeAdCount={activeAdCount}
                        clickTaggingActionEvent="addetail_more_ads_click"
                        taggingData={advertDetails.taggingData}
                        testIdPrefix="top"
                    >
                        Weitere Anzeigen von {contactFirstName ?? 'diesem Verkäufer'}
                    </SellerInfoButton>
                    <SellerTypeInfoBox attributes={advertDetails.attributes} />
                </Box>
            </Box>
        </Card>
    )
}

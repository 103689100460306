import React, { FunctionComponent, PropsWithChildren } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { css } from 'styled-components'

export const Desktop5050Container: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => (
    <Box
        display="flex"
        flexDirection={{ phone: 'column', desktop: 'row' }}
        flex="1 1 auto"
        css={css`
            & > * {
                width: 100%;
                margin-right: ${(p) => p.theme.space.m}px;

                ${(p) => p.theme.media.desktop} {
                    width: calc(50% - ${(p) => p.theme.space.m / 2}px);
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        `}
    >
        {children}
    </Box>
)

import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { LatLong } from '@bbx/search-journey/common/components/Map/constants'

export const getMapCoordinates = (advertDetails: AdvertDetails): LatLong | undefined => {
    const coordinates = getAttributeValue(advertDetails.attributes.attribute, 'COORDINATES')
    if (coordinates) {
        const latitude = parseFloat(coordinates.split(',')[0])
        const longitude = parseFloat(coordinates.split(',')[1])
        return [latitude, longitude]
    }
    return undefined
}

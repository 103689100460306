import { ContextLink } from '@bbx/common/types/contextLinks'
import { getFormattedTrustSignals } from '@bbx/search-journey/common/api/trustProfileApiClient'
import { useEffect, useState } from 'react'
import { TrustSignals } from '@bbx/search-journey/common/TrustProfile'

export const useProfileTrustSignals = (trustProfileLink: ContextLink | undefined) => {
    const [trustsignals, setTrustsignals] = useState<TrustSignals>({})

    useEffect(() => {
        const fetchTrustProfile = async () => {
            const trustSignals = await getFormattedTrustSignals(trustProfileLink)
            if (trustSignals) {
                setTrustsignals(trustSignals)
            }
        }
        fetchTrustProfile()
    }, [trustProfileLink])

    return trustsignals
}

import { SbBlokData } from '@storyblok/js/dist/types/types'
import { ISbStoryData, StoryblokComponent } from '@storyblok/react'
import { Box } from '@wh-components/core/Box/Box'
import { Button } from '@wh-components/core/Button/Button'
import { Modal } from '@wh-components/core/Modal/Modal'
import { Spinner } from '@wh-components/core/Spinner/Spinner'
import { Text } from '@wh-components/core/Text/Text'
import { ClientRoutingAnchorLink, ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import icon_security from '@wh/common/chapter/static_hashed/img/myprofile/icon_security.svg'
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { css } from 'styled-components'
import { getStoryblokStory } from '../../cms/api/storyblokIadProxyClient'
import { StoryblokEmbeddedContentBlok } from './StoryblokEmbeddedContent'

interface SmsAllowanceModalProps {
    isOpen: boolean
    smsAllowanceType: keyof typeof smsAllowanceStoryblokIds
    onSmsAllowanceAccepted: () => void
    onSmsAllowanceDeclined: () => void
}

const smsAllowanceStoryblokIds = {
    Aza: '173173863',
    ContactAdvertiser: '170144172',
}

export const SmsAllowanceModal: FunctionComponent<SmsAllowanceModalProps> = (props) => {
    const [smsAllowanceStory, setSmsAllowanceStory] = useState<ISbStoryData<StoryblokEmbeddedContentBlok> | undefined>(undefined)
    const [fetchingStoryFailed, setFetchingStoryFailed] = useState(false)
    const isLoading = !(smsAllowanceStory || fetchingStoryFailed)

    useEffect(() => {
        if (!props.isOpen) {
            return
        }
        if (smsAllowanceStory) {
            return
        }

        const fetchStory = async () => {
            try {
                const response = await getStoryblokStory<StoryblokEmbeddedContentBlok>(smsAllowanceStoryblokIds[props.smsAllowanceType])
                if (!response) {
                    throw Error()
                }
                setSmsAllowanceStory(response.story)
            } catch (_error) {
                setFetchingStoryFailed(true)
            }
        }

        fetchStory()
    }, [props.isOpen, props.smsAllowanceType, smsAllowanceStory])

    return (
        <Modal
            header={smsAllowanceStory?.content.title ?? 'Zu deiner Sicherheit'}
            isOpen={props.isOpen}
            testId="sms-allowance-modal"
            onRequestClose={props.onSmsAllowanceDeclined}
            fullScreen={{ phone: true, tablet: false }}
            footer={
                <Box display="flex" justifyContent={{ phone: 'center', tablet: 'flex-end' }}>
                    <Button
                        testId="sms-allowance-accept"
                        size="large"
                        onClick={props.onSmsAllowanceAccepted}
                        css={css`
                            ${(p) => p.theme.media.only.phone} {
                                width: 100%;
                            }
                        `}
                    >
                        Ja, ich stimme zu
                    </Button>
                </Box>
            }
        >
            <Box
                display="flex"
                alignItems="center"
                flexDirection={{ phone: 'column', tablet: 'row' }}
                width={{ tablet: 682 }}
                minHeight={{ tablet: isLoading ? 350 : undefined }}
            >
                <Box maxWidth="100%" marginRight={{ phone: '0px', tablet: 'm' }} marginBottom={{ phone: 'm', tablet: '0px' }}>
                    <img width={92} src={icon_security} alt="" />
                </Box>
                <Box maxWidth="100%" flex="1 1 auto">
                    {smsAllowanceStory && Array.isArray(smsAllowanceStory?.content.body) ? (
                        smsAllowanceStory?.content.body.map((nestedBlok: SbBlokData) => {
                            return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} story={smsAllowanceStory} />
                        })
                    ) : fetchingStoryFailed || (smsAllowanceStory && !Array.isArray(smsAllowanceStory?.content.body)) ? (
                        <FallbackSmsAllowanceContent />
                    ) : (
                        <Box display="flex" justifyContent="center">
                            <Spinner />
                        </Box>
                    )}
                </Box>
            </Box>
        </Modal>
    )
}

const FallbackSmsAllowanceContent = () => (
    <Fragment>
        <Text as="p">
            Um eine mögliche missbräuchliche Nutzung des willhaben-Nachrichtenservices zu verhindern, benötigen wir dein Einverständnis.
            Bitte akzeptiere diese einmalige Zustimmungserklärung, damit Anzeigen erstellt und Anfragen versendet werden können.
        </Text>
        <Text as="p">
            Ich erkläre mich damit einverstanden, dass willhaben alle meine über das willhaben-Nachrichtenservice übermittelten Nachrichten
            und deren Inhalte speichern, sichten und gegebenenfalls modifizieren kann, sowie die von mir angegebenen personenbezogenen Daten
            (z.B. E-Mail-Adresse, Name, Telefonnummer, Adresse) als auch die durch meine Nutzung von willhaben entstandenen Verkehrsdaten
            (insb. IP-Adresse) verarbeitet werden. Zweck dieser Datenverwendung ist die Gewährleistung der Systemsicherheit und die
            Verbesserung der Nutzersicherheit. Bei konkretem Verdacht auf missbräuchliche Nutzung (z.B. Verstöße gegen die
            AGB/Nutzungsbedingungen, illegale, insbesondere betrügerische, Aktivitäten) kann willhaben Nachrichten verzögert oder gar nicht
            zustellen bzw. auf Aufforderung der Strafverfolgungsbehörden hin diese auch an die zuständige Behörde übermitteln. Diese
            Zustimmung kann ich jederzeit schriftlich per E-Mail an{' '}
            <ServerRoutingAnchorLink type="anchor" href="mailto:info@willhaben.at" testId="info-mail-address">
                info@willhaben.at
            </ServerRoutingAnchorLink>{' '}
            widerrufen. willhaben löscht die erhobenen personenbezogenen Daten spätestens 6 Monate nach der letzten Verwendung. Im Übrigen
            akzeptiere ich die{' '}
            <ClientRoutingAnchorLink type="anchor" href="/iad/agb" testId="agb">
                AGB
            </ClientRoutingAnchorLink>{' '}
            der willhaben internet service GmbH u. Co KG.
        </Text>
    </Fragment>
)

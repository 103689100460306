import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import { TestProps } from '@wh-components/core/common'
import { Alert } from '@wh-components/core/Alert/Alert'
import WarningIcon from '@wh-components/icons/AlertWarning'
import { SpaceProps } from '@wh-components/system/space'

interface ErrorAlertProps extends TestProps, SpaceProps, PropsWithChildren {
    title?: string
    message?: ReactNode
}

export const ErrorAlert: FunctionComponent<ErrorAlertProps> = ({ title, message, testId, children, ...spaceProps }) => (
    <Alert title={title} variant="error" Icon={WarningIcon} testId={testId || 'alert-error'} {...spaceProps}>
        {children || message}
    </Alert>
)

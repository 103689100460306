import React, { FunctionComponent, ReactNode } from 'react'
import { SpaceProps } from '@wh-components/system/space'
import { Price } from '@bbx/common/types/Price'
import { isFormattedPrice } from '@wh/common/chapter/lib/formatter'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { RentTotalLoadTooltip } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/RentTotalLoadTooltip'

interface PriceBoxProps extends SpaceProps {
    mainPrice: Price
    secondaryPrice?: Price
    additionalPrices?: Price[]
    priceLabelDisplay?: 'column' | 'row'
    detailsButton?: ReactNode
}

export const PriceBox: FunctionComponent<PriceBoxProps> = ({
    mainPrice,
    secondaryPrice,
    additionalPrices,
    priceLabelDisplay = 'column',
    detailsButton,
    ...props
}) => {
    const prices = [mainPrice, secondaryPrice].filter((p) => Boolean(p?.value))

    if (!prices.length && !additionalPrices?.length) {
        return null
    }

    return (
        <Box display="flex" testId="contact-box-price-box" flexDirection="column" gap="xs" {...props}>
            {!!prices?.length &&
                prices.map(
                    (price, index) =>
                        price?.value && (
                            <Box display="flex" flexDirection={priceLabelDisplay} alignItems="baseline" key={index}>
                                <Text
                                    fontSize={isFormattedPrice(price.value) ? { phone: 'xxl', tablet: 'xxxl' } : 'xl'}
                                    paddingBottom={price.label ? '' : 'm'}
                                    marginRight="s"
                                    fontWeight="bold"
                                    lineHeight={1}
                                    color="palette.primary.main"
                                    testId={`contact-box-price-box-price-value-${index}`}
                                >
                                    {price.value}
                                </Text>
                                {price.label && (
                                    <Text fontSize="s" color="palette.raccoon" testId={`contact-box-price-box-price-label-${index}`}>
                                        {price.label}
                                    </Text>
                                )}
                            </Box>
                        ),
                )}
            {!!additionalPrices?.length && (
                <Box display="flex" flexDirection="column">
                    {additionalPrices.map((price, index) =>
                        price?.value ? (
                            <Text key={index} color="palette.raccoon">
                                <Text
                                    fontSize={{ phone: 'm', tablet: 'l' }}
                                    fontWeight="bold"
                                    testId={`contact-box-price-box-additional-price-value-${index}`}
                                >
                                    {price.value}
                                </Text>
                                {price.label && (
                                    <Text fontSize="s" testId={`contact-box-price-box-additional-price-label-${index}`}>
                                        &nbsp;{price.label}
                                        {price.label === 'Gesamtbelastung' && <RentTotalLoadTooltip />}
                                    </Text>
                                )}
                                {detailsButton && detailsButton}
                            </Text>
                        ) : null,
                    )}
                </Box>
            )}
        </Box>
    )
}

import React, { FunctionComponent } from 'react'
import { SpaceProps } from '@wh-components/system/space'
import { AttributesItem, getAttribute } from '@wh/common/chapter/types/Attributes'
import { AdBoxHeading } from '@bbx/common/components/AdBoxHeading/AdBoxHeading'
import { Box } from '@wh-components/core/Box/Box'
import { Columns } from '@wh-components/core/Columns/Columns'
import { PropertyItem, PropertyList } from '@bbx/common/components/Lists/PropertyList'
import { AttributeName } from '@wh/common/chapter/types/AttributeName'
import { inverseAdTypeIdMap } from '@wh/common/chapter/types/AdType'

interface EquipmentsProps extends SpaceProps {
    attributes: AttributesItem[]
    adTypeId: number
}

export const Equipments: FunctionComponent<EquipmentsProps> = ({ adTypeId, attributes, ...props }) => {
    const equipmentName = getEquipmentName(adTypeId)
    const equipments = getAttribute(attributes, equipmentName)

    if (!equipments) {
        return null
    }

    return (
        <Box paddingHorizontal={{ phone: 'm', tablet: 0 }} {...props}>
            <AdBoxHeading text="Ausstattungen & Extras" marginBottom="s" />
            <Columns columns={{ phone: 1, tablet: 3, desktop: 4 }} gap="m">
                <PropertyList>
                    {equipments.values.map((equipment) => (
                        <PropertyItem key={equipment} testId="equipment">
                            {equipment}
                        </PropertyItem>
                    ))}
                </PropertyList>
            </Columns>
        </Box>
    )
}

const getEquipmentName = (adTypeId: number): AttributeName => {
    switch (inverseAdTypeIdMap[adTypeId]) {
        case 'MotorCar':
            return 'EQUIPMENT'
        case 'MotorMc':
            return 'MC_EQUIPMENT'
        case 'MotorVanTruck':
            return 'VAN_EQUIPMENT'
        case 'MotorCaravan':
            return 'CARAVAN_EQUIPMENT'
        default:
            return 'EQUIPMENT'
    }
}

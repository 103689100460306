import React, { FunctionComponent } from 'react'
import { ErrorAlert } from '@wh/common/chapter/components/Alerts/ErrorAlert'

export const AdIdNotFoundAlert: FunctionComponent = () => {
    return (
        <ErrorAlert
            title="Bitte beachte folgendes:"
            message="Leider konnte die von Ihnen gesuchte Anzeige nicht gefunden werden."
            marginBottom="m"
        />
    )
}

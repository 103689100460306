import React, { FunctionComponent } from 'react'
import { Text } from '@wh-components/core/Text/Text'
import { ButtonWithLinkStyle } from '@wh-components/core/Button/ButtonWithLinkStyle'
import { WarningAlert } from '@wh/common/chapter/components/Alerts/WarningAlert'
import { Box } from '@wh-components/core/Box/Box'
import { SpaceProps } from '@wh-components/system/space'

interface SmsAllowanceErrorProps {
    onRetrySendMessage: () => void
}

export const SmsAllowanceError: FunctionComponent<SmsAllowanceErrorProps> = (props) => (
    <Box marginHorizontal={{ phone: 'm', tablet: 0 }}>
        <SmsAllowanceWarningAlert onRetrySendMessage={props.onRetrySendMessage} marginBottom="m" />
    </Box>
)

export const SmsAllowanceWarningAlert: FunctionComponent<SmsAllowanceErrorProps & SpaceProps> = ({ onRetrySendMessage, ...props }) => (
    <WarningAlert testId="ad-request-sms-allowance-declined" {...props}>
        <Text as="p">
            Deine Anfrage konnte nicht versandt werden. Bitte akzeptiere die Zustimmungserklärung, um die Anfrage zu versenden.
        </Text>
        <ButtonWithLinkStyle
            testId="ad-request-retry-send-message-after-declined-sms-allowance"
            onClick={onRetrySendMessage}
            textAlign="left"
        >
            Zustimmungserklärung erneut aufrufen
        </ButtonWithLinkStyle>
    </WarningAlert>
)

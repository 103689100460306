import React, { FunctionComponent } from 'react'
import { SpaceProps } from '@wh-components/system/space'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { prependHttpIfMissing } from '@wh/common/chapter/lib/urlHelpers'
import { logBdsEvent } from '@wh/common/chapter/api/bdsApiClient'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { Text, TextProps } from '@wh-components/core/Text/Text'

interface WebsiteLinkProps extends SpaceProps {
    url: string
    adId: string
    taggingData: TaggingData
    testIdPrefix?: string
}

export const WebsiteLink: FunctionComponent<WebsiteLinkProps & Pick<TextProps, 'fontSize'>> = ({
    url,
    adId,
    taggingData,
    testIdPrefix,
    fontSize = 'm',
    ...props
}) => (
    <ServerRoutingAnchorLink
        type="anchor"
        href={prependHttpIfMissing(url)}
        rel="nofollow"
        target="_blank"
        alignSelf="flex-start"
        onClick={() => {
            logBdsEvent(adId, 'homepage-clicked')
            return callActionEvent('addetail_dealer_website_click', taggingData)
        }}
        testId={testIdPrefix ? `${testIdPrefix}-website-link` : undefined}
        {...props}
    >
        <Text fontSize={fontSize}>Firmenwebsite</Text>
    </ServerRoutingAnchorLink>
)

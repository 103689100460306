import { AdServiceGroup, AdServiceLinkList, DaWidget } from '@bbx/common/types/ad-detail/AdServices'
import { AdvertAddressDetails } from '@bbx/common/types/ad-detail/AdvertAddressDetails'
import { AdvertContactDetails } from '@bbx/common/types/ad-detail/AdvertContactDetails'
import { OrganisationDetails } from '@bbx/common/types/ad-detail/OrganisationDetails'
import { AdvertImageList } from '@wh/common/chapter/types/AdvertImage'
import { AttributesList, FormattedAttributesList } from '@wh/common/chapter/types/Attributes'
import { ContextLinkList } from '@bbx/common/types/contextLinks'
import { EquipmentList } from '@bbx/common/types/ad-detail/Equipments'
import { ContactSuggestions } from '@bbx/common/types/Messaging'
import { DmpParameters, DmpUserIdentities } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'
import { AdvertisingParametersV2 } from '@wh/common/digital-advertising/types/advertising-parametersV2'
import { SeoMetaData } from '@wh/common/chapter/types/SeoMetaData'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import dayjs from 'dayjs'
import { P2ppOptionsAdvertDetails } from '@wh/common/paylivery/types/deliveryOptions'
import { TeaserAttribute } from '@wh/common/chapter/components/TeaserAttributes'

export interface AdvertDetailsWithFormattedAttributes extends AdvertDetails {
    formattedAttributes: FormattedAttributesList
}

export interface AdvertDetails {
    id: string
    verticalId: number
    adTypeId: number
    productId: number
    advertAddressDetails: AdvertAddressDetails | null
    advertContactDetails: AdvertContactDetails
    advertEditStatusActionsList: AdvertEditStatusActionsList | null
    advertImageList: AdvertImageList
    advertStatus: AdvertStatus
    attributes: AttributesList
    equipmentList: EquipmentList
    description: string
    chatEnabled: boolean
    categoryTreeId: number | null
    categoryXmlCode: string | null
    ownageTypeXmlCode: string | null
    organisationDetails: OrganisationDetails
    advertAttachmentList?: unknown
    sellerProfileUserData: SellerProfileUserData
    contactOption: ContactOption
    contactSuggestions: ContactSuggestions | null
    contextLinkList: ContextLinkList
    taggingData: TaggingData
    savedInFolder: boolean
    advertiserReferenceNumber: string | null
    advertisingParametersV2: AdvertisingParametersV2
    dmpParameters?: DmpParameters
    dmpUserIdentities?: DmpUserIdentities
    firstPublishedDate: string
    publishedDate: string
    startDate: string
    changedDate: string
    endDate: string
    facebookTrackingData: FacebookTrackingData
    loginUUid: string
    uuid: string
    inputSource: number
    seoMetaData: SeoMetaData
    tooltips: ToolTip[]
    deleteReasonQueryParameter: string | null
    soldReasonQueryParameter?: string | null
    adServiceDesktopLandingPageLinks: AdServiceLinkList | null
    serviceBoxGroups: AdServiceGroup[] | null
    daWidget?: DaWidget | null
    breadcrumbs?: BreadcrumbsItem[] | null
    attributeInformation: AttributeInformation[] | null
    adServiceLandingPageLinks?: unknown
    p2ppOptions?: P2ppOptionsAdvertDetails | null
    upsellings?: AdUpsellingInformation[] | null
    teaserAttributes?: TeaserAttribute[] | null
}

export interface AdvertStatus {
    description: string
    id: string
    statusId?: number
}

export const isContactPossible = (status: AdvertStatus) => status.statusId !== 120

export const isNewAd = (advertDetails: AdvertDetails) => {
    const publishedDate = dayjs(advertDetails.publishedDate)
    const timestamp = dayjs(new Date())
    return timestamp.diff(publishedDate, 'minute') < 60
}

export interface AdvertEditStatusActionsList {
    statusId: number
    statusName: string
    contextLinkList: ContextLinkList
}

export interface ContactOption {
    additionalInfo: AdditionalContactInfo | null
    contactType: ContactType
}

export interface AdditionalContactInfo {
    buttonText: string
    link: string
}

export type ContactType = 'EMAIL' | 'MESSAGING' | 'EXTERNAL'

export interface SellerProfileUserData {
    activeAdCount: number
    name: string
    orgUUID: string | null
    pictureUrl: string | null
    private: boolean
    registerDate: string
    location: string | null
    street: string | null
    district: string | null
    hasProfileImage: boolean
}

export interface FacebookTrackingData {
    category1: string
    category2: string
    vertical: string
}

export interface BreadcrumbsItem {
    displayName: string
    seoUrl: string
}

export interface AttributeInformation {
    treeAttributeElement: TreeElement
    values: TreeElement[]
}

export interface TreeElement {
    label: string
    code?: string
    sortOrder: number
    treeId: number
    systemTags: string[]
    childrenDisplayType?: string
    preText?: string | null
    postText?: string | null
    middleText?: string | null
}

interface AdUpsellingInformation {
    type: 'BUMPED' | 'TOP_AD' | 'HIGHLIGHTED'
    context?: Record<string, string>
}

type ToolTip = 'NEW_SIMILARITY_SEARCH_CATEGORY'

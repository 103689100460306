import React, { FunctionComponent } from 'react'
import dynamic from 'next/dynamic'
import { LeafletMapProps } from '@bbx/search-journey/common/components/Map/LeafletMap'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { Box } from '@wh-components/core/Box/Box'
import PinIcon from '@wh-components/icons/Pin'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { useUserAgent } from '@wh/common/chapter/components/UserAgentProvider/useUserAgent'

const LeafletMap = dynamic<LeafletMapProps>(
    () => import('@bbx/search-journey/common/components/Map/LeafletMap').then((m) => m.LeafletMap),
    {
        ssr: false,
        loading: () => <Box height="100%" width="100%" borderRadius="m" backgroundColor="palette.polarbear" />,
    },
)

interface AdDetailMapProps extends LeafletMapProps {
    adId: string
    taggingData: TaggingData
}

export const AdDetailMap: FunctionComponent<AdDetailMapProps> = React.memo(({ adId, taggingData, ...props }) => {
    const isVeryOldChrome = useIsVeryOldChrome()

    return isVeryOldChrome ? null : (
        <Box height={props.height}>
            <LeafletMap
                borderRadius="m"
                overflow="hidden"
                {...props}
                controls={[
                    {
                        position: 'bottomright',
                        element: (
                            <ServerRoutingAnchorLink
                                type="button"
                                href={`/iad/mapobject?adId=${adId}`}
                                Icon={PinIcon}
                                size="small"
                                color="primary"
                                variant="outline"
                                testId="open-fullscreen-map"
                                onClick={() => callActionEvent('addetail_map_click', taggingData)}
                            >
                                Karte öffnen
                            </ServerRoutingAnchorLink>
                        ),
                    },
                ]}
            />
        </Box>
    )
})

// Probably due to a change in Next.js (https://github.com/vercel/next.js/pull/42589),
// dynamic imports are causing issues on older, unsupported (!) Chrome browsers.
// Therefore, we avoid dynamic imports, actually do not show LeafLet maps on Ad Detail for them.
const useIsVeryOldChrome = () => {
    const parsedUA = useUserAgent().uaParserResult
    if (!parsedUA || parsedUA.browser.name?.toLowerCase() !== 'chrome' || !parsedUA.browser.version) {
        return false
    }

    const [majorString] = parsedUA.browser.version.split('.')
    const major = parseInt(majorString, 10)
    if (isNaN(major)) {
        return false
    }

    return major <= 38
}

import { AttributeGroup, FormattedAttributesItem } from '@wh/common/chapter/types/Attributes'
import {
    AttributeGroupDefinition,
    groupAttributes,
} from '@bbx/search-journey/sub-domains/ad-detail/components/common/AttributesBox/grouping'

export const attributeGroupsCar: AttributeGroupDefinition[] = [
    {
        id: 'group1',
        heading: 'Basisdaten',
        includedAttributes: [
            'LICENSE',
            'MILEAGE',
            'ENGINE/EFFECT',
            'ENGINE/FUEL',
            'TRANSMISSION',
            'DRIVE_SYSTEM',
            'WHEEL_DRIVE',
            'NO_OF_OWNERS',
            'ELECTRIC_VEHICLE/WLTP_RANGE',
            'ELECTRIC_VEHICLE/BATTERY_CAPACITY',
        ],
    },
    {
        id: 'group2',
        heading: 'Karosserie & Technik',
        includedAttributes: [
            'CAR_TYPE',
            'MOTOR_CONDITION',
            'EXTERIOR_COLOUR',
            'EXTERIOR_COLOUR_MAIN',
            'EQUIPMENT_METALLIC_PAINT',
            'NO_OF_DOORS',
            'NO_OF_SEATS',
            'ENGINE/VOLUME',
            'ENGINE/CYLINDER',
            'CO2_FOOTPRINT',
            'EMISSION_STANDARD',
            'CONSUMPTION',
            'TRAILER_LOAD',
        ],
    },
    {
        id: 'group3',
        heading: 'Weitere Vorteile',
        includedAttributes: [
            'WARRANTY',
            'WARRANTY_DURATION',
            'DEFECTS_LIABILITY',
            'CONDITION_REPORT',
            'CONDITION_REPORT_VALID_UNTIL',
            'EQUIPMENT_VAT_REPORTABLE',
            'EQUIPMENT_COMPLETE_SERVICE_BOOKLET',
            'EQUIPMENT_LEASABLE',
            'EQUIPMENT_WHEELCHAIR_ACCESSIBLE',
        ],
    },
]

export const attributeGroupMotorcycle: AttributeGroupDefinition = {
    id: 'groupMotorbike',
    heading: '',
    includedAttributes: [
        'MILEAGE',
        'LICENSE',
        'MC_CATEGORY',
        'ENGINE/EFFECT',
        'ENGINE/VOLUME',
        'ENGINE/CYLINDER',
        'ENGINE/FUEL',
        'EXTERIOR_COLOUR_MAIN',
        'MOTOR_CONDITION',
        'TRANSMISSION',
        'DRIVE_SYSTEM',
        'EXTERIOR_COLOUR',
        'CONDITION_REPORT',
        'DEFECTS_LIABILITY',
        'ELECTRIC_VEHICLE/WLTP_RANGE',
        'ELECTRIC_VEHICLE/BATTERY_CAPACITY',
    ],
}

export const attributeGroupCaravan: AttributeGroupDefinition = {
    id: 'groupCaravan',
    heading: '',
    includedAttributes: [
        'MILEAGE',
        'LICENSE',
        'CARAVAN_SEGMENT',
        'MOTOR_CONDITION',
        'ENGINE/FUEL',
        'TRANSMISSION',
        'WHEEL_DRIVE',
        'ENGINE/EFFECT',
        'CARAVAN_WEIGHT/WEIGHT_NET',
        'CARAVAN_WEIGHT/WEIGHT_TOT',
        'LENGTH_CM',
        'WIDTH_CM',
        'NO_OF_BERTHS',
        'CONDITION_REPORT',
        'WARRANTY',
        'DEFECTS_LIABILITY',
    ],
}

export const attributeGroupTruck: AttributeGroupDefinition = {
    id: 'groupTruck',
    heading: '',
    includedAttributes: [
        'MILEAGE',
        'LICENSE',
        'ENGINE/EFFECT',
        'MOTOR_CONDITION',
        'ENGINE/FUEL',
        'VAN_SEGMENT',
        'TRANSMISSION',
        'WHEEL_DRIVE',
        'EXTERIOR_COLOUR_MAIN',
        'NO_OF_DOORS',
        'NO_OF_SEATS',
        'NO_OF_OWNERS',
        'CONDITION_REPORT',
        'CONDITION_REPORT_DATE',
        'CONDITION_REPORT_VALID_UNTIL',
        'WARRANTY',
        'WARRANTY_DURATION',
        'DEFECTS_LIABILITY',
        'ENGINE/VOLUME',
        'CO2_FOOTPRINT',
        'ENGINE/CYLINDER',
        'EMISSION_STANDARD',
        'CONSUMPTION',
        'TRAILER_LOAD',
        'MAX_TOTAL_WEIGHT',
        'ELECTRIC_VEHICLE/WLTP_RANGE',
        'ELECTRIC_VEHICLE/BATTERY_CAPACITY',
    ],
}

export const groupAttributesCar = (attributes: FormattedAttributesItem[]): AttributeGroup[] => {
    return groupAttributes(attributes, attributeGroupsCar)
}

export const groupAttributesMotorcycle = (attributes: FormattedAttributesItem[]): AttributeGroup[] => {
    return groupAttributes(attributes, [attributeGroupMotorcycle])
}

export const groupAttributesCaravan = (attributes: FormattedAttributesItem[]): AttributeGroup[] => {
    return groupAttributes(attributes, [attributeGroupCaravan])
}

export const groupAttributesTruck = (attributes: FormattedAttributesItem[]): AttributeGroup[] => {
    return groupAttributes(attributes, [attributeGroupTruck])
}

import React, { FunctionComponent, RefObject, useContext, useMemo } from 'react'
import { AdvertDetails, isContactPossible } from '@bbx/common/types/ad-detail/AdvertDetails'
import {
    needsToAcceptSmsAllowance as defaultNeedsToAcceptSmsAllowance,
    setSmsAllowanceStatusAccepted as defaultSetSmsAllowanceStatusAccepted,
} from '@wh/common/sms-allowance/api/smsAllowanceApiClient'
import { UserProfileData } from '@wh/common/chapter/types/user'
import { AdContactFormMessaging } from '@bbx/lead-journey/lead-journey/components/AdContactForm/common/AdContactFormMessaging'
import { getContactTypeFromAdvertDetails } from '@bbx/lead-journey/lead-journey/components/AdContactForm/common/ContactType'
import { SendEmailRequestFormDataMotor } from './adContactFormEmailMotor.types'
import { AdContactFormInnerEmailMotor } from './AdContactFormInnerEmailMotor'
import { callActionEvent, callPageViewWithPageParameters } from '@wh/common/chapter/lib/tagging/tagging'
import { DmpStateContext } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'
import { AdContactFormRequestStateContainer } from '@bbx/lead-journey/lead-journey/components/AdContactForm/common/AdContactFormRequestStateContainer'
import { RequestSendSuccessEmail } from '@bbx/lead-journey/lead-journey/components/AdContactForm/common/AdContactFormRequestSendSuccess'
import { Card } from '@wh-components/core/Card/Card'
import {
    sendRequestMail as defaultSendRequestMail,
    sendRequestMessage as defaultSendRequestMessage,
} from '@bbx/lead-journey/lead-journey/api/adContactApiClient'

interface AdContactFormMotorProps {
    advertDetails: AdvertDetails
    profileData: UserProfileData | undefined
    setAdvertisingStateContacted: () => void
    adContactVisibilityTrackingRef: RefObject<HTMLDivElement>

    // mockable props that are provided with defaults
    sendRequestMail?: typeof defaultSendRequestMail
    sendRequestMessage?: typeof defaultSendRequestMessage
    needsToAcceptSmsAllowance?: typeof defaultNeedsToAcceptSmsAllowance
    setSmsAllowanceStatusAccepted?: typeof defaultSetSmsAllowanceStatusAccepted
}

export const AdContactFormMotor: FunctionComponent<AdContactFormMotorProps> = ({
    sendRequestMail = defaultSendRequestMail,
    sendRequestMessage = defaultSendRequestMessage,
    needsToAcceptSmsAllowance = defaultNeedsToAcceptSmsAllowance,
    setSmsAllowanceStatusAccepted = defaultSetSmsAllowanceStatusAccepted,
    ...props
}) => {
    const contactTypeWithDetails = useMemo(() => getContactTypeFromAdvertDetails(props.advertDetails), [props.advertDetails])
    const { trackCustomDmpEvent } = useContext(DmpStateContext)

    if (!isContactPossible(props.advertDetails.advertStatus)) {
        return null
    }

    switch (contactTypeWithDetails.type) {
        case 'EMAIL': {
            return (
                <AdContactFormRequestStateContainer<{
                    data: SendEmailRequestFormDataMotor
                }>
                    adId={props.advertDetails.id}
                    adContactVisibilityTrackingRef={props.adContactVisibilityTrackingRef}
                    requestSendSuccess={() => (
                        <RequestSendSuccessEmail adId={props.advertDetails.id} adTypeId={props.advertDetails.adTypeId} isHouse={false} />
                    )}
                    requestForm={(handleSend) => (
                        <AdContactFormInnerEmailMotor
                            advertDetails={props.advertDetails}
                            profileData={props.profileData}
                            contactSuggestions={contactTypeWithDetails.contactSuggestions}
                            onSendEmail={handleSend}
                        />
                    )}
                    preSendCheck={async (formData) => {
                        trackCustomDmpEvent('UserAction', { eventName: 'TapSendMessage' })
                        callActionEvent('addetail_send_email_click', props.advertDetails.taggingData)
                        // INFO user does not need to be logged in to send an email request
                        return { result: 'success', pendingRequestData: { data: formData }, emailAddressForSmsAllowance: formData.from }
                    }}
                    sendNetworkRequest={(pendingRequestData, _emailAddress) =>
                        sendRequestMail({
                            adId: +props.advertDetails.id,
                            showTelephoneNumber: pendingRequestData.data.telephone.length > 0,
                            ...pendingRequestData.data,
                        })
                    }
                    onSendSuccess={(pendingRequestData) => {
                        props.setAdvertisingStateContacted()
                        trackCustomDmpEvent('UserAction', { eventName: 'MessageSent' })
                        callPageViewWithPageParameters(
                            {
                                page: 'contact_seller_confirmation_dealer_motor',
                                selectedContactSuggestions: pendingRequestData.data.selectedContactSuggestions,
                                hasFilledOutMessageField: pendingRequestData.data.mailContent.trim().length > 0,
                            },
                            props.advertDetails.taggingData,
                        )
                    }}
                    needsToAcceptSmsAllowance={needsToAcceptSmsAllowance}
                    setSmsAllowanceStatusAccepted={setSmsAllowanceStatusAccepted}
                />
            )
        }
        case 'MESSAGING':
            return (
                <Card>
                    <AdContactFormMessaging
                        heading="Verkäufer kontaktieren"
                        advertDetails={props.advertDetails}
                        profileData={props.profileData}
                        setAdvertisingStateContacted={props.setAdvertisingStateContacted}
                        adContactVisibilityTrackingRef={props.adContactVisibilityTrackingRef}
                        sendRequestMessage={sendRequestMessage}
                        needsToAcceptSmsAllowance={needsToAcceptSmsAllowance}
                        setSmsAllowanceStatusAccepted={setSmsAllowanceStatusAccepted}
                        messagingInfoTitle="Finde noch andere passende Fahrzeuge!"
                        messagingInfoBody="Geh zurück zum Suchergebnis und entdecke weitere super Fahrzeug-Angebote."
                    />
                </Card>
            )
        default:
            return null // currently unsupported
    }
}

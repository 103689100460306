import React, { FunctionComponent, useContext } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { RenderSlotId } from '@wh/common/digital-advertising/config'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { css } from 'styled-components'
import { SpaceProps } from '@wh-components/system/space'
import { werbungBeforeCss } from '../werbungBeforeCss'

export const AdDetailLargeRenderSlot: FunctionComponent<{ className?: string } & SpaceProps> = ({ className, ...spaceProps }) => {
    const advertisingState = useContext(AdvertisingStateContext)
    const isClosedAdSlot = (advertisingState.pageModifications.closedAdSlots || []).includes(RenderSlotId.AD_DETAIL_LARGE)

    // display:flex implicitly makes child elements display:block, which is necessary to avoid the extra spacing at the bottom
    return (
        <Box
            flex="1 1 auto"
            justifyContent="center"
            width="100%"
            id={RenderSlotId.AD_DETAIL_LARGE}
            className={className}
            aria-hidden="true"
            position="relative"
            zIndex="content"
            {...spaceProps}
            css={css`
                iframe {
                    /* avoids extra spacing at the bottom */
                    display: block;
                }

                display: none;
                ${(p) => p.theme.media.desktop} {
                    display: ${isClosedAdSlot ? 'none' : 'flex'};
                }

                ${(p) => p.theme.media.print} {
                    display: none;
                }
                ${werbungBeforeCss}
            `}
        />
    )
}

import React, { FunctionComponent } from 'react'
import { LastViewedAdsSliderType } from '@bbx/common/types/UserFeed'
import { callActionEvent, callSelfPromotionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { TaggingWidgetType, useWidgetTagging } from '@bbx/search-journey/common/hooks/useWidgetTagging'
import { LastViewedAdsSlider } from '@bbx/search-journey/common/components/Widgets/LastViewedAdsWidgetSlider'
import { verticalTaggingNameMap } from '@wh/common/chapter/types/verticals'

interface AdDetailRecommendationsWidgetProps {
    lastViewedAdsSlider: LastViewedAdsSliderType
    taggingData: TaggingData
}

export const AdDetailLastViewedAdsWidget: FunctionComponent<AdDetailRecommendationsWidgetProps> = ({
    lastViewedAdsSlider,
    taggingData,
}) => {
    const numberOfAdsLimit = 10

    const { visibilityTrackingRef } = useWidgetTagging({
        ...widgetAndPage,
        adIds: lastViewedAdsSlider.ads.map((ad) => ad.id).slice(0, numberOfAdsLimit),
        pulseMetadata: lastViewedAdsSlider.pulseMetadata,
    })

    return (
        <LastViewedAdsSlider
            slider={lastViewedAdsSlider}
            paddingLeft={{ phone: 'm', tablet: 0 }}
            onClickAdTagging={(ad, index) => {
                callActionEvent('ad_widget_ad_click', taggingData, {
                    ...widgetAndPage,
                    taggingId: verticalTaggingNameMap[ad.verticalId],
                    adId: ad.id,
                    adIndex: index + 1,
                    listName: lastViewedAdsSlider.pulseMetadata?.listName,
                    transactionId: lastViewedAdsSlider.pulseMetadata?.transactionId,
                })
                callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
            }}
            visibilityTrackingRef={visibilityTrackingRef}
            numberOfAdsLimit={numberOfAdsLimit}
        />
    )
}

const widgetAndPage = {
    taggingWidgetType: 'LastViewedAdsWidget' as TaggingWidgetType,
    page: 'AdDetail',
    source: 'ad-view-detail',
}

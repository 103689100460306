import React, { FunctionComponent, RefObject, SyntheticEvent, useContext } from 'react'
import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { SendMessageRequestFormData } from './adContactFormMessaging.types'
import {
    needsToAcceptSmsAllowance as defaultNeedsToAcceptSmsAllowance,
    setSmsAllowanceStatusAccepted as defaultSetSmsAllowanceStatusAccepted,
} from '@wh/common/sms-allowance/api/smsAllowanceApiClient'
import { UserProfileData } from '@wh/common/chapter/types/user'
import { RequestSendSuccessMessaging } from './AdContactFormRequestSendSuccess'
import { callActionEvent, callPageView } from '@wh/common/chapter/lib/tagging/tagging'
import { DmpStateContext } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'
import { AdContactFormRequestStateContainer } from './AdContactFormRequestStateContainer'
import { redirectToLoginPage } from '@wh/common/chapter/lib/routing/bbxRouter'
import { AdContactFormInnerMessaging } from './AdContactFormInnerMessaging'
import { updateProfileData } from '@bbx/common/api/myProfileApiClient'
import { getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { ButtonVariantType } from '@wh-components/core/Button/Button'
import { sendRequestMessage as defaultSendRequestMessage } from '@bbx/lead-journey/lead-journey/api/adContactApiClient'

interface AdContactFormMessagingProps {
    advertDetails: AdvertDetails
    heading?: string
    profileData: UserProfileData | undefined
    setAdvertisingStateContacted: () => void
    adContactVisibilityTrackingRef: RefObject<HTMLDivElement>
    messagingInfoTitle: string
    messagingInfoBody: string
    buttonVariant?: ButtonVariantType
    // mockable props that are provided with defaults
    sendRequestMessage?: typeof defaultSendRequestMessage
    needsToAcceptSmsAllowance?: typeof defaultNeedsToAcceptSmsAllowance
    setSmsAllowanceStatusAccepted?: typeof defaultSetSmsAllowanceStatusAccepted
}

export const AdContactFormMessaging: FunctionComponent<AdContactFormMessagingProps> = ({
    sendRequestMessage = defaultSendRequestMessage,
    needsToAcceptSmsAllowance = defaultNeedsToAcceptSmsAllowance,
    setSmsAllowanceStatusAccepted = defaultSetSmsAllowanceStatusAccepted,
    ...props
}) => {
    const { trackCustomDmpEvent } = useContext(DmpStateContext)
    const isHouse = getAttributeValue(props.advertDetails.attributes.attribute, 'PROPERTY_TYPE_HOUSE') === 'true'

    const handleClickedOnMessagingFormWhileNotLoggedIn = (event: SyntheticEvent) => {
        event.preventDefault()
        redirectToLoginPage()
    }

    return (
        <AdContactFormRequestStateContainer<{
            data: SendMessageRequestFormData
            fromFullName: string
        }>
            adId={props.advertDetails.id}
            adContactVisibilityTrackingRef={props.adContactVisibilityTrackingRef}
            requestSendSuccess={() => (
                <RequestSendSuccessMessaging
                    adId={props.advertDetails.id}
                    adTypeId={props.advertDetails.adTypeId}
                    isHouse={isHouse ?? false}
                    infoTitle={props.messagingInfoTitle}
                    infoBody={props.messagingInfoBody}
                />
            )}
            requestForm={(handleSend) => (
                <AdContactFormInnerMessaging
                    heading={props.heading}
                    advertDetails={props.advertDetails}
                    profileData={props.profileData}
                    buttonVariant={props.buttonVariant}
                    onClickedOnMessagingFormWhileNotLoggedIn={handleClickedOnMessagingFormWhileNotLoggedIn}
                    onSendMessage={handleSend}
                />
            )}
            preSendCheck={async (formData) => {
                trackCustomDmpEvent('UserAction', { eventName: 'TapSendMessage' })
                callActionEvent('addetail_send_message_click', props.advertDetails.taggingData)

                if (typeof props.profileData === 'undefined') {
                    // should not happen, redirect to login for safety
                    return { result: 'needs-login' }
                }

                const { firstName, lastName } = formData
                const fromFullName = [firstName, lastName].filter(Boolean).join(' ').trim() // necessary for the api call

                if (firstName !== props.profileData.firstName || lastName !== props.profileData.lastName) {
                    try {
                        await updateProfileData(props.profileData.loginId, { ...props.profileData, firstName, lastName })
                    } catch {
                        // no error in case of failure to profile update since messaging will still work
                    }
                }

                const pendingRequestData = {
                    data: formData,
                    fromFullName,
                }

                return { result: 'success', pendingRequestData, emailAddressForSmsAllowance: props.profileData.emailAddress }
            }}
            sendNetworkRequest={(pendingRequestData, emailAddress) =>
                sendRequestMessage({
                    fromFullName: pendingRequestData.fromFullName,
                    firstName: props.profileData?.firstName ? props.profileData.firstName : undefined,
                    lastName: props.profileData?.lastName ? props.profileData.lastName : undefined,
                    shareTenantProfile: pendingRequestData.data.shareTenantProfile,
                    from: emailAddress,
                    adId: +props.advertDetails.id,
                    copyToSender: false,
                    showTelephoneNumber: false,
                    telephone: '',
                    selectedContactSuggestions: [],
                    mailContent: pendingRequestData.data.mailContent,
                })
            }
            onSendSuccess={() => {
                props.setAdvertisingStateContacted()
                trackCustomDmpEvent('UserAction', { eventName: 'MessageSent' })
                callPageView('contact_seller_confirmation', {
                    taggingData: props.advertDetails.taggingData,
                })
            }}
            needsToAcceptSmsAllowance={needsToAcceptSmsAllowance}
            setSmsAllowanceStatusAccepted={setSmsAllowanceStatusAccepted}
        />
    )
}

import { inverseAdTypeIdMap } from '@wh/common/chapter/types/AdType'
import { staticRelativeCanonicals } from '@wh/common/chapter/hocs/staticRelativeCanonicals'

export const getBaseSearchResultPath = (adTypeId: number, isHouse: boolean) => {
    switch (inverseAdTypeIdMap[adTypeId]) {
        case 'MotorCar':
            return staticRelativeCanonicals.MotorCarResultList
        case 'MotorMc':
            return staticRelativeCanonicals.MotorMcResultList
        case 'MotorVanTruck':
            return staticRelativeCanonicals.MotorTruckResultList
        case 'MotorCaravan':
            return staticRelativeCanonicals.MotorCaravanResultList
        case 'EstateSale':
            if (isHouse) {
                return staticRelativeCanonicals.EstateHouseSaleResultList
            } else {
                return staticRelativeCanonicals.EstateFlatSaleResultList
            }
        case 'EstateRent':
            if (isHouse) {
                return staticRelativeCanonicals.EstateHouseRentResultList
            } else {
                return staticRelativeCanonicals.EstateFlatRentResultList
            }
        case 'EstateBusinessSale':
            return staticRelativeCanonicals.EstateCommercialSaleResultList
        case 'EstateBusinessRent':
            return staticRelativeCanonicals.EstateCommercialRentResultList
        case 'EstateLeisureSale':
            return staticRelativeCanonicals.EstateLeisureSaleResultList
        case 'EstateLeisureRent':
            return staticRelativeCanonicals.EstateLeisureRentResultList
        case 'EstatePlotSale':
            return staticRelativeCanonicals.EstatePlotsResultList
        case 'EstateMisc':
            return staticRelativeCanonicals.EstateMiscResultList
        case 'BapFree':
        case 'BapWebstoreobject':
        case 'BapSalesobjectPro':
            return staticRelativeCanonicals.BapHome
        default:
            return staticRelativeCanonicals.Home
    }
}

import { ContactAdvertiser, ContactAdvertiserRequest, OfferResponse } from '@bbx/common/types/Messaging'
import { fetcher } from '@wh/common/chapter/api/fetcher'

export const sendRequestMail = (message: ContactAdvertiserRequest): Promise<ContactAdvertiser> => {
    return fetcher<ContactAdvertiser>(`/iad-messaging/sendrequest`, {
        method: 'POST',
        body: JSON.stringify(message),
        credentials: 'include',
    })
}
export const sendRequestMessage = (message: ContactAdvertiserRequest): Promise<ContactAdvertiser> => {
    return fetcher<ContactAdvertiser>(`/iad-messaging/sendrequest/messaging`, {
        method: 'POST',
        body: JSON.stringify(message),
        credentials: 'include',
    })
}
export const initConversation = (adId: string): Promise<OfferResponse> => {
    return fetcher<OfferResponse>(`/iad-messaging/conversation/init/${adId}`, {
        method: 'PUT',
        credentials: 'include',
    })
}

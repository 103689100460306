import { getAttributeValue, AttributesItem } from '@wh/common/chapter/types/Attributes'
import { inverseAdTypeIdMap } from '@wh/common/chapter/types/AdType'
import { AttributeName } from '@wh/common/chapter/types/AttributeName'
import { OfferItemCondition } from 'schema-dts'

export const getAutoBrand = (adTypeId: number, attributes: AttributesItem[]) => {
    let attribute: AttributeName = 'CAR_MODEL/MAKE'
    switch (inverseAdTypeIdMap[adTypeId]) {
        case 'MotorCar':
            attribute = 'CAR_MODEL/MAKE'
            break
        case 'MotorMc':
            attribute = 'MC_MODEL/MAKE'
            break
        case 'MotorVanTruck':
            attribute = 'VAN_MODEL/MAKE'
            break
        case 'MotorCaravan':
            attribute = 'CARAVAN_MODEL/MAKE'
            break
        default:
            attribute = 'CAR_MODEL/MAKE'
            break
    }
    return getAttributeValue(attributes, attribute)
}

export const getAutoModel = (adTypeId: number, attributes: AttributesItem[]) => {
    let attribute: AttributeName = 'CAR_MODEL/MODEL'
    switch (inverseAdTypeIdMap[adTypeId]) {
        case 'MotorCar':
            attribute = 'CAR_MODEL/MODEL'
            break
        case 'MotorMc':
            attribute = 'MC_MODEL/MODEL'
            break
        case 'MotorVanTruck':
            attribute = 'VAN_MODEL/MODEL'
            break
        case 'MotorCaravan':
            attribute = 'CARAVAN_MODEL/MODEL'
            break
        default:
            attribute = 'CAR_MODEL/MODEL'
            break
    }
    return getAttributeValue(attributes, attribute)
}

export const getItemCondition = (attributes: AttributesItem[]): OfferItemCondition => {
    const motorCondition = getAttributeValue(attributes, 'MOTOR_CONDITION') || getAttributeValue(attributes, 'CONDITION_RESOLVED')
    if (motorCondition === 'Unfallwagen') {
        return 'DamagedCondition'
    } else if (motorCondition === 'Neuwagen' || motorCondition === 'Neufahrzeug') {
        return 'NewCondition'
    }
    return 'UsedCondition'
}

import { SellerProfileUserData, AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { OrganisationDetails } from '@bbx/common/types/ad-detail/OrganisationDetails'
import { Person, Organization } from 'schema-dts'
import { getSellerType } from '@bbx/common/lib/getSellerType'

export const getPrivate = (privateSeller: SellerProfileUserData): Person => ({
    '@type': 'Person',
    givenName: privateSeller ? privateSeller.name : '',
})

export const getDealer = (dealer: OrganisationDetails): Organization => ({
    '@type': 'Organization',
    name: dealer.orgName || undefined,
    email: dealer.orgEmail || undefined,
    telephone: dealer.orgPhone || undefined,
    address: {
        '@type': 'PostalAddress',
        addressCountry: dealer.country || undefined,
        postalCode: dealer.postCode || undefined,
        streetAddress: dealer.addressLines.value[0] ? dealer.addressLines.value[0] : undefined,
    },
})

export const getSeller = (advertDetails: AdvertDetails): Organization | Person | undefined => {
    if (advertDetails) {
        return getSellerType(advertDetails.attributes) !== 'dealer'
            ? getPrivate(advertDetails.sellerProfileUserData)
            : getDealer(advertDetails.organisationDetails)
    } else {
        return undefined
    }
}

import React, { FunctionComponent } from 'react'
import { Box, BoxProps } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { Tooltip } from '@wh-components/core/Tooltip/Tooltip'
import TooltipQuestionmark from '@wh-components/icons/TooltipQuestionmark'

export const AdvertisementHint: FunctionComponent<{ id: number | string; advertisers: string[] } & BoxProps> = ({
    advertisers,
    id,
    ...props
}) => {
    return (
        <Box display="flex" {...props}>
            <Text color="palette.elephant" fontSize="xs">
                WERBUNG
            </Text>
            <Tooltip
                interactive={true}
                content={
                    <Box padding="s">
                        <Box fontWeight="bold">Werbetreibend</Box>
                        {advertisers.length > 1 ? (
                            <ul>
                                {advertisers.map((advertiser) => (
                                    <li key={advertiser}>{advertiser}</li>
                                ))}
                            </ul>
                        ) : (
                            `${advertisers}`
                        )}
                        <Box fontWeight="bold" marginTop="m">
                            Warum sehe ich diese Werbung?
                        </Box>
                        Verwendung reduzierter Daten zur Auswahl von Werbeanzeigen: Werbeanzeigen, die Ihnen auf diesem Dienst präsentiert
                        werden, können auf reduzierten Daten basieren, wie z. B. der Webseite oder App, die Sie gerade verwenden, Ihrem
                        ungefähren Standort, Ihrem Gerätetyp oder den Inhalten, mit denen Sie interagieren (oder interagiert haben) (z. B.,
                        um die Anzeigefrequenz der Werbung zu begrenzen, die Ihnen ausgespielt werden).
                    </Box>
                }
            >
                <Box display="flex" marginLeft="xs" testId={`serviceBoxToolTip-${id}`}>
                    <TooltipQuestionmark color="palette.raccoon" size="xsmall" aria-hidden={true} />
                </Box>
            </Tooltip>
        </Box>
    )
}

import React, { FunctionComponent, RefObject } from 'react'
import { Text } from '@wh-components/core/Text/Text'
import { Box } from '@wh-components/core/Box/Box'

export const SecurityHintsDealer: FunctionComponent<{ securityHintsRef?: RefObject<HTMLAnchorElement> }> = ({ securityHintsRef }) => {
    return (
        <Box paddingHorizontal={{ phone: 'm', tablet: '0' }} testId="ad-detail-hint-dealer">
            <Text fontSize="s" color="palette.raccoon" ref={securityHintsRef}>
                <Text fontWeight="bold">HINWEIS: </Text>Alle Angaben ohne Gewähr. Bei einigen angezeigten Daten (z.B. Ausstattungen) handelt
                es sich um Hersteller-/Importeurs-Angaben bzw. Daten von Autovista. Bitte beachte, dass es hierdurch im jeweiligen
                Fahrzeugangebot zu Abweichungen kommen kann. Im Zweifelsfall wende dich bitte an den Anbieter.
            </Text>
        </Box>
    )
}

import { fetcher } from '@wh/common/chapter/api/fetcher'
import { urlSearchParamsFromObject } from '@wh/common/chapter/api/urlSearchParams'
import { GeoJSONGeometryOrNull, parse } from 'wellknown'

type ShapeInfo = {
    shape: GeoJSONGeometryOrNull | 'not-found'
    shapeFocusPoint: [number, number] | null
}

export const getAreaShape = async (searchText: string, additionalData?: string): Promise<ShapeInfo> => {
    try {
        const response = await fetcher<unknown>(
            `/iad/geocoder/shape?${urlSearchParamsFromObject({
                query: searchText,
                additionalData,
            })}`,
            {
                credentials: 'include',
            },
        )

        // @ts-ignore
        const shape: string = response?.response?.view?.[0]?.result?.[0]?.location?.shape?.value
        // @ts-ignore
        const displayPosition = response?.response?.view?.[0]?.result?.[0]?.location?.displayPosition
        const displayPositionLat = displayPosition?.latitude
        const displayPositionLon = displayPosition?.longitude
        let center: [number, number] | null = null
        if (displayPositionLat && displayPositionLon) {
            center = [displayPositionLat, displayPositionLon]
        }
        return { shape: shape ? parse(shape) : 'not-found', shapeFocusPoint: center }
    } catch (_error) {
        return { shape: 'not-found', shapeFocusPoint: null }
    }
}

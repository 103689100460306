import React, { FunctionComponent } from 'react'
import { SpaceProps } from '@wh-components/system/space'
import { ContactDetailAddress } from '@bbx/common/types/ad-detail/AdvertContactDetails'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'

interface AddressBoxProps extends SpaceProps {
    address: ContactDetailAddress
    testIdPrefix?: string
    showStreet?: boolean
}

export const AddressBox: FunctionComponent<AddressBoxProps> = ({ address, testIdPrefix, showStreet = true, ...props }) => {
    const { country, postCode, postalName, region, state, street } = address

    const postCodePart = buildPostCodePart(postCode, postalName)
    const regionPart = state !== 'Wien' && region !== postalName ? region : undefined
    const statePart = state !== 'Wien' ? state : undefined

    return (
        <Box display="flex" flexDirection="column" testId={`${testIdPrefix}-contact-box-address-box`} {...props}>
            {showStreet && street && <Text>{street}</Text>}
            <Text>{postCodePart}</Text>
            {(regionPart || statePart) && <Text>{[regionPart, statePart].filter(Boolean).join(', ')}</Text>}
            {country && country !== 'Österreich' && <Text>{country}</Text>}
        </Box>
    )
}

export const buildPostCodePart = (postCode: string | undefined, postalName: string | undefined) => {
    return [postCode, postalName].filter(Boolean).join(' ')
}

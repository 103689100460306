import { fetcher } from '../../chapter/api/fetcher'
import { SmsAllowanceResponse } from '../types/SmsAllowance'
import { ApiError } from '@wh/common/chapter/lib/errors'

export const getSmsAllowanceStatus = (adId: string, emailAddress: string): Promise<SmsAllowanceResponse> => {
    return fetcher<SmsAllowanceResponse>(`/iad/sms/allowance?adId=${adId}&emailAddress=${encodeURIComponent(emailAddress)}`, {
        credentials: 'include',
    })
}

export const needsToAcceptSmsAllowance = async (adId: string, emailAddress: string) =>
    !(await getSmsAllowanceStatus(adId, emailAddress)).confirmed

export const setSmsAllowanceStatusAccepted = (emailAddress: string): Promise<SmsAllowanceResponse> => {
    if (!emailAddress) {
        return Promise.reject(new ApiError('emailAddress missing'))
    }

    return fetcher<SmsAllowanceResponse>(`/iad/sms/allowance`, {
        method: 'PUT',
        body: JSON.stringify({ confirmed: 'true', emailAddress: emailAddress }),
        credentials: 'include',
    })
}

import React, { FunctionComponent, PropsWithChildren, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { TestProps } from '@wh-components/core/common'
import { Box } from '@wh-components/core/Box/Box'
import { ImageScaleModes } from '@wh/common/chapter/components/AdImage'
import { FLICKITY_CAROUSEL_CELL_CLASSNAME, ImageGalleryData } from './ImageGallery.settings'
import { ResponsiveValue } from '@wh-components/system'

interface CarouselCellProps {
    image: ImageGalleryData
    index: number
    lazyLoad?: boolean
    height: ResponsiveValue<string>
    imageScaleMode?: ImageScaleModes
    showMultiImages?: boolean
    onFlickityImageClick?: (imageGalleryData: ImageGalleryData) => void
    isSelected: boolean
    isFlickityReady: boolean
}

export const CarouselCell: FunctionComponent<CarouselCellProps & TestProps> = ({
    image,
    index,
    lazyLoad,
    testId,
    height,
    imageScaleMode = 'contain',
    showMultiImages = false,
    onFlickityImageClick,
    isSelected,
    isFlickityReady,
}) => {
    return (
        <Box
            // The images need to be part of the dom when flickity is loaded, but displaying them beforehand breaks styles -> images are displayed if flickity is ready
            display={index === 0 || isFlickityReady ? 'flex' : 'none'}
            justifyContent="center"
            alignItems="center"
            top="0"
            margin="0"
            overflow="hidden"
            className={FLICKITY_CAROUSEL_CELL_CLASSNAME}
            testId={`carousel-cell-${testId}`}
            height={height}
            css={css`
                cursor: pointer;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }

                ${(p) => p.theme.media.tablet} {
                    width: ${showMultiImages ? '33.333%' : '100%'};
                }

                ${(p) => p.theme.media.only.phone} {
                    width: 100%;
                }
            `}
        >
            {image.mediaType === 'video' ? (
                <VideoCellContent image={image} testId={testId} isSelected={isSelected} imageScaleMode={imageScaleMode} />
            ) : (
                <ImageCellContent
                    isSelected={isSelected}
                    imageScaleMode={imageScaleMode}
                    image={image}
                    onFlickityImageClick={onFlickityImageClick}
                    lazyLoad={lazyLoad}
                    height={height}
                    testId={testId}
                />
            )}
        </Box>
    )
}

const VideoCellContent: FunctionComponent<Pick<CarouselCellProps, 'isSelected' | 'imageScaleMode' | 'image'> & TestProps> = ({
    image,
    isSelected,
    testId,
    imageScaleMode,
}) => {
    const { imageUrl: src } = image

    return (
        <video
            width="auto"
            height="auto"
            controls={true}
            preload={isSelected ? 'metadata' : 'none'}
            data-testid={testId}
            tabIndex={isSelected ? 0 : -1}
            css={css`
                flex-shrink: 0;
                object-fit: ${imageScaleMode};
                max-width: 100%;

                ${(p) => p.theme.media.tablet} {
                    max-height: calc(100% - 90px);
                    max-width: calc(100% - 90px);
                }
            `}
        >
            <source src={src} {...(src.endsWith('mp4') && { type: 'video/mp4' })} />
        </video>
    )
}

const ImageCellContent: FunctionComponent<
    Pick<CarouselCellProps, 'isSelected' | 'imageScaleMode' | 'image' | 'onFlickityImageClick' | 'lazyLoad' | 'height'> & TestProps
> = ({ imageScaleMode, image, onFlickityImageClick, testId, height, lazyLoad, isSelected }) => {
    const { imageUrl: src, alt } = image
    const lazyBoxProps = lazyLoad && { 'data-flickity-bg-lazyload': src }
    const lazyImgProps = lazyLoad ? { 'data-flickity-lazyload': src } : { src: src }

    return (
        <ImageClickWrapper isSelected={isSelected} image={image} onFlickityImageClick={onFlickityImageClick}>
            <Box
                {...lazyBoxProps}
                role={image.mediaType}
                aria-label={alt}
                testId={`background-${testId}`}
                height={height}
                css={css`
                    background-image: ${!lazyLoad ? `url("${src}")` : 'none'};
                    background-size: ${imageScaleMode};
                    background-position: center center;
                    background-repeat: no-repeat;
                    min-width: 100%;
                    width: 100%;
                    display: none;

                    @media only screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        display: block;
                    }
                `}
            />
            <img
                {...lazyImgProps}
                alt={alt}
                width={imageScaleMode === 'cover' ? '100%' : 'auto'}
                height={imageScaleMode === 'cover' ? '100%' : 'auto'}
                data-testid={testId}
                css={css`
                    flex-shrink: 0;
                    object-fit: ${imageScaleMode};

                    @media only screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        display: none;
                    }
                `}
            />
        </ImageClickWrapper>
    )
}

const ImageClickWrapper: FunctionComponent<PropsWithChildren<Pick<CarouselCellProps, 'image' | 'onFlickityImageClick' | 'isSelected'>>> = ({
    image,
    onFlickityImageClick,
    children,
    isSelected,
}) => {
    const onClick = useCallback(() => {
        onFlickityImageClick?.(image)
    }, [image, onFlickityImageClick])

    return image.link ? (
        <InvisibleAnchor
            href={image.link}
            tabIndex={isSelected ? 0 : -1}
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault()
                onClick()
            }}
        >
            {children}
        </InvisibleAnchor>
    ) : (
        <InvisibleButton tabIndex={-1} onClick={onClick}>
            {children}
        </InvisibleButton>
    )
}

const InvisibleButton = styled.button`
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: 0;
    outline: none;
    user-select: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
    justify-content: center;
`

const InvisibleAnchor = styled.a`
    user-select: none;
    cursor: pointer;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
`

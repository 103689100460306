import React, { FunctionComponent, RefObject } from 'react'
import { SecurityHintsPrivate } from '@bbx/search-journey/sub-domains/ad-detail/components/common/SecurityHints/SecurityHintsPrivate'

export const AutoMotorSecurityHintsPrivate: FunctionComponent<{ securityHintsRef?: RefObject<HTMLAnchorElement> }> = ({
    securityHintsRef,
}) => {
    return (
        <SecurityHintsPrivate
            heading="Die wichtigsten Tipps zum sicheren Kaufen:"
            hints={[
                'Artikelbeschreibung und Kontaktdaten kontrollieren. (Vorsicht falls der Anbieter in den Verhandlungen andere Angaben macht als in der Anzeige)',
                'Nur das "Anfrage senden" Formular nutzen. Sende keine E-Mails an Adressen im Anzeigentext oder in Bildern.',
                'Produkt vor dem Kauf begutachten bzw. erst bei Abholung bezahlen',
                'Von privaten Verkäufern aus dem Ausland und Überweisungen ins Ausland Abstand nehmen.',
                'Von der Bezahlung mit Bargeldtransfer z.B.: Western Union Abstand nehmen.',
            ]}
            securityHintsRef={securityHintsRef}
        />
    )
}

import { ImgSrcSet } from '@wh/common/chapter/components/LazyPicture/LazyPicture'
import { Options } from 'flickity'

export interface ImageGalleryData<T = {}> {
    alt: string
    imageUrl: string
    thumbnailUrl?: string
    link?: string
    mediaType?: MediaType
    similarImageSearchUrl?: string | null
    /**
     * Used if addtional data is needed for the click handler
     */
    additionalData?: T
}

export interface ImageSrcSetGalleryData<T = {}> extends Omit<ImageGalleryData<T>, 'imageUrl'> {
    imageUrl: string | ImgSrcSet
}

type MediaType = 'image' | 'video'

export const FLICKITY_CAROUSEL_CELL_CLASSNAME = 'carousel-cell'
export const FLICKITY_CAROUSEL_CLASSNAME = 'carousel'

export const flickityCarouselOptionsDefault: FlickityOptionsExtended = {
    // Infinite scroll
    wrapAround: true,
    // Reset gallery size and position after having loaded the images
    imagesLoaded: true,
    pageDots: true,
    // Only consider nodes with this class as images to be displayed
    cellSelector: `.${FLICKITY_CAROUSEL_CELL_CLASSNAME}`,
    lazyLoad: 1,
    // @ts-ignore
    fullscreen: true,
    bgLazyLoad: 1,
    dragThreshold: 10,
    arrowShape: {
        x0: 10,
        x1: 60,
        y1: 50,
        x2: 60,
        y2: 40,
        x3: 20,
    },
}

export const flickityThumbnailOptionsDefault: FlickityOptionsExtended = {
    pageDots: false,
    asNavFor: `.${FLICKITY_CAROUSEL_CLASSNAME}`,
    cellAlign: 'left',
    lazyLoad: 8,
    contain: true,
    arrowShape: {
        x0: 10,
        x1: 55,
        y1: 45,
        x2: 60,
        y2: 40,
        x3: 20,
    },
}

export type FlickityOptionsExtended = Options & {
    fullscreen?: boolean
    onChange?: () => void
    onClick?: () => void
    showMultiImages?: boolean
    hideCounter?: boolean
    hideBackground?: boolean
}

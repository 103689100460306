import React, { FunctionComponent, ReactNode } from 'react'
import { IconButton } from '@wh-components/core/Button/Button'
import ShareIcon from '@wh-components/icons/Share'
import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { AdDetailSaveAdButton } from '@bbx/search-journey/sub-domains/ad-detail/components/common/SaveAdButton/AdDetailSaveAdButton'
import { ShareButton } from '@wh/common/chapter/components/ShareButton'
import { Heading } from '@wh-components/core/Heading/Heading'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { css } from 'styled-components'
import { formatIsoDate } from '@wh/common/chapter/lib/formatter'
import { ReservedBadge } from '@wh/common/chapter/components/ReservedBadge'
import { PrintButton } from '@wh/common/chapter/components/PrintButton'

interface AdHeaderProps {
    advertDetails: AdvertDetails
    teaserAttributes?: ReactNode
    locationTeaser?: ReactNode
}

export const AdHeader: FunctionComponent<AdHeaderProps> = ({ advertDetails, teaserAttributes, locationTeaser }) => (
    <Box paddingHorizontal={{ phone: 'm', tablet: 0 }}>
        <Box marginBottom={{ phone: 's', tablet: 'xs' }} display="flex" justifyContent="space-between">
            <Box>
                <Heading
                    text={advertDetails.description}
                    level={1}
                    fontSize={{ phone: 'xl', tablet: 'xxxl' }}
                    fontWeight="normal"
                    breakWord={true}
                    minWidth={1}
                    testId="ad-detail-header"
                />
                <ReservedBadge advertStatus={advertDetails.advertStatus} position="static" />
                <Box display={{ tablet: 'none' }} marginTop="xs">
                    {locationTeaser}
                </Box>
            </Box>
            <Box
                marginLeft={{ phone: 'm', tablet: 'xl' }}
                display={{ phone: 'flex', print: 'none' }}
                alignItems="flex-start"
                justifyContent="flex-end"
                flexShrink={0}
                css={css`
                    & > :not(:last-child) {
                        margin-right: ${(p) => p.theme.space.s}px;
                    }
                `}
            >
                <AdDetailSaveAdButton
                    adId={advertDetails.id}
                    adTitle={advertDetails.description}
                    taggingData={advertDetails.taggingData}
                    testId="saveAdButton"
                    variant="icon"
                />
                <ShareButton
                    bodyText={advertDetails.description}
                    url={advertDetails.seoMetaData.canonicalUrl}
                    adId={advertDetails.id}
                    taggingData={advertDetails.taggingData}
                    shareEmailEvent="addetail_share_email_click"
                    shareWhatsAppEvent="addetail_share_whatsapp_click"
                    shareFacebookEvent="addetail_share_facebook_click"
                    shareTwitterEvent="addetail_share_twitter_click"
                    shareLinkedInEvent="addetail_share_linkedin_click"
                    shareLinkEvent="addetail_share_link_click"
                >
                    {(toggle) => (
                        <IconButton
                            size={{ phone: 'medium', tablet: 'small' }}
                            variant="outline"
                            Icon={ShareIcon}
                            name="ShareButton"
                            aria-label="Teilen"
                            testId="shareButton"
                            onClick={toggle}
                        />
                    )}
                </ShareButton>

                <PrintButton testId="printButton" id="printButton" taggingData={advertDetails.taggingData} />
            </Box>
        </Box>

        <Box display="flex" justifyContent="space-between">
            {teaserAttributes && (
                <Box minWidth={0} overflow="hidden">
                    {teaserAttributes}
                </Box>
            )}
            {locationTeaser && (
                <Box minWidth={0} overflow="hidden" display={{ phone: 'none', tablet: 'block' }}>
                    {locationTeaser}
                </Box>
            )}
            <Box display={{ phone: 'none', tablet: 'flex' }} alignItems="flex-end" justifyContent="flex-end" flexShrink={0} marginLeft="m">
                <Text fontSize="s" color="palette.elephant">
                    <Text testId="ad-detail-ad-edit-date-top">
                        Zuletzt geändert: {formatIsoDate(advertDetails.changedDate, 'DD.MM.YYYY, HH:mm [Uhr]')}
                    </Text>
                    &nbsp;|&nbsp;
                    <Text testId="ad-detail-ad-wh-code-top">willhaben-Code: {advertDetails.id}</Text>
                </Text>
            </Box>
        </Box>
    </Box>
)

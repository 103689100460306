import React, { FunctionComponent, useContext } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { RenderSlotId } from '@wh/common/digital-advertising/config'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { css } from 'styled-components'
import { SpaceProps } from '@wh-components/system/space'
import { werbungPlaceholderEmptyAfter } from '../werbungPlaceholderEmptyAfter'
import { werbungBeforeCssAdDetailSmall } from '../werbungBeforeCss'

export const AdDetailSmallRenderSlot: FunctionComponent<{ className?: string; showPlaceholder?: boolean } & SpaceProps> = ({
    className,
    showPlaceholder = true,
    ...spaceProps
}) => {
    const advertisingState = useContext(AdvertisingStateContext)
    const isClosedAdSlot = (advertisingState.pageModifications.closedAdSlots || []).includes(RenderSlotId.AD_DETAIL_SMALL)

    // display:flex implicitly makes child elements display:block, which is necessary to avoid the extra spacing at the bottom
    return (
        <Box
            flex="1 1 auto"
            justifyContent="center"
            width="100%"
            id={RenderSlotId.AD_DETAIL_SMALL}
            className={className}
            aria-hidden="true"
            {...spaceProps}
            position="relative"
            zIndex="content"
            paddingHorizontal={{ phone: showPlaceholder ? 'm' : '0', tablet: '0' }}
            css={css`
                iframe {
                    /* avoids extra spacing at the bottom */
                    display: block;
                }

                display: none;
                ${(p) => p.theme.media.only.phone} {
                    display: ${isClosedAdSlot ? 'none' : 'flex'};
                }

                ${(p) => p.theme.media.print} {
                    display: none;
                }

                ${showPlaceholder &&
                css`
                    min-height: 250px;

                    /* set size only during loading, afterwards should jump to size of Advertisement */
                    &:empty {
                        width: 100%;
                        height: 250px;
                    }

                    /* defines :after */
                    ${werbungPlaceholderEmptyAfter(250)}
                    ${werbungBeforeCssAdDetailSmall}
                `}
            `}
        />
    )
}

import { SimilarAdsContextLink } from '@bbx/common/types/contextLinks'
import { useEffect, useRef, useState } from 'react'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { getSimilarAds } from '@bbx/search-journey/common/api/similarAdsApiClient'
import { useIsInView } from '@wh/common/chapter/hooks/useIsInView'
import { callActionEvent, callSelfPromotionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { VerticalId, verticalIdMap } from '@wh/common/chapter/types/verticals'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'

export const useSimilarAds = (
    similarAdsRecommendationContextLink: SimilarAdsContextLink,
    taggingData: TaggingData,
    verticalId: VerticalId,
) => {
    const [searchResult, setSearchResult] = useState<SearchResult | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const visibilityTrackingRef = useRef<HTMLDivElement>(null)
    const [isInView] = useIsInView(visibilityTrackingRef, '100px', undefined, true)

    useEffect(() => {
        async function fetchSimilarAds() {
            if (similarAdsRecommendationContextLink) {
                try {
                    const searchResult_ = await getSimilarAds(similarAdsRecommendationContextLink)
                    setSearchResult(() => searchResult_)
                    trackSimilarAdsImpression(
                        'ad_widget_in_view',
                        similarAdsRecommendationContextLink,
                        taggingData,
                        verticalId,
                        searchResult_,
                    )
                } catch (error) {
                    console.error('getSimilarAds: ', error)
                } finally {
                    setIsLoading(false)
                }
            }
        }

        if (isInView) {
            fetchSimilarAds()
        }
    }, [isInView, similarAdsRecommendationContextLink, taggingData, verticalId])

    useEffect(() => {
        trackSimilarAdsImpression('ad_widget_loaded', similarAdsRecommendationContextLink, taggingData, verticalId)
    }, [similarAdsRecommendationContextLink, taggingData, verticalId])

    return { searchResult, isLoading, visibilityTrackingRef }
}

export const nrOfSimilarAdsShown = 9
const nrOfSimilarAdsShownBap = 4

const trackSimilarAdsImpression = (
    event: 'ad_widget_in_view' | 'ad_widget_loaded',
    similarAdsRecommendationContextLink: SimilarAdsContextLink,
    taggingData: TaggingData,
    verticalId: VerticalId,
    searchResult?: SearchResult | undefined,
) => {
    callSelfPromotionEvent(event, taggingData, {
        ...getWidgetAndPage(similarAdsRecommendationContextLink, verticalId),
        adIds:
            searchResult?.advertSummaryList?.advertSummary
                .slice(0, verticalIdMap.BAP === verticalId ? nrOfSimilarAdsShownBap : nrOfSimilarAdsShown)
                .map((advertSummary) => advertSummary.id) ?? [],
    })
}

export const trackSimilarAdsImpressionEndlessBap = (taggingData: TaggingData, advertSummaries?: AdvertSummary[] | undefined) => {
    callSelfPromotionEvent('ad_widget_in_view', taggingData, {
        ...getWidgetAndPageSimilarAdsEndlessBap(),
        adIds: advertSummaries?.slice(nrOfSimilarAdsShown).map((advertSummary) => advertSummary.id) ?? [],
    })
}

export const trackSimilarAdsClick = (
    similarAdsRecommendationContextLink: SimilarAdsContextLink,
    taggingData: TaggingData,
    verticalId: VerticalId,
    adId: string,
    index: number,
) => {
    callActionEvent('ad_widget_ad_click', taggingData, {
        ...getWidgetAndPage(similarAdsRecommendationContextLink, verticalId),
        adId: adId,
        adIndex: index + 1,
    })

    callSelfPromotionEvent('ad_widget_click_interaction', taggingData, getWidgetAndPage(similarAdsRecommendationContextLink, verticalId))
}

export const trackSimilarAdsEndlessBapClick = (taggingData: TaggingData, adId: string, index: number) => {
    callActionEvent('ad_widget_ad_click', taggingData, {
        ...getWidgetAndPageSimilarAdsEndlessBap(),
        adId: adId,
        adIndex: index + 1,
    })

    callSelfPromotionEvent('ad_widget_click_interaction', taggingData, getWidgetAndPageSimilarAdsEndlessBap())
}

export const trackSimilarAdsShowMore = (
    eventName: TaggingActionEvent,
    similarAdsRecommendationContextLink: SimilarAdsContextLink,
    taggingData: TaggingData,
    verticalId: VerticalId,
) => {
    callActionEvent(eventName, taggingData, getWidgetAndPage(similarAdsRecommendationContextLink, verticalId))
    callSelfPromotionEvent('ad_widget_click_interaction', taggingData, getWidgetAndPage(similarAdsRecommendationContextLink, verticalId))
}

const getWidgetAndPage = (similarAdsRecommendationContextLink: SimilarAdsContextLink, verticalId: VerticalId) => ({
    ...getListNameAndWidgetType(similarAdsRecommendationContextLink, verticalId),
    page: 'AdDetail',
    source: 'ad-view-detail',
})

const getWidgetAndPageSimilarAdsEndlessBap = () => ({
    ...{ taggingWidgetType: 'SimilarAdsWidgetBapEndless', listName: 'external-widget:similar-ads-endless:bap' },
    page: 'AdDetail',
    source: 'ad-view-detail',
})

const getListNameAndWidgetType = (similarAdsRecommendationContextLink: SimilarAdsContextLink, verticalId: VerticalId) => {
    if (similarAdsRecommendationContextLink.type === 'similar-ads') {
        switch (verticalId) {
            case verticalIdMap.BAP:
                return { taggingWidgetType: 'SimilarAdsWidgetBap', listName: 'external-widget:similar-ads:bap' }
            case verticalIdMap.MOTOR:
                return { taggingWidgetType: 'SimilarAdsWidgetCar', listName: 'external-widget:similar-ads:motor' }
            case verticalIdMap.ESTATE:
                return { taggingWidgetType: 'SimilarAdsWidgetRE', listName: 'external-widget:similar-ads:estate' }
        }
    } else {
        switch (verticalId) {
            case verticalIdMap.MOTOR:
                return { taggingWidgetType: 'SimilarAdsWidgetCarB2C', listName: 'external-widget:more-from-dealer:motor' }
            case verticalIdMap.ESTATE:
                return { taggingWidgetType: 'SimilarAdsWidgetREB2C', listName: 'external-widget:more-from-dealer:estate' }
            case verticalIdMap.BAP:
                return { taggingWidgetType: 'SimilarAdsWidgetBapB2C', listName: 'external-widget:more-from-dealer:bap' }
        }
    }

    return {}
}

import { useDigitalAdvertising } from '@wh/common/digital-advertising/hooks/useDigitalAdvertising'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { RenderSlotId, RenderSlotIdConfig } from '@wh/common/digital-advertising/config'
import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { useRouter } from 'next/router'

const defaultRenderSlotIdConfig: RenderSlotIdConfig = {
    PHONE: [RenderSlotId.AD_DETAIL_SMALL],
    TABLET: [RenderSlotId.LEADERBOARD_MEDIUM, RenderSlotId.AD_DETAIL_MEDIUM],
    DESKTOP_WITHOUT_SKYSCRAPER: [RenderSlotId.LEADERBOARD_LARGE, RenderSlotId.AD_DETAIL_LARGE],
    DESKTOP_WITH_SKYSCRAPER: [RenderSlotId.LEADERBOARD_LARGE, RenderSlotId.SKYSCRAPER_LARGE, RenderSlotId.AD_DETAIL_LARGE],
}

export const useAdDetailDigitalAdvertisingMotor = (advertDetails: AdvertDetails) =>
    useAdDetailDigitalAdvertising(advertDetails, defaultRenderSlotIdConfig)

export const useAdDetailDigitalAdvertisingEstate = (advertDetails: AdvertDetails) =>
    useAdDetailDigitalAdvertising(advertDetails, defaultRenderSlotIdConfig)

export const useAdDetailDigitalAdvertisingBap = (advertDetails: AdvertDetails) =>
    useAdDetailDigitalAdvertising(advertDetails, defaultRenderSlotIdConfig)

export const useAdDetailDigitalAdvertising = (advertDetails: AdvertDetails, renderSlotIdConfig: RenderSlotIdConfig) => {
    const router = useRouter()
    const isSearchAlert = router.query.searchAgentQueryString !== undefined

    const [didContact, setDidContact] = useState(false)

    const memoizedAdvertisingParameters = useMemo(() => {
        let advertisingParametersV2 = advertDetails.advertisingParametersV2
        if (didContact) {
            advertisingParametersV2 = { ...advertisingParametersV2, pagetype: 'Anfragesenden' }
        }
        advertisingParametersV2 = { ...advertisingParametersV2, search_alert: isSearchAlert.toString() }

        return advertisingParametersV2
    }, [advertDetails.advertisingParametersV2, didContact, isSearchAlert])

    const memoizedDmpParameters = useMemo(() => {
        let dmpParameters = advertDetails.dmpParameters
        if (didContact) {
            dmpParameters = { ...advertDetails.dmpParameters, pagetype: 'Anfragesenden' }
        }
        dmpParameters = { ...dmpParameters, search_alert: isSearchAlert }

        return dmpParameters
    }, [advertDetails.dmpParameters, didContact, isSearchAlert])

    const memoizedDmpUserIdentities = useMemo(() => {
        return advertDetails.dmpUserIdentities
    }, [advertDetails.dmpUserIdentities])

    const setAdvertisingStateContacted = useCallback(() => {
        setDidContact(true)
    }, [])

    // Re-initialize states if advertDetails change (i.e. navigate to a different ad)
    useEffect(() => {
        setDidContact(false)
    }, [advertDetails])

    const result = useDigitalAdvertising(
        renderSlotIdConfig,
        memoizedAdvertisingParameters,
        memoizedDmpParameters,
        memoizedDmpUserIdentities,
    )

    return { ...result, setAdvertisingStateContacted }
}

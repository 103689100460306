import React, { FunctionComponent, ReactNode } from 'react'
import { TestProps } from '@wh-components/core/common'
import { SpaceProps } from '@wh-components/system/space'
import { Box } from '@wh-components/core/Box/Box'
import { Text, crossBrowserBreakWordStyles } from '@wh-components/core/Text/Text'
import { css } from 'styled-components'

interface KeyValueProps extends TestProps, SpaceProps {
    title?: string
    content: ReactNode
    direction?: 'row' | 'column'
    testIdPrefix: string
}

export const KeyValue: FunctionComponent<KeyValueProps> = ({ title, content, direction = 'column', testIdPrefix, ...props }) =>
    content ? (
        <Box
            display="flex"
            flexDirection={direction}
            {...props}
            testId={`${testIdPrefix}-${title}`}
            css={css`
                ${crossBrowserBreakWordStyles}
            `}
        >
            {title && (
                <Text
                    fontWeight="semibold"
                    display="inline-block"
                    breakWord={true}
                    minWidth={direction === 'row' ? 80 : undefined}
                    flexShrink={0}
                    marginRight={direction === 'row' ? 's' : undefined}
                >
                    {title}
                </Text>
            )}
            {content}
        </Box>
    ) : null

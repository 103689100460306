import { BBX_PAGE_SIZE } from '@wh/common/chapter/lib/config/constants'
import { Nullable } from '@wh/common/chapter/types/utilities'
import { ParsedUrlQuery } from 'querystring'

export const defaultPagination = {
    page: 1 as number,
    rows: BBX_PAGE_SIZE as number,
} as const

export type Pagination = typeof defaultPagination

export const feSortOrders = ['1', '2'] as const
export type FESortOrder = (typeof feSortOrders)[number]

export const beSortOrders = ['-createdAt', '+createdAt'] as const
export type BESortOrder = (typeof beSortOrders)[number]

export type SortOrder = { [K in FESortOrder]: BESortOrder }

// Note: it seems usually sort query parameter is a number through the app
export const sortOrders: SortOrder = {
    1: '-createdAt',
    2: '+createdAt',
} as const

export const origins = {
    creditCheck: '1',
    tenantProfile: '2',
} as const

export const reasons = {
    paymentSuccessfull: '1',
    timeout: '2',
    creditCheckDeleteSuccess: '3',
} as const

/**
 * (!) this is the pagination for UI. Currently, API pagination starts at page 0
 *
 * @param {ParsedUrlQuery} query the query to parse
 * @returns a Pagination with sensitive defaults
 */
export const extractPagination = (query: ParsedUrlQuery): Pagination => {
    const { page = `${defaultPagination.page}`, rows = `${defaultPagination.rows}` } = query

    return { page: +page, rows: +rows }
}

export const guessExpectedResultsCount = (page: number, rows: number, total: number): number => {
    const totalRows = total ?? rows
    if (totalRows < rows) {
        return totalRows
    }

    const askedCount = page * rows
    if (askedCount > totalRows) {
        const remainingCount = totalRows + rows - askedCount
        return remainingCount < 0 ? 0 : remainingCount
    }
    return rows
}

export const extractSortOrder = (query: ParsedUrlQuery): FESortOrder | undefined => {
    const { sort } = query as { sort?: FESortOrder }

    return sort
}

export const toChatHref = (conversationId?: Nullable<string>): string => {
    const chatBaseUrl = '/iad/myprofile/chat'
    if (conversationId) {
        return `${chatBaseUrl}/id/${conversationId}`
    }
    return chatBaseUrl
}

export const toRealEstateAdHref = (encodedRealEstateUri?: Nullable<string>): string => {
    const realEstateBaseUrl = '/iad/immobilien'
    if (encodedRealEstateUri) {
        return `${realEstateBaseUrl}/d/${decodeURI(encodedRealEstateUri)}`
    }
    return realEstateBaseUrl
}

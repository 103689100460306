/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable no-restricted-imports */
/* eslint-disable import/no-unassigned-import */
import 'flickity/css/flickity.css'
import 'flickity-fullscreen/fullscreen.css'
import { StyledClassNameProp } from '@bbx/common/types/styled'
import { BbxRouter } from '@wh/common/chapter/lib/routing/bbxRouter'
import { useRouter } from 'next/router'
import React, { FunctionComponent, PropsWithChildren, useEffect, useRef } from 'react'
import { FlickityOptionsExtended } from './ImageGallery.settings'

interface FlickityWrapperProps extends StyledClassNameProp, PropsWithChildren {
    flickityOptions: FlickityOptionsExtended
    requireImagesLoaded?: boolean
    requireAsNavFor?: boolean
    requireFullscreen?: boolean
    currentIndex?: number
    onFlickityReady?: () => void
    onFlickityChange?: (index: number, isFullscreen: boolean) => void
    onFullscreenOpen?: () => void
    onFlickityImageClick?: () => void
}

type Cell = {
    element: Element
}

const clickTargetIsVideo = (event: React.MouseEvent<HTMLElement>) => {
    return (event?.target as HTMLElement)?.tagName.toLowerCase() === 'video'
}

export const FlickityWrapper: FunctionComponent<FlickityWrapperProps> = ({
    flickityOptions,
    requireImagesLoaded,
    requireAsNavFor,
    requireFullscreen,
    currentIndex,
    onFlickityReady,
    onFlickityChange,
    onFullscreenOpen,
    onFlickityImageClick,
    children,
    className,
}) => {
    const flickity = useRef<Flickity | null>(null)
    const flickityRef = React.createRef<HTMLDivElement>()
    let isFullscreen: boolean = false
    const router = useRouter()

    useEffect(() => {
        if (flickityRef.current) {
            // We only load Flickity on the client as it does not support SSR

            const Flickity = require('flickity')

            require('flickity-bg-lazyload')

            if (requireImagesLoaded) {
                require('flickity-imagesloaded')
            }
            if (requireAsNavFor) {
                require('flickity-as-nav-for')
            }
            if (requireFullscreen) {
                require('flickity-fullscreen')
                router.events.on('hashChangeStart', hashChangeStart)
            }

            flickity.current = new Flickity(flickityRef.current, {
                ...flickityOptions,
                draggable: true,
                on: {
                    change: (index: number) => onChange(index),
                    ready: onReady,
                    staticClick: onStaticClick,
                    fullscreenChange: onFullscreenChange,
                },
            })
        }

        return () => {
            // @ts-ignore
            flickity.current?.off('ready', onReady)
            // @ts-ignore
            flickity.current?.off('change', onChange)
            if (requireFullscreen) {
                router.events.off('hashChangeStart', hashChangeStart)
            }
        }
    })

    const onReady = () => {
        if (onFlickityReady) {
            onFlickityReady()
        }
    }

    const hashChangeStart = (url: string) => {
        if (flickity && currentIndex !== undefined) {
            if (url.indexOf('#fullscreen') === -1) {
                // @ts-ignore
                flickity.current.exitFullscreen()
                isFullscreen = false
                // this should only happen when user uses browser forward to open fullscreen mode again
            } else if (url.indexOf('#fullscreen') !== -1 && !isFullscreen) {
                // @ts-ignore
                flickity.current.viewFullscreen()
            }
        }
    }

    const onStaticClick = (event: React.MouseEvent<HTMLElement>, _pointer: unknown, _cellElement: unknown, _cellIndex: number) => {
        if (flickityOptions.onClick) {
            flickityOptions.onClick()
        }

        if (mayFullscreenBeToggled(event)) {
            // @ts-ignore
            flickity.current.toggleFullscreen()
        } else if (onFlickityImageClick) {
            onFlickityImageClick()
        }
    }

    /*
     * never toggle fullscreen if clicking on video tags
     * otherwise Toggle fullscreen if
     * it's activated for the gallery (requireFullscreen)
     * flickity and the currentIndex are set (defensive checks)
     */
    const mayFullscreenBeToggled = (event: React.MouseEvent<HTMLElement>) => {
        return requireFullscreen && flickity && currentIndex !== undefined && !clickTargetIsVideo(event)
    }

    const onChange = (index: number) => {
        // @ts-ignore
        const isPlaying = flickity.current.player.state === 'playing'

        if (onFlickityChange) {
            onFlickityChange(index, isFullscreen)
        }
        if (flickityOptions.onChange) {
            if (!isPlaying) {
                flickityOptions.onChange()
            }
        }

        pauseAllVideos()
    }

    const pauseAllVideos = () => {
        // cells are wrongly typed
        const cells = ((flickity.current?.cells as unknown) ?? []) as Cell[]

        cells.forEach((cell: Cell) => {
            const videoElement = getVideoElement(cell)
            if (videoElement) {
                videoElement.pause()
            }
        })
    }

    const getVideoElement = (cell: Cell) => {
        return cell.element.querySelector('video')
    }

    const onFullscreenChange = (isFullscreenMode: boolean) => {
        isFullscreen = isFullscreenMode

        if (isFullscreen) {
            if (window.location.hash !== '#fullscreen') {
                BbxRouter.push({
                    href: `${router.asPath}#fullscreen`,
                    clientSideRouting: true,
                    options: { shallow: true, scroll: false },
                })
            }

            onFullscreenOpen?.()
        } else {
            if (window.location.hash === '#fullscreen') {
                BbxRouter.back()
            }
        }
    }

    if (flickity && currentIndex !== undefined) {
        flickity.current?.select(currentIndex)
    }

    return (
        <div ref={flickityRef} className={className}>
            {children}
        </div>
    )
}

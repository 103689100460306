import { ContactAdvertiserRequest } from '@bbx/common/types/Messaging'
import { validationErrorMessages, firstNameValidation, lastNameValidation } from '@bbx/common/lib/validation'
import { removeUndefined } from '@wh/common/chapter/lib/functionalHelpers'
import { isNonEmpty } from '@wh/common/chapter/lib/validation'

export type SendMessageRequestFormData = Pick<ContactAdvertiserRequest, 'mailContent'> & {
    firstName: string
    lastName: string
    shareTenantProfile: boolean
}

export const validateAdContactFormMessaging = (
    values: SendMessageRequestFormData,
): Partial<Record<AdContactFormMessagingField, string>> => {
    return removeUndefined({
        mailContent: !isNonEmpty(values.mailContent) ? validationErrorMessages.EMPTY_ERROR : undefined,
        firstName: firstNameValidation(values.firstName),
        lastName: lastNameValidation(values.lastName),
    })
}

export type AdContactFormMessagingField = keyof SendMessageRequestFormData

export const adContactFormMessagingFieldToLabelMap: Record<AdContactFormMessagingField, string> = {
    mailContent: '',
    firstName: '',
    lastName: '',
    shareTenantProfile: '',
}

export const adContactFormMessagingFieldRequiredness = (
    _values: SendMessageRequestFormData,
): Record<AdContactFormMessagingField, boolean> => {
    return {
        mailContent: true,
        firstName: true,
        lastName: false,
        shareTenantProfile: false,
    }
}

import React, { FunctionComponent, useContext } from 'react'
import { SpaceProps } from '@wh-components/system/space'
import { AdServiceLinkList } from '@bbx/common/types/ad-detail/AdServices'
import { findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { Text } from '@wh-components/core/Text/Text'
import { DmpStateContext } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'
import { Box } from '@wh-components/core/Box/Box'

interface TextLinksProps extends SpaceProps {
    landingPageLinks: AdServiceLinkList | null
}

export const TextLinks: FunctionComponent<TextLinksProps> = ({ landingPageLinks, ...props }) => {
    const { trackCustomDmpEvent } = useContext(DmpStateContext)

    if (!landingPageLinks || landingPageLinks.adServiceLinks.length === 0) {
        return null
    }

    return (
        <Box
            display="flex"
            gap={{ phone: 'xs', tablet: 0 }}
            flexDirection="column"
            alignItems="flex-start"
            testId="contact-box-text-links"
            {...props}
        >
            {landingPageLinks.adServiceLinks.map((link, index) => {
                const website = findContextLinkWithId('landingPageWebsite', link.contextLinkList)

                return (
                    website?.uri && (
                        <ClientRoutingAnchorLink
                            key={index}
                            type="anchor"
                            href={website.relativePath ?? website.uri}
                            rel="nofollow"
                            onClick={() =>
                                trackCustomDmpEvent('UserAction', {
                                    eventName: 'TapTextlink',
                                    textlinkDescription: link.dmpTextlinkDescription,
                                })
                            }
                            onAuxClick={() =>
                                trackCustomDmpEvent('UserAction', {
                                    eventName: 'TapTextlink',
                                    textlinkDescription: link.dmpTextlinkDescription,
                                })
                            }
                        >
                            <Text fontSize="s">{link.description}</Text>
                        </ClientRoutingAnchorLink>
                    )
                )
            })}
        </Box>
    )
}

import { findContextLinkWithId, SimilarAdsContextLink } from '@bbx/common/types/contextLinks'
import React, { FunctionComponent } from 'react'
import {
    nrOfSimilarAdsShown,
    trackSimilarAdsClick,
    trackSimilarAdsShowMore,
    useSimilarAds,
} from '@bbx/search-journey/sub-domains/ad-detail/components/common/SimilarAds/useSimilarAds'
import { SimilarAdsLayout } from '../../../common/SimilarAds/SimilarAdsLayout'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { AutoMotorAdvertSummaryComponent } from '@bbx/search-journey/common/components/AdvertSummary/AutoMotorAdvertSummaryComponent'
import { verticalIdMap } from '@wh/common/chapter/types/verticals'
import { buildSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'

export interface AutoMotorSimilarAdsProps {
    similarAdsRecommendationContextLink: SimilarAdsContextLink
    taggingData: TaggingData
}

export const AutoMotorSimilarAds: FunctionComponent<AutoMotorSimilarAdsProps> = ({ similarAdsRecommendationContextLink, taggingData }) => {
    const verticalId = verticalIdMap.MOTOR

    const { searchResult, isLoading, visibilityTrackingRef } = useSimilarAds(similarAdsRecommendationContextLink, taggingData, verticalId)
    const getMoreAdsLink =
        searchResult && buildSeoUrl(findContextLinkWithId('similarAdsSearchLink', searchResult.searchContextLinks)?.relativePath)

    const advertSummaries = searchResult?.advertSummaryList.advertSummary.slice(0, nrOfSimilarAdsShown).map((advertSummary, index) => (
        <AutoMotorAdvertSummaryComponent
            key={advertSummary.id}
            advertSummary={advertSummary}
            onClickTaggingEvent={() => {
                trackSimilarAdsClick(similarAdsRecommendationContextLink, taggingData, verticalId, advertSummary.id, index)
            }}
        />
    ))

    return (
        <SimilarAdsLayout
            advertSummaries={advertSummaries}
            isLoading={isLoading}
            moreAdsLink={getMoreAdsLink}
            visibilityTrackingRef={visibilityTrackingRef}
            heading={similarAdsRecommendationContextLink.displayText}
            onClickShowAllTagging={() => {
                trackSimilarAdsShowMore('ad_widget_show_all_button_click', similarAdsRecommendationContextLink, taggingData, verticalId)
            }}
            onClickShowAllButtonTagging={() => {
                trackSimilarAdsShowMore('ad_widget_show_all_click', similarAdsRecommendationContextLink, taggingData, verticalId)
            }}
        />
    )
}

import { ContactSuggestion } from '@bbx/common/types/Messaging'
import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'

export type ContactTypeWithDetails =
    | {
          type: 'EMAIL'
          contactSuggestions: ContactSuggestion[]
      }
    | {
          type: 'MESSAGING'
      }
    | ExternalContactTypeWithDetails

export interface ExternalContactTypeWithDetails {
    type: 'EXTERNAL'
    buttonText: string
    link: string
}

export const getContactTypeFromAdvertDetails = (advertDetails: AdvertDetails): ContactTypeWithDetails => {
    switch (advertDetails.contactOption.contactType) {
        case 'EMAIL':
            return {
                type: advertDetails.contactOption.contactType,
                contactSuggestions: advertDetails.contactSuggestions?.suggestions || [],
            }
        case 'MESSAGING':
            return {
                type: advertDetails.contactOption.contactType,
            }
        default:
            return {
                type: advertDetails.contactOption.contactType,
                buttonText: advertDetails.contactOption.additionalInfo?.buttonText || '',
                link: advertDetails.contactOption.additionalInfo?.link || '',
            }
    }
}

import React, { FunctionComponent } from 'react'
import { Text } from '@wh-components/core/Text/Text'
import { getSellerType, SellerType } from '@bbx/common/lib/getSellerType'
import { AttributesList, getAttribute } from '@wh/common/chapter/types/Attributes'

interface SellerTypeInfoBoxProps {
    attributes: AttributesList
}

export const SellerTypeInfoBox: FunctionComponent<SellerTypeInfoBoxProps> = ({ attributes }) => {
    const isAnimalAd = getAttribute(attributes.attribute, 'ANIMAL_AD') !== undefined

    return isAnimalAd ? null : (
        <Text as="p" color="palette.elephant" fontSize="xs">
            {getSellerType(attributes) === SellerType.COMMERCIAL ? 'Unternehmen' : 'Privatperson'}
        </Text>
    )
}

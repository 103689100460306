import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { getAdvertContactValue, getAdvertContactAddress } from '@bbx/common/types/ad-detail/AdvertContactDetails'
import { dealerIsOpen } from '@bbx/common/types/ad-detail/OrganisationDetails'

export const getContactData = (advertDetails: AdvertDetails) => {
    const { advertContactDetails, organisationDetails, sellerProfileUserData } = advertDetails

    const name = sellerProfileUserData.name
    const image = sellerProfileUserData.pictureUrl || undefined

    const contactFirstName = getAdvertContactValue(advertContactDetails, 'contactFirstName')
    const contactName = getAdvertContactValue(advertContactDetails, 'contactName')
    const contactName2 = getAdvertContactValue(advertContactDetails, 'contactName/1')
    const companyName = getAdvertContactValue(advertContactDetails, 'companyName')
    const companyName2 = getAdvertContactValue(advertContactDetails, 'companyName/1')
    const websiteUrl = getAdvertContactValue(advertContactDetails, 'websiteUrl')
    const websiteUrl2 = getAdvertContactValue(advertContactDetails, 'websiteUrl/1')
    const faxNumber = getAdvertContactValue(advertContactDetails, 'fax')
    const faxNumber2 = getAdvertContactValue(advertContactDetails, 'fax/1')
    const address = getAdvertContactAddress(advertContactDetails, 'address')
    const companyAddress = getAdvertContactAddress(advertContactDetails, 'address_vcard')
    const immoCardId = getAdvertContactValue(advertContactDetails, 'immoCardId')
    const immoCardCompanyId = getAdvertContactValue(advertContactDetails, 'immoCardCompanyId')
    const immoCardId2 = getAdvertContactValue(advertContactDetails, 'immoCardId/1')
    const immoCardCompanyId2 = getAdvertContactValue(advertContactDetails, 'immoCardCompanyId/1')

    const companyYears = getAdvertContactValue(advertContactDetails, 'companyYears')
    const nowOpen = dealerIsOpen(organisationDetails.openingHours)
    const userSince = sellerProfileUserData.registerDate
    const activeAdCount = sellerProfileUserData.activeAdCount

    const phoneNumbers = [
        getAdvertContactValue(advertContactDetails, 'phoneNo') || '',
        getAdvertContactValue(advertContactDetails, 'phoneNo2') || '',
    ].filter(Boolean)

    const phoneNumbers2 = [
        getAdvertContactValue(advertContactDetails, 'phoneNo/1') || '',
        getAdvertContactValue(advertContactDetails, 'phoneNo2/1') || '',
    ].filter(Boolean)

    return {
        name,
        image,
        contactFirstName,
        contactName,
        contactName2,
        companyName,
        companyName2,
        websiteUrl,
        websiteUrl2,
        faxNumber,
        faxNumber2,
        phoneNumbers,
        phoneNumbers2,
        address,
        companyAddress,
        immoCardId,
        immoCardCompanyId,
        immoCardId2,
        immoCardCompanyId2,
        companyYears,
        nowOpen,
        userSince,
        activeAdCount,
    }
}

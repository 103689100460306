import { CdcLastViewedAdsForAllVerticals, LastViewedAdsSliderType } from '@bbx/common/types/UserFeed'
import { getLastViewedAds } from '@bbx/search-journey/common/api/cdcApiClient'
import { getLastViewedAdsVerticalKey } from '@bbx/search-journey/common/lib/useLastViewedAds'
import { useProfileData } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { useIsInView } from '@wh/common/chapter/hooks/useIsInView'
import { getUniversalBbxCookie } from '@wh/common/chapter/types/cookies'
import { VerticalId } from '@wh/common/chapter/types/verticals'
import React, { useEffect, useState } from 'react'

export const useLoadLastViewedAdsWidget = (
    verticalId: VerticalId,
    currentAdId: number,
    visibilityRef: React.RefObject<HTMLDivElement | HTMLAnchorElement>,
) => {
    const [lastViewedAdsWidget, setLastViewedAdsWidget] = useState<LastViewedAdsSliderType | undefined>(undefined)
    const [isTargetInView] = useIsInView(visibilityRef, '100px')
    const [profileData] = useProfileData()

    useEffect(() => {
        const loadLastViewedAdsWidget = async () => {
            try {
                const lastViewedAdsAsString = getUniversalBbxCookie('bbxLastViewed', undefined) || '{}'
                const lastViewedAdsAsObject = JSON.parse(lastViewedAdsAsString) as CdcLastViewedAdsForAllVerticals

                const key = getLastViewedAdsVerticalKey(verticalId)
                const lastViewedAdsForVertical = lastViewedAdsAsObject[key]

                if (lastViewedAdsForVertical) {
                    const lastViewedAds = await getLastViewedAds(lastViewedAdsForVertical, verticalId, currentAdId)
                    setLastViewedAdsWidget(lastViewedAds)
                }
            } catch (_error) {}
        }

        if (isTargetInView) {
            loadLastViewedAdsWidget()
        }
    }, [isTargetInView, profileData, verticalId, currentAdId])

    return lastViewedAdsWidget
}

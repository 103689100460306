import { Thumbnails } from '@bbx/search-journey/common/components/ImageGallery/Thumbnails'
import { Box } from '@wh-components/core/Box/Box'
import { ResponsiveValue } from '@wh-components/system'
import { ImageScaleModes } from '@wh/common/chapter/components/AdImage'
import { PlaceholderImage } from '@wh/common/chapter/components/PlaceholderImage'
import { AdType } from '@wh/common/chapter/types/AdType'
import { VerticalShortName } from '@wh/common/chapter/types/verticals'
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { css } from 'styled-components'
import { Carousel } from './Carousel'
import {
    flickityCarouselOptionsDefault,
    FlickityOptionsExtended,
    flickityThumbnailOptionsDefault,
    ImageGalleryData,
} from './ImageGallery.settings'

export interface ImageGalleryProps {
    imageData: ImageGalleryData[] | []
    vertical: VerticalShortName | undefined
    adType: AdType | undefined
    isHouse?: boolean
    showThumbnails?: boolean
    flickityCarouselOptions?: FlickityOptionsExtended
    flickityThumbnailOptions?: FlickityOptionsExtended
    alwaysShowPrevNextButtons?: boolean
    height: ResponsiveValue<string>
    thumbnailWidth?: ResponsiveValue<string>
    imageScaleMode?: ImageScaleModes
    placeholderSize?: string
    onFlickityImageClick?: (currentImageData: ImageGalleryData) => void
    carouselOverlay?: (currentIndex: number) => ReactNode
    overrideIndex?: number
}

export const ImageGallery: FunctionComponent<ImageGalleryProps> = ({
    imageData,
    vertical,
    adType,
    isHouse,
    showThumbnails = true,
    flickityCarouselOptions,
    flickityThumbnailOptions,
    alwaysShowPrevNextButtons,
    height,
    thumbnailWidth = { tablet: '16.5%', desktop: '13.75%' },
    imageScaleMode,
    placeholderSize = '160px',
    onFlickityImageClick,
    carouselOverlay,
    overrideIndex,
}) => {
    const [currentIndex, setCurrentIndex] = useState(0)
    const refCurrentIndex = useRef(currentIndex)

    const [isFlickityReady, setIsFlickityReady] = useState(false)

    const handleFlickityReady = useCallback(() => {
        setIsFlickityReady(true)
    }, [])

    useEffect(() => {
        if (!overrideIndex) {
            return
        }
        setCurrentIndex(overrideIndex)
    }, [overrideIndex])

    const handleChange = useCallback((index: number, _isFullscreen: boolean) => {
        setCurrentIndex(index)
        refCurrentIndex.current = index
    }, [])

    const images = imageData ?? []
    const renderSkeleton = images.length === 0

    if (renderSkeleton) {
        return (
            <Box
                css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    background-color: ${(p) => p.theme.colors.palette.babyseal};
                    ${(p) => p.theme.media.only.phone} {
                        display: none;
                    }
                `}
            >
                <Box height={placeholderSize} width={placeholderSize}>
                    <PlaceholderImage vertical={vertical} adType={adType} isHouse={isHouse} />
                </Box>
            </Box>
        )
    } else {
        return (
            <Box position="relative" width="100%">
                <Carousel
                    imageData={images}
                    onChange={handleChange}
                    onFlickityReady={handleFlickityReady}
                    isFlickityReady={isFlickityReady}
                    currentIndex={currentIndex}
                    flickityOptions={{ ...flickityCarouselOptionsDefault, ...flickityCarouselOptions }}
                    alwaysShowPrevNextButtons={alwaysShowPrevNextButtons}
                    height={height}
                    imageScaleMode={imageScaleMode}
                    onFlickityImageClick={onFlickityImageClick}
                    overlay={carouselOverlay}
                />
                {showThumbnails && (
                    <Thumbnails
                        imageData={images}
                        onChange={setCurrentIndex}
                        isFlickityReady={isFlickityReady}
                        flickityThumbnailOptions={{ ...flickityThumbnailOptionsDefault, ...flickityThumbnailOptions }}
                        width={thumbnailWidth}
                    />
                )}
            </Box>
        )
    }
}

import { Box } from '@wh-components/core/Box/Box'
import { useHideDelay } from '@wh/common/chapter/hooks/useHideDelay'
import { useIntersectionObserver } from '@wh/common/chapter/hooks/useIntersectionObserver'
import { rgba } from 'polished'
import React, { Fragment, FunctionComponent, ReactNode, RefObject } from 'react'
import { createGlobalStyle, css } from 'styled-components'

interface AdDetailStickyFooterProps {
    stickyFooterVisibilityTrackingRef: RefObject<HTMLDivElement>
    buttons: ReactNode
}

const transitionDuration = 300

export const AdDetailStickyFooter: FunctionComponent<AdDetailStickyFooterProps> = ({ stickyFooterVisibilityTrackingRef, buttons }) => {
    const stickyFooterVisibilityThreshold = 0.16

    const [inView] = useIntersectionObserver(
        stickyFooterVisibilityTrackingRef,
        { triggerOnce: false, threshold: stickyFooterVisibilityThreshold },
        [],
        undefined,
    )

    const showStickyFooter = !inView
    const stickyFooterVisibilityHidden = !useHideDelay(showStickyFooter, transitionDuration)

    return (
        <Fragment>
            <GlobalStickyFooterStyle />
            <Box
                width="100%"
                display={{ phone: 'block', tablet: 'none' }}
                position="fixed"
                bottom="0"
                left="0"
                testId="sticky-ad-footer"
                aria-hidden={!showStickyFooter}
                data-is-shown={showStickyFooter}
                css={css`
                    z-index: ${(p) => p.theme.zIndices.stickyContent};
                    transition: transform ${transitionDuration / 1000}s;
                    transform: ${showStickyFooter ? 'translateY(0)' : 'translateY(100px)'};
                    /* makes children untabbable */
                    visibility: ${stickyFooterVisibilityHidden ? 'hidden' : undefined};
                `}
            >
                <Box
                    display="flex"
                    padding="s"
                    backgroundColor="palette.white"
                    css={css`
                        border-top: 1px solid ${(p) => p.theme.colors.palette.owl};
                        box-shadow: 0 0 6px -1px ${(p) => rgba(p.theme.colors.palette.black, 0.2)};
                    `}
                >
                    {buttons}
                </Box>
            </Box>
        </Fragment>
    )
}

const GlobalStickyFooterStyle = createGlobalStyle`
    ${(p) => p.theme.media.only.phone} {
        .grid-content {
            margin-bottom: 56px;
        }
    }
`

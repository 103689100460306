import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { css } from 'styled-components'
import { Box, BoxProps } from '@wh-components/core/Box/Box'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { sanitizeAdDescription } from '@bbx/common/lib/xss'
import { ButtonWithLinkStyle } from '@wh-components/core/Button/ButtonWithLinkStyle'
import { AdBoxHeading } from '@bbx/common/components/AdBoxHeading/AdBoxHeading'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import { crossBrowserBreakWordStyles } from '@wh-components/core/Text/Text'
import { ResponsiveValue } from '@wh-components/system'

interface DescriptionProps extends BoxProps {
    heading: string
    description: string | undefined
    stripLinks?: boolean
}

export const Description: FunctionComponent<DescriptionProps> = ({ heading, description, stripLinks, ...props }) => {
    if (!description) {
        return null
    }

    return (
        <Box {...props}>
            <AdBoxHeading text={heading} marginBottom="s" />
            <Box
                dangerouslySetInnerHTML={{ __html: sanitizeAdDescription(description, stripLinks) }}
                testId={`ad-description-${heading}`}
                css={css`
                    p {
                        margin: 0;
                    }

                    a {
                        color: ${(p) => p.theme.colors.palette.primary.main};
                    }
                `}
            />
        </Box>
    )
}

interface CollapsibleDescriptionProps extends DescriptionProps {
    rows?: number
    taggingEvent: TaggingActionEvent
    taggingData: TaggingData
    displayHeading?: ResponsiveValue<string>
}

export const CollapsibleDescription: FunctionComponent<CollapsibleDescriptionProps> = ({
    heading,
    description,
    stripLinks,
    rows = 12,
    taggingEvent,
    taggingData,
    displayHeading = 'block',
    ...props
}) => {
    const [collapsed, setCollapsed] = useState(true)
    const [buttonVisible, setButtonVisible] = useState(false)
    const descriptionBox = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (descriptionBox.current) {
            const visibleHeight = descriptionBox.current.clientHeight
            const maxHeight = descriptionBox.current.scrollHeight

            if (visibleHeight === maxHeight) {
                setCollapsed(false)
            } else {
                setButtonVisible(true)
            }
        }
    }, [description])

    if (!description) {
        return null
    }

    const descriptionMaxHeightByRows = `${rows * 21}px`
    return (
        <Box {...props}>
            <AdBoxHeading text={heading} display={displayHeading} marginBottom="s" />
            <Box
                ref={descriptionBox}
                dangerouslySetInnerHTML={{ __html: sanitizeAdDescription(description, stripLinks) }}
                testId={`ad-description-${heading}`}
                css={css`
                    max-height: ${collapsed ? descriptionMaxHeightByRows : 'none'};
                    overflow: hidden;

                    ${(p) => p.theme.media.print} {
                        max-height: none;
                    }

                    p {
                        margin: 0;
                        ${crossBrowserBreakWordStyles}
                    }

                    a {
                        color: ${(p) => p.theme.colors.palette.primary.main};
                    }
                `}
            />
            {buttonVisible && (
                <ButtonWithLinkStyle
                    onClick={() => {
                        setCollapsed(false)
                        setButtonVisible(false)
                        return callActionEvent(taggingEvent, taggingData)
                    }}
                    fontSize="m"
                    fontWeight="bold"
                    marginTop="s"
                    display={{ print: 'none' }}
                >
                    Mehr anzeigen +
                </ButtonWithLinkStyle>
            )}
        </Box>
    )
}

import React, { FunctionComponent, useContext } from 'react'
import { Card } from '@wh-components/core/Card/Card'
import { Text } from '@wh-components/core/Text/Text'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { AttributesItem } from '@wh/common/chapter/types/Attributes'
import { Formik, FormikHelpers } from 'formik'
import { removeUndefined } from '@wh/common/chapter/lib/functionalHelpers'
import { FormikInput } from '@wh/common/chapter/components/Formik/FormikInputs'
import { DisabledOnSSRButton } from '@wh/common/chapter/components/DisabledOnSSRButton'
import { DaWidget } from '@bbx/common/types/ad-detail/AdServices'
import { Box } from '@wh-components/core/Box/Box'
import { Heading } from '@wh-components/core/Heading/Heading'
import { SpaceProps } from '@wh-components/system/space'
import { filterXSS } from 'xss'
import { DmpStateContext, TrackCustomDmpEventFunction } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'
import { formatDisplayedPrice } from '@bbx/search-journey/sub-domains/ad-detail/lib/formatDisplayedPrice'
import { css } from 'styled-components'
import { AdvertisementHint } from '@bbx/search-journey/sub-domains/ad-detail/components/common/AdvertismentHint/AdvertisementHint'

interface DaWidgetProps {
    price: AttributesItem | undefined
    daWidget: DaWidget
}

export const DaPromotionWidget: FunctionComponent<DaWidgetProps & SpaceProps> = ({ price, daWidget, ...props }) => {
    const { trackCustomDmpEvent } = useContext(DmpStateContext)

    return daWidget ? (
        <Card testId="ad-detail-widget" display={{ phone: 'block', print: 'none' }} {...props}>
            <Heading text={daWidget.headline1} level={2} fontSize="m" marginBottom="xs" />
            <Box color={daWidget.headline2Color} fontWeight="bold" marginBottom="s">
                {daWidget.headline2}
            </Box>
            <Box marginBottom="s">
                {daWidget.widgetTypeId === 2 ? (
                    <WidgetInput
                        link={daWidget.link}
                        buttonText={daWidget.buttonText}
                        price={price ? formatDisplayedPrice(price) : ''}
                        trackCustomDmpEvent={trackCustomDmpEvent}
                        organisationName={daWidget.organisationName}
                        buttonTextColor={daWidget.buttonTextColor}
                        buttonBackgroundColor={daWidget.buttonBackgroundColor}
                        buttonOutlineColor={daWidget.buttonOutlineColor}
                    />
                ) : (
                    <WidgetButton
                        daWidget={daWidget}
                        trackCustomDmpEvent={trackCustomDmpEvent}
                        organisationName={daWidget.organisationName}
                    />
                )}
            </Box>
            <Box display="flex" alignItems="center" marginBottom="s">
                <Text fontSize="xs" color="palette.elephant" marginRight="s">
                    {daWidget.logoText}
                </Text>
                <Box display="flex" alignItems="center">
                    <img src={daWidget.logo} alt="" />
                </Box>
                <CountPixel countPixel={daWidget.countPixel} />
            </Box>
            <AdvertisementHint id="da-widget" advertisers={[daWidget.organisationName]} />
        </Card>
    ) : null
}

interface WidgetFormValues {
    link: string
    buttonText: string
    price: string
}

const WidgetButton: FunctionComponent<{
    daWidget: DaWidget
    trackCustomDmpEvent: TrackCustomDmpEventFunction
    organisationName: string
}> = ({ daWidget, trackCustomDmpEvent, organisationName }) => (
    <ServerRoutingAnchorLink
        href={daWidget.link}
        type="button"
        testId="ad-detail-widget-button"
        target="_blank"
        rel="nofollow noopener"
        onClick={() =>
            trackCustomDmpEvent('UserAction', {
                eventName: 'TapWidget',
                advertiser: organisationName,
            })
        }
        onAuxClick={() =>
            trackCustomDmpEvent('UserAction', {
                eventName: 'TapWidget',
                advertiser: organisationName,
            })
        }
        css={css`
            color: ${daWidget.buttonTextColor};
            border-color: ${daWidget.buttonOutlineColor};
            background-color: ${daWidget.buttonBackgroundColor};
            &:not(:disabled):not([disabled]):hover {
                background-color: ${daWidget.buttonBackgroundColor};
                border-color: ${daWidget.buttonOutlineColor};
            }
        `}
    >
        {daWidget.buttonText}
    </ServerRoutingAnchorLink>
)

const WidgetInput: FunctionComponent<{
    link: string
    buttonText: string
    trackCustomDmpEvent: TrackCustomDmpEventFunction
    organisationName: string
    price: string
    buttonTextColor: string
    buttonBackgroundColor: string
    buttonOutlineColor: string
}> = ({ link, buttonText, trackCustomDmpEvent, organisationName, price, buttonTextColor, buttonBackgroundColor, buttonOutlineColor }) => (
    <Formik initialValues={{ price, link, buttonText }} validate={widgetFormValidationErrorsFromValues} onSubmit={sendForm}>
        {(props) => {
            const formSpecificFieldProps = {
                formProps: props,
                fieldLabelMap: { price: '' },
                fieldRequiredness: (_values: WidgetFormValues) => {
                    return { price: true }
                },
            }
            return (
                <form method="post" onSubmit={props.handleSubmit} noValidate={true} data-testid="ad-detail-widget-form">
                    <Box display="flex">
                        <Box marginRight="s" flexGrow={1}>
                            <FormikInput field="price" {...formSpecificFieldProps} type="text" placeholder="Kreditbetrag" size="medium" />
                        </Box>

                        <Box>
                            <DisabledOnSSRButton
                                name="send"
                                testId="ad-detail-widget-form-submit"
                                type="submit"
                                size="medium"
                                disabled={props.isSubmitting}
                                css={css`
                                    color: ${buttonTextColor};
                                    border-color: ${buttonOutlineColor};
                                    background-color: ${buttonBackgroundColor};

                                    &:not(:disabled):not([disabled]):hover {
                                        background-color: ${buttonBackgroundColor};
                                        border-color: ${buttonOutlineColor};
                                    }
                                `}
                                onClick={() =>
                                    trackCustomDmpEvent('UserAction', {
                                        eventName: 'TapWidget',
                                        advertiser: organisationName,
                                    })
                                }
                                onAuxClick={() =>
                                    trackCustomDmpEvent('UserAction', {
                                        eventName: 'TapWidget',
                                        advertiser: organisationName,
                                    })
                                }
                            >
                                {buttonText}
                            </DisabledOnSSRButton>
                        </Box>
                    </Box>
                </form>
            )
        }}
    </Formik>
)

const widgetFormValidationErrorsFromValues = (values: WidgetFormValues): { price?: string } => {
    return removeUndefined({
        price: formatPriceBeforeSend(values.price) > 0 ? undefined : 'Ungültige Preisangabe',
    })
}

const sendForm = (formValues: WidgetFormValues, formikHelpers: FormikHelpers<WidgetFormValues>) => {
    formikHelpers.setSubmitting(true)
    window.open(insertPriceIntoUrl(formValues.link, formatPriceBeforeSend(formValues.price)), '_blank')
    formikHelpers.setSubmitting(false)
}

const insertPriceIntoUrl = (url: string, price: number): string => {
    return url.replace(/price%3D[0-9]*/, `price%3D${price}`)
}

const formatPriceBeforeSend = (price: string): number => {
    return parseInt(price.replace('\u20AC', '').replace('.', ''), 10)
}

interface CountPixelProps {
    countPixel: string | null
}

const CountPixel: FunctionComponent<CountPixelProps> = ({ countPixel }) => {
    if (!countPixel) {
        return null
    }

    const sanitizedPixel = filterXSS(countPixel, {
        whiteList: {
            img: ['src', 'width', 'height', 'border', 'alt'],
        },
    })

    return <div dangerouslySetInnerHTML={{ __html: sanitizedPixel }} />
}

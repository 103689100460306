import React, { FunctionComponent, ReactNode, RefObject, useCallback, useState } from 'react'
import { useIntersectionObserver } from '@wh/common/chapter/hooks/useIntersectionObserver'
import { Box } from '@wh-components/core/Box/Box'
import { rgba } from 'polished'
import { StickyHeader } from '@bbx/search-journey/common/components/StickyHeader/StickyHeader'
import { Heading } from '@wh-components/core/Heading/Heading'

interface AdDetailStickyHeaderProps {
    stickyHeaderVisibilityTrackingRef: RefObject<HTMLDivElement>
    teaserAttributes?: ReactNode
    priceBox?: ReactNode
    contactBoxes: ReactNode
    headingText: string
}

export const AdDetailStickyHeader: FunctionComponent<AdDetailStickyHeaderProps> = ({
    stickyHeaderVisibilityTrackingRef,
    teaserAttributes,
    priceBox,
    contactBoxes,
    headingText,
}) => {
    const [showStickyHeader, setShowStickyHeader] = useState<boolean>(false)

    const stickyHeaderVisibilityThreshold = 0.16

    const stickyHeaderVisibilityCallback = useCallback(
        ({ boundingClientRect, rootBounds, intersectionRatio }: IntersectionObserverEntry) => {
            // INFO this would not work the rare case where the viewport scrolls without animation from stickyHeaderVisibilityTrackingRef being invisible below the viewport to being invisible above the viewport
            // since we do not have this case here on ad detail, this solution is good enough here
            const isAbove = rootBounds ? boundingClientRect.top < rootBounds.top : false

            if (isAbove && intersectionRatio < stickyHeaderVisibilityThreshold) {
                if (!showStickyHeader) {
                    setShowStickyHeader(true)
                }
            } else {
                if (showStickyHeader) {
                    setShowStickyHeader(false)
                }
            }
        },
        [showStickyHeader, stickyHeaderVisibilityThreshold],
    )

    useIntersectionObserver(
        stickyHeaderVisibilityTrackingRef,
        { triggerOnce: false, threshold: stickyHeaderVisibilityThreshold },
        [],
        stickyHeaderVisibilityCallback,
    )

    return (
        <StickyHeader testId="sticky-ad-header" showStickyHeader={showStickyHeader}>
            <Box
                display={{ phone: 'none', tablet: 'block' }}
                padding="m"
                backgroundColor="palette.white"
                borderBottom="owl"
                boxShadow={`0 4px 8px -4px ${rgba('black', 0.3)}`}
            >
                <Box marginBottom={{ phone: 's', tablet: 'xs' }} display="flex" justifyContent="space-between">
                    <Heading
                        truncate={true}
                        text={headingText}
                        level={2}
                        fontSize="xl"
                        fontWeight="normal"
                        testId="ad-detail-header-sticky"
                    />
                    <Box display="flex" marginLeft="m" flexShrink={0} alignItems="flex-start">
                        {contactBoxes}
                    </Box>
                </Box>

                <Box display="flex" justifyContent="space-between">
                    <Box minWidth={0} flex="1 1 100%">
                        {teaserAttributes}
                    </Box>
                    <Box display="flex" alignItems="baseline" justifyContent="flex-end" flexShrink={0} marginLeft="m">
                        {priceBox}
                    </Box>
                </Box>
            </Box>
        </StickyHeader>
    )
}

import { AttributeGroup, FormattedAttributesList } from '@wh/common/chapter/types/Attributes'
import React, { FunctionComponent } from 'react'
import { inverseAdTypeIdMap } from '@wh/common/chapter/types/AdType'
import {
    groupAttributesCar,
    groupAttributesCaravan,
    groupAttributesMotorcycle,
    groupAttributesTruck,
} from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/auto-motor/AttributesBox/attribute-group-definitions'
import { AttributesBox } from '@bbx/search-journey/sub-domains/ad-detail/components/common/AttributesBox/AttributesBox'

interface AutoMotorAttributesBoxProps {
    formattedAttributes: FormattedAttributesList
    adTypeId: number
}

export const AutoMotorAttributesBox: FunctionComponent<AutoMotorAttributesBoxProps> = ({ formattedAttributes, adTypeId }) => {
    let groupedAttributes: AttributeGroup[]

    switch (inverseAdTypeIdMap[adTypeId]) {
        case 'MotorCar':
            groupedAttributes = groupAttributesCar(formattedAttributes.attribute)
            break
        case 'MotorMc':
            groupedAttributes = groupAttributesMotorcycle(formattedAttributes.attribute)
            break
        case 'MotorVanTruck':
            groupedAttributes = groupAttributesTruck(formattedAttributes.attribute)
            break
        case 'MotorCaravan':
            groupedAttributes = groupAttributesCaravan(formattedAttributes.attribute)
            break
        default:
            groupedAttributes = groupAttributesCar(formattedAttributes.attribute)
            break
    }

    return <AttributesBox groupedAttributes={groupedAttributes} title="Fahrzeugdaten" marginBottom={{ phone: 'm', tablet: 'l' }} />
}

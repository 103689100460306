import React, { FunctionComponent, ReactNode, RefObject } from 'react'
import { Card } from '@wh-components/core/Card/Card'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { Heading } from '@wh-components/core/Heading/Heading'
import CheckmarkCircleIcon from '@wh-components/icons/CheckCircle'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'

interface SecurityHintsPrivateProps {
    hints: ReactNode[]
    heading: string
    href?: string
    securityHintsRef?: RefObject<HTMLAnchorElement>
}

export const SecurityHintsPrivate: FunctionComponent<SecurityHintsPrivateProps> = ({
    hints,
    heading,
    href = '/iad/sicherheitshinweise/kaufen',
    securityHintsRef,
}) => {
    return (
        <ClientRoutingAnchorLink
            type="anchor"
            href={href}
            color="palette.verydarkgrey"
            aria-label="Zu den Sicherheitshinweisen"
            underline="none"
            ref={securityHintsRef}
        >
            <Card testId="ad-detail-hint-private">
                <Heading color="palette.primary.main" text={heading} testId="securityTippsHeader" level={2} fontSize="m" />

                {hints.map((hint, index) => {
                    return (
                        <Box key={index} marginTop="s" display="flex" alignItems="flex-start">
                            <CheckmarkCircleIcon size="xsmall" color="palette.primary.main" marginRight="xs" />
                            <Text as="p" fontSize="s">
                                {hint}
                            </Text>
                        </Box>
                    )
                })}
            </Card>
        </ClientRoutingAnchorLink>
    )
}

import React, { Fragment, FunctionComponent, useState } from 'react'
import { css } from 'styled-components'
import { Card } from '@wh-components/core/Card/Card'
import { AttributesItem, getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { DefectsLiabilityModal, WarrantyModal } from './WarrantyBoxModals'
import { Box } from '@wh-components/core/Box/Box'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { Heading } from '@wh-components/core/Heading/Heading'
import { Text } from '@wh-components/core/Text/Text'
import ArrowTinyRightIcon from '@wh-components/icons/ArrowtinyRight'
import DealerWarrantyIcon from '@wh-components/icons/DealerWarranty'
import GuaranteeIcon from '@wh-components/icons/Guarantee'
import { ButtonWithLinkStyle } from '@wh-components/core/Button/ButtonWithLinkStyle'
import { TaggingData } from '@wh/common/chapter/types/taggingData'

interface WarrantyBoxProps {
    attributes: AttributesItem[]
    taggingData: TaggingData
}

export const WarrantyBox: FunctionComponent<WarrantyBoxProps> = ({ attributes, taggingData }) => {
    const warrantyDuration = getAttributeValue(attributes, 'WARRANTY_DURATION')
    const hasWarranty = getAttributeValue(attributes, 'WARRANTY') === 'Ja'
    const warrantyLogo = getAttributeValue(attributes, 'WARRANTY_LOGO')
    const warrantyLink = getAttributeValue(attributes, 'WARRANTY_LINK')
    const hasDefectsLiability = getAttributeValue(attributes, 'DEFECTS_LIABILITY')

    const [warrantyModalVisible, setWarrantyModalVisible] = useState(false)
    const [guaranteeModalVisible, setGuaranteeModalVisible] = useState(false)

    return hasDefectsLiability || hasWarranty ? (
        <Fragment>
            {hasWarranty && <WarrantyModal isOpen={guaranteeModalVisible} onRequestClose={() => setGuaranteeModalVisible(false)} />}
            {hasDefectsLiability && (
                <DefectsLiabilityModal isOpen={warrantyModalVisible} onRequestClose={() => setWarrantyModalVisible(false)} />
            )}

            <Card testId="ad-detail-warranty-box">
                <Box marginBottom="xs">
                    <Heading text="Vorteile zu diesem Fahrzeug" level={2} fontSize="m" />
                </Box>

                {hasDefectsLiability && (
                    <Box marginTop="xs">
                        <Box display="flex" alignItems="center">
                            <DealerWarrantyIcon size="xsmall" color="palette.primary.main" />
                            <ButtonWithLinkStyle
                                onClick={() => {
                                    setWarrantyModalVisible(true)
                                    callActionEvent('addetail_defects_liability_click', taggingData)
                                }}
                                fontSize="m"
                                fontWeight="bold"
                            >
                                Gewährleistung
                            </ButtonWithLinkStyle>
                            <ArrowTinyRightIcon size="xxsmall" color="palette.primary.main" marginLeft="xs" />
                        </Box>
                    </Box>
                )}

                {hasWarranty && (
                    <Box display="flex" justifyContent="space-between" marginTop="xs">
                        <Box
                            display="flex"
                            justifyContent="flex-start"
                            flexDirection="column"
                            css={css`
                                overflow: hidden;
                            `}
                        >
                            <Box display="flex" alignItems="center">
                                <GuaranteeIcon size="xsmall" color="palette.primary.main" />
                                <ButtonWithLinkStyle
                                    onClick={() => {
                                        setGuaranteeModalVisible(true)
                                        callActionEvent('addetail_warranty_click', taggingData)
                                    }}
                                    fontSize="m"
                                    fontWeight="bold"
                                >
                                    Garantie
                                </ButtonWithLinkStyle>
                                <ArrowTinyRightIcon size="xxsmall" color="palette.primary.main" marginLeft="xs" />
                            </Box>

                            {warrantyDuration && (
                                <Box marginLeft="m">
                                    <Text as="p" fontSize="s" breakWord={true}>
                                        {warrantyDuration}
                                    </Text>
                                </Box>
                            )}
                        </Box>

                        {warrantyLogo && (
                            <Box marginLeft="s" marginTop="xs">
                                <ServerRoutingAnchorLink
                                    type="anchor"
                                    href={warrantyLink}
                                    target="_blank"
                                    rel="nofollow noopener"
                                    testId="warrant-logo-link"
                                    onClick={() => callActionEvent('addetail_warranty_logo_click', taggingData)}
                                >
                                    <img
                                        src={warrantyLogo}
                                        alt="Garantie Logo"
                                        data-testid="warrantyLogo"
                                        css={css`
                                            display: block;
                                            max-height: 70px;
                                            max-width: 130px;
                                        `}
                                    />
                                </ServerRoutingAnchorLink>
                            </Box>
                        )}
                    </Box>
                )}
            </Card>
        </Fragment>
    ) : null
}

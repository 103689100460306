import { Box } from '@wh-components/core/Box/Box'
import { Text, TextProps } from '@wh-components/core/Text/Text'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import React, { FunctionComponent } from 'react'

type Props = {
    icon: string
    text: string
    url: string
}

export const BadgeText: FunctionComponent<Props & TextProps> = ({ icon, text, url, ...props }) => {
    return (
        <ClientRoutingAnchorLink
            type="anchor"
            href={url}
            display="inline-flex"
            alignItems="center"
            target="_blank"
            gap="xs"
            marginBottom={0}
            color="palette.verydarkgrey"
            {...props}
        >
            <Box height="16px">
                <img src={icon} alt="" aria-hidden={true} style={{ width: '16px', height: '16px', objectFit: 'cover' }} />
            </Box>
            <Box>
                <Text fontSize="s">{text}</Text>
            </Box>
        </ClientRoutingAnchorLink>
    )
}

import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import React, { FunctionComponent } from 'react'
import { getAttributeValue } from '@wh/common/chapter/types/Attributes'
import dayjs from 'dayjs'
import { Vehicle, WithContext } from 'schema-dts'
import { captureException } from '@wh/common/chapter/components/Sentry/sentry'
import {
    getAutoBrand,
    getAutoModel,
    getItemCondition,
} from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/auto-motor/JsonLD/Helpers/autoHelper'
import { getSeller } from '@bbx/search-journey/common/components/JsonLd/Helpers/personHelper'
import { getPlace } from '@bbx/search-journey/common/components/JsonLd/Helpers/placeHelper'
import { inverseAdTypeIdMap } from '@wh/common/chapter/types/AdType'
import { JsonLdScriptElement } from '@wh/common/chapter/components/JsonLd/JsonLdScriptElement'

interface AutoJsonLdProps {
    advertDetails: AdvertDetails | undefined
}

export const AutoJsonLd: FunctionComponent<AutoJsonLdProps> = ({ advertDetails }) => {
    try {
        if (advertDetails) {
            const advertPrice = getAttributeValue(advertDetails.attributes.attribute, 'PRICE')
            const itemCondition = getItemCondition(advertDetails.attributes.attribute)
            const priceValidUntil = dayjs(advertDetails.endDate).format('YYYY-MM-DD')
            const place = getPlace(advertDetails)
            const seller = getSeller(advertDetails)
            const brand = getAutoBrand(advertDetails.adTypeId, advertDetails.attributes.attribute)
            const model = getAutoModel(advertDetails.adTypeId, advertDetails.attributes.attribute)

            let productJsonLd: WithContext<Vehicle>

            switch (inverseAdTypeIdMap[advertDetails.adTypeId]) {
                case 'MotorCar':
                    productJsonLd = {
                        '@context': 'https://schema.org',
                        '@type': 'Car',
                        name: advertDetails.description,
                        description: advertDetails.seoMetaData.description,
                        sku: advertDetails.id,
                        image: advertDetails.advertImageList.advertImage[0]
                            ? advertDetails.advertImageList.advertImage[0].referenceImageUrl
                            : undefined,
                        url: advertDetails.seoMetaData.canonicalUrl,
                        offers: {
                            '@type': 'Offer',
                            price: advertPrice,
                            priceCurrency: 'EUR',
                            priceValidUntil: priceValidUntil,
                            availability: 'InStock',
                            itemCondition: itemCondition,
                            url: advertDetails.seoMetaData.canonicalUrl,
                            availableAtOrFrom: place,
                            seller: seller,
                        },
                        brand: {
                            '@type': 'Brand',
                            name: brand,
                        },
                        model: model,
                    }
                    break
                case 'MotorMc':
                    productJsonLd = {
                        '@context': 'https://schema.org',
                        '@type': 'Motorcycle',
                        name: advertDetails.description,
                        description: advertDetails.seoMetaData.description,
                        sku: advertDetails.id,
                        image: advertDetails.advertImageList.advertImage[0]
                            ? advertDetails.advertImageList.advertImage[0].referenceImageUrl
                            : undefined,
                        url: advertDetails.seoMetaData.canonicalUrl,
                        offers: {
                            '@type': 'Offer',
                            price: advertPrice,
                            priceCurrency: 'EUR',
                            priceValidUntil: priceValidUntil,
                            availability: 'InStock',
                            itemCondition: itemCondition,
                            url: advertDetails.seoMetaData.canonicalUrl,
                            availableAtOrFrom: place,
                            seller: seller,
                        },
                        brand: {
                            '@type': 'Brand',
                            name: brand,
                        },
                        model: model,
                    }
                    break
                case 'MotorVanTruck':
                case 'MotorCaravan':
                default:
                    productJsonLd = {
                        '@context': 'https://schema.org',
                        '@type': 'Vehicle',
                        name: advertDetails.description,
                        description: advertDetails.seoMetaData.description,
                        sku: advertDetails.id,
                        image: advertDetails.advertImageList.advertImage[0]
                            ? advertDetails.advertImageList.advertImage[0].referenceImageUrl
                            : undefined,
                        url: advertDetails.seoMetaData.canonicalUrl,
                        offers: {
                            '@type': 'Offer',
                            price: advertPrice,
                            priceCurrency: 'EUR',
                            priceValidUntil: priceValidUntil,
                            availability: 'InStock',
                            itemCondition: itemCondition,
                            url: advertDetails.seoMetaData.canonicalUrl,
                            availableAtOrFrom: place,
                            seller: seller,
                        },
                        brand: {
                            '@type': 'Brand',
                            name: brand,
                        },
                        model: model,
                    }
                    break
            }

            return <JsonLdScriptElement key="jsonld-car" jsonLd={productJsonLd} />
        }
        return null
    } catch (error) {
        captureException(error)
        return null
    }
}

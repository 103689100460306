import React, { FunctionComponent } from 'react'
import { css } from 'styled-components'
import dayjs from 'dayjs'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import {
    DayOfWeekGerman,
    DayOfWeekNumber,
    dealerIsOpen,
    inverseDayOfWeekNumber,
    OpeningHoursForDay,
    OpeningHoursGroup,
    OrganisationOpeningHours,
} from '@bbx/common/types/ad-detail/OrganisationDetails'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import ArrowUpIcon from '@wh-components/icons/ArrowUp'
import ArrowDownIcon from '@wh-components/icons/ArrowDown'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { OpeningHour } from '@bbx/common/components/OpeningHour'

interface OpeningHoursBoxProps {
    openingHours: OrganisationOpeningHours
    group?: OpeningHoursGroup
    collapsed: boolean
    setCollapsed: (collapsed: boolean) => void
    currentTime?: number
    taggingData: TaggingData
}

export const OpeningHoursBox: FunctionComponent<OpeningHoursBoxProps> = ({
    openingHours,
    collapsed,
    setCollapsed,
    group = 'SHOP',
    currentTime = Date.now(),
    taggingData,
}) => {
    const currentDay = dayjs(currentTime).day()
    const currentlyOpen = dealerIsOpen(openingHours, group, currentTime)

    const openingHoursByGroup = openingHours.openingHoursByGroup.find((superGroup) => superGroup.openingHoursGroup === group)

    if (!openingHoursByGroup) {
        return null
    }

    const openingHoursCurrentDay = openingHoursByGroup.openingHoursForDays.find(
        (day) => day.dayOfWeek === inverseDayOfWeekNumber[currentDay],
    )
    const openingHoursToday = openingHoursCurrentDay?.openingHours

    return (
        <Box id="ad-detail-opening-times-container" padding="m" borderRadius={4} border="1px solid" borderColor="palette.owl">
            <Box
                display="flex"
                justifyContent="space-between"
                testId="openingHoursCollapseButton"
                aria-label="Öffnungszeiten"
                role="button"
                aria-expanded={!collapsed}
                onClick={() => {
                    setCollapsed(!collapsed)
                    callActionEvent('addetail_show_opening_hours_click', taggingData)
                }}
                css={css`
                    ${(p) => p.theme.media.tablet} {
                        pointer-events: none;
                    }
                `}
            >
                <Box marginRight={{ phone: 's', tablet: '0' }}>
                    <Text fontWeight="bold" color={currentlyOpen ? 'adStatus.active' : 'palette.elephant'}>
                        {currentlyOpen ? 'Jetzt geöffnet' : 'Öffnungszeiten'}
                    </Text>
                </Box>
                <Box display={{ phone: 'flex', tablet: 'none' }} alignItems="center">
                    {collapsed &&
                        currentlyOpen &&
                        openingHoursToday?.map((hours, hourIndex) => <OpeningHour key={hourIndex} hourIndex={hourIndex} hours={hours} />)}
                    {collapsed ? <ArrowDownIcon size="xsmall" marginLeft="s" /> : <ArrowUpIcon size="xsmall" />}
                </Box>
            </Box>

            <Box marginTop="s" display={{ phone: collapsed ? 'none' : 'block', tablet: 'block' }}>
                {openingHoursByGroup.openingHoursForDays.map((day, dayIndex) => (
                    <OpeningHourLine key={dayIndex} day={day} bold={currentlyOpen && currentDay === DayOfWeekNumber[day.dayOfWeek]} />
                ))}
            </Box>
        </Box>
    )
}

const OpeningHourLine: FunctionComponent<{ day: OpeningHoursForDay; bold: boolean }> = ({ day, bold }) => {
    return (
        <Text as="div" aria-label={`Öffnungszeiten: ${DayOfWeekGerman[day.dayOfWeek]}`} fontWeight={bold ? 'bold' : undefined}>
            <Box display="inline-block" width={42} marginRight="s">
                <Text>{DayOfWeekGerman[day.dayOfWeek].substr(0, 2)}</Text>
            </Box>
            {day.openingHours.map((hours, hourIndex) => (
                <OpeningHour key={hourIndex} hourIndex={hourIndex} hours={hours} />
            ))}
        </Text>
    )
}

import React, { FunctionComponent, SyntheticEvent, useState, Fragment } from 'react'
import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import {
    adContactFormMessagingFieldRequiredness,
    adContactFormMessagingFieldToLabelMap,
    SendMessageRequestFormData,
    validateAdContactFormMessaging,
} from './adContactFormMessaging.types'
import { UserProfileData } from '@wh/common/chapter/types/user'
import { ContentClickBlocker } from '@wh/common/chapter/components/ContentClickBlocker/ContentClickBlocker'
import { FormikInput, FormikTextarea } from '@wh/common/chapter/components/Formik/FormikInputs'
import { FormikHelpers, useFormik } from 'formik'
import { DisabledOnSSRButton } from '@wh/common/chapter/components/DisabledOnSSRButton'
import { Desktop5050Container } from '@wh/common/chapter/components/Layouts/rows/Desktop5050Container'
import MessageIcon from '@wh-components/icons/Message'
import { AdBoxHeading } from '@bbx/common/components/AdBoxHeading/AdBoxHeading'
import { firstNameValidation, lastNameValidation } from '@bbx/common/lib/validation'
import { ButtonVariantType } from '@wh-components/core/Button/Button'
import { Box } from '@wh-components/core/Box/Box'
import { AdContactFormShareProfile } from '@bbx/lead-journey/lead-journey/components/AdContactForm/real-estate/AdContactFormShareProfile'
import { isRentalAd } from '@wh/common/rental/lib/rentalUtils'
import type { TenantProfileDTOType } from '@wh/common/rental/types'

interface AdContactFormInnerMessagingProps {
    heading?: string
    advertDetails: AdvertDetails
    profileData: UserProfileData | undefined
    onClickedOnMessagingFormWhileNotLoggedIn: (event: SyntheticEvent) => void
    onSendMessage: (data: SendMessageRequestFormData, onRetrySendForm: () => void) => void
    buttonVariant?: ButtonVariantType
}

export const AdContactFormInnerMessaging: FunctionComponent<AdContactFormInnerMessagingProps> = (props) => {
    const adIsRentalAd = isRentalAd(props.advertDetails.adTypeId)
    const [tenantProfile, setTenantProfile] = useState<TenantProfileDTOType>()
    const [currentShareTenantProfile, setCurrentShareTenantProfile] = useState(false)
    const formProps = useFormik({
        initialValues: {
            mailContent: adIsRentalAd ? tenantProfile?.inquiryText ?? '' : '',
            firstName: props.profileData?.firstName?.trim() ?? '',
            lastName: props.profileData?.lastName?.trim() ?? '',
            shareTenantProfile: false,
        },
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: true,
        validate: validateAdContactFormMessaging,
        onSubmit: async (values, formikHelpers: FormikHelpers<SendMessageRequestFormData>) => {
            props.onSendMessage({ ...values, shareTenantProfile: currentShareTenantProfile }, formikHelpers.submitForm)
            formikHelpers.setSubmitting(false)
        },
    })

    const formSpecificFieldProps = {
        formProps,
        fieldLabelMap: adContactFormMessagingFieldToLabelMap,
        fieldRequiredness: adContactFormMessagingFieldRequiredness,
        disabled: props.profileData?.isAdminUser,
    }
    // the api might return " " (one whitespace) even if the firstName is set to an empty string (without whitespace), so we need to make the same checks as in the form validation to show the input fields correctly and not run into form validation issues
    const showNameInput =
        props.profileData !== undefined &&
        (typeof firstNameValidation(props.profileData.firstName) !== 'undefined' ||
            typeof lastNameValidation(props.profileData.lastName) !== 'undefined')

    // use method post in order to prevent field content to land in the browser url in case js breaks on the page

    return (
        <Fragment>
            {props.heading && <AdBoxHeading text={props.heading} marginBottom="m" />}
            <ContentClickBlocker
                block={typeof props.profileData === 'undefined'}
                onClickOnBlockingDiv={props.onClickedOnMessagingFormWhileNotLoggedIn}
            >
                {/* use method post in order to prevent field content to land in the browser url in case js breaks on the page */}
                <form method="post" onSubmit={formProps.handleSubmit} noValidate={true} data-testid="ad-contact-form-messaging">
                    {showNameInput && (
                        <Box display="flex" gap="m" flexDirection={{ phone: 'column', tablet: 'row' }} marginBottom="m">
                            <FormikInput
                                field="firstName"
                                placeholder="Vorname"
                                commentOverride={formProps.errors.firstName ?? 'Dieser Name wird in dein Profil übernommen'}
                                {...formSpecificFieldProps}
                                aria-label="Vorname"
                                flex="1 1 50%"
                            />
                            <FormikInput
                                field="lastName"
                                placeholder="Nachname"
                                {...formSpecificFieldProps}
                                aria-label="Nachname"
                                flex="1 1 50%"
                            />
                        </Box>
                    )}

                    <FormikTextarea
                        field="mailContent"
                        rows={5}
                        maxLength={5000}
                        placeholder="Deine Nachricht"
                        {...formSpecificFieldProps}
                        aria-label="Nachrichteninhalt"
                        marginBottom="m"
                    />

                    {adIsRentalAd && !props.profileData?.isAdminUser && (
                        <Box marginBottom="m">
                            <AdContactFormShareProfile
                                shareTenantProfile={currentShareTenantProfile}
                                setCurrentShareTenantProfile={setCurrentShareTenantProfile}
                                tenantUuid={props.profileData?.uuid}
                                tenantProfile={tenantProfile}
                                setTenantProfile={setTenantProfile}
                            />
                        </Box>
                    )}

                    <Desktop5050Container>
                        <DisabledOnSSRButton
                            disabled={formProps.isSubmitting || props.profileData?.isAdminUser}
                            name="send"
                            type="submit"
                            testId="ad-request-send-message"
                            size="large"
                            width="100%"
                            Icon={MessageIcon}
                            variant={props.buttonVariant}
                        >
                            Nachricht absenden
                        </DisabledOnSSRButton>
                    </Desktop5050Container>
                </form>
            </ContentClickBlocker>
        </Fragment>
    )
}

/* eslint-disable max-lines */
import { AdBoxHeading } from '@bbx/common/components/AdBoxHeading/AdBoxHeading'
import { useAdDetailDigitalAdvertisingMotor } from '@bbx/common/digital-advertising/hooks/useAdDetailDigitalAdvertising'
import { SellerType } from '@bbx/common/lib/getSellerType'
import { AdvertDetails, AdvertDetailsWithFormattedAttributes, isContactPossible } from '@bbx/common/types/ad-detail/AdvertDetails'
import { SimilarAdsContextLink, findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { AdContactFormMotor } from '@bbx/lead-journey/lead-journey/components/AdContactForm/auto-motor/AdContactFormMotor'
import { SeoSearchTerms } from '@bbx/search-journey/common/SeoSearchTerms'
import { Breadcrumbs } from '@bbx/search-journey/common/components/Breadcrumbs/Breadcrumbs'
import { ImageGallery } from '@bbx/search-journey/common/components/ImageGallery/ImageGallery'
import { ImageGalleryData } from '@bbx/search-journey/common/components/ImageGallery/ImageGallery.settings'
import { SaveAdModal, SavedAdsUiState } from '@bbx/search-journey/common/components/SaveAd/SaveAdModal'
import { SearchTermsLinkVomit } from '@bbx/search-journey/common/components/SearchTermsLinkVomit/SearchTermsLinkVomit'
import { useGeoData } from '@bbx/search-journey/common/lib/useGeoData'
import { useLastViewedAds } from '@bbx/search-journey/common/lib/useLastViewedAds'
import { useLoadLastViewedAdsWidget } from '@bbx/search-journey/common/lib/useLoadLastViewedAdsWidget'
import { AdHeader } from '@bbx/search-journey/sub-domains/ad-detail/components/common/AdHeader/AdHeader'
import { PhoneNumberContext } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ContactBox/PhoneNumberContext'
import { DaPromotionWidget } from '@bbx/search-journey/sub-domains/ad-detail/components/common/DaWidget/DaWidget'
import { CollapsibleDescription } from '@bbx/search-journey/sub-domains/ad-detail/components/common/Description/Description'
import { AdDetailLastViewedAdsWidget } from '@bbx/search-journey/sub-domains/ad-detail/components/common/LastViewedAdsWidgetWrapper/AdDetailLastViewedAdsWidget'
import { AdDetailMap } from '@bbx/search-journey/sub-domains/ad-detail/components/common/Map/AdDetailMap'
import { PreviousBackNext } from '@bbx/search-journey/sub-domains/ad-detail/components/common/PreviousBackNext/PreviousBackNext'
import {
    ReportAdBox,
    ReportAdButton,
    WhCodeAndLastChangedDate,
} from '@bbx/search-journey/sub-domains/ad-detail/components/common/ReportAdBox/ReportAdBox'
import { AdDetailSavedAdButtonContext } from '@bbx/search-journey/sub-domains/ad-detail/components/common/SaveAdButton/AdDetailSavedAdButtonContext'
import { SecurityHintsDealer } from '@bbx/search-journey/sub-domains/ad-detail/components/common/SecurityHints/SecurityHintsDealer'
import { ServiceBox } from '@bbx/search-journey/sub-domains/ad-detail/components/common/ServiceBox/ServiceBox'
import { AutoMotorAttributesBox } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/auto-motor/AttributesBox/AutoMotorAttributesBox'
import { Equipments } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/auto-motor/Equipments/Equipments'
import { OpeningHoursBox } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/auto-motor/OpeningHoursBox/OpeningHoursBox'
import { AutoMotorSecurityHintsPrivate } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/auto-motor/SecurityHints/AutoMotorSecurityHintsPrivate'
import { AutoMotorSimilarAds } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/auto-motor/SimilarAds/AutoMotorSimilarAds'
import { WarrantyBox } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/auto-motor/WarrantyBox/WarrantyBox'
import { Box } from '@wh-components/core/Box/Box'
import { Divider } from '@wh-components/core/Divider/Divider'
import { DmpStateProvider } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'
import { DynamicRendering } from '@wh/common/chapter/components/DynamicRendering/DynamicRendering'
import { FullWidthRow } from '@wh/common/chapter/components/Layouts/rows/FullWidthRow'
import { TRow } from '@wh/common/chapter/components/Layouts/rows/TRow'
import { TwoColumnRow } from '@wh/common/chapter/components/Layouts/rows/TwoColumnRow'
import { printDisplayBlockFirefoxWorkaroundCss } from '@wh/common/chapter/components/Printing/printDisplayBlockFirefoxWorkaroundCss'
import { adStatusFlag } from '@wh/common/chapter/lib/advertStatusColorHelper'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { inverseAdTypeIdMap } from '@wh/common/chapter/types/AdType'
import { AdvertImage } from '@wh/common/chapter/types/AdvertImage'
import { getAttribute, getAttributeValue, getFormattedAttributeValue } from '@wh/common/chapter/types/Attributes'
import { UserProfileData } from '@wh/common/chapter/types/user'
import { inverseVerticalIdMap, verticalIdMap } from '@wh/common/chapter/types/verticals'
import { AdDetailLargeRenderSlot } from '@wh/common/digital-advertising/components/AdDetailLargeRenderSlot/AdDetailLargeRenderSlot'
import { AdDetailMediumRenderSlot } from '@wh/common/digital-advertising/components/AdDetailMediumRenderSlot/AdDetailMediumRenderSlot'
import { AdDetailSmallRenderSlot } from '@wh/common/digital-advertising/components/AdDetailSmallRenderSlot/AdDetailSmallRenderSlot'
import { TeaserAttributes } from '@wh/common/chapter/components/TeaserAttributes'
import React, { Fragment, FunctionComponent, RefObject, useRef, useState } from 'react'
import { css } from 'styled-components'
import { BumpedBadgeText } from '../../common/BumpedBadgeText/BumpedBadgeText'
import { LegalDisclaimerInfoBox } from '../../common/LegalDisclaimerInfoBox/LegalDisclaimerInfoBox'
import { Open360Button } from '../../common/Open360Button/Open360Button'
import { CallAndContactAdDetailStickyFooter } from '../../common/StickyFooter/CallAndContactAdDetailStickyFooter'
import { RealEstateAutoAdDetailStickyHeader } from '../../common/StickyHeader/RealEstateAutoAdDetailStickyHeader'
import { LeasingInfoAttributesBox } from './AttributesBox/LeasingInfoAttributesBox'
import { AutoMotorContactBoxDealerBottom } from './ContactBox/AutoMotorContactBoxDealerBottom'
import { AutoMotorContactBoxDealerTop } from './ContactBox/AutoMotorContactBoxDealerTop'
import { AutoMotorContactBoxPrivateBottom } from './ContactBox/AutoMotorContactBoxPrivateBottom'
import { AutoMotorContactBoxPrivateTop } from './ContactBox/AutoMotorContactBoxPrivateTop'
import { OpeningHoursContext } from './OpeningHoursBox/OpeningHoursContext'
import { getSellerType } from '@bbx/common/lib/getSellerType'
import { MoreInfoLinksBox } from '@bbx/search-journey/sub-domains/ad-detail/components/common/MoreInfoLinksBox/MoreInfoLinksBox'

interface AutoMotorAdDetailContainerProps {
    advertDetails: AdvertDetailsWithFormattedAttributes
    profileData: UserProfileData | undefined
    sellerType: SellerType
    seoSearchTerms: SeoSearchTerms[]
}

export const AutoMotorAdDetailContainer: FunctionComponent<AutoMotorAdDetailContainerProps> = ({
    advertDetails,
    sellerType,
    profileData,
    seoSearchTerms,
}) => {
    const [savedInFolder, setSavedInFolder] = useState(advertDetails.savedInFolder)
    const [savedAdsUiState, setSavedAdsUiState] = useState<SavedAdsUiState>({ state: 'idle' })
    const [openingHoursCollapsed, setOpeningHoursCollapsed] = useState(true)
    const [telephoneVisible, setTelephoneVisible] = useState(false)

    const stickyHeaderVisibilityTrackingRef = useRef<HTMLDivElement>(null)
    const adContactVisibilityTrackingRef = useRef<HTMLDivElement>(null)
    const securityHintsRef = useRef<HTMLAnchorElement>(null)
    const geoData = useGeoData(advertDetails)

    const { trackCustomDmpEvent, setAdvertisingStateContacted } = useAdDetailDigitalAdvertisingMotor(advertDetails)

    const imageData: ImageGalleryData[] = getImageData(advertDetails)
    const teaserAttributes = advertDetails.teaserAttributes
    const price = getFormattedAttributeValue(advertDetails.formattedAttributes.attribute, 'PRICE')
    const sold = advertDetails.advertStatus.id === adStatusFlag.SOLD
    const similarAdsContextLink = findContextLinkWithId('similarAdsRecommendationUrl', advertDetails.contextLinkList)

    const lastViewedAdsWidget = useLoadLastViewedAdsWidget(verticalIdMap.MOTOR, Number(advertDetails.id), securityHintsRef)
    useLastViewedAds(Number(advertDetails.id), verticalIdMap.MOTOR)

    return (
        <DmpStateProvider trackCustomDmpEvent={trackCustomDmpEvent}>
            <AdDetailSavedAdButtonContext.Provider value={[savedInFolder, setSavedInFolder]}>
                <OpeningHoursContext.Provider value={[openingHoursCollapsed, setOpeningHoursCollapsed]}>
                    <PhoneNumberContext.Provider value={[telephoneVisible, setTelephoneVisible, setAdvertisingStateContacted]}>
                        <SaveAdModal
                            savedAdsUiState={savedAdsUiState}
                            setSavedAdsUiState={setSavedAdsUiState}
                            onSavedAd={(adId) => {
                                if (adId === advertDetails.id) {
                                    setSavedInFolder(true)
                                }
                            }}
                            loginId={profileData?.loginId}
                            onSavedTaggingEvent={() => callActionEvent('addetail_favorite_ad_saved', advertDetails.taggingData)}
                        />
                        <Box as="article" display="flex" flexDirection="column" css={printDisplayBlockFirefoxWorkaroundCss}>
                            <RealEstateAutoAdDetailStickyHeader
                                advertDetails={advertDetails}
                                teaserAttributes={teaserAttributes}
                                stickyHeaderVisibilityTrackingRef={stickyHeaderVisibilityTrackingRef}
                                price={sold ? 'Verkauft' : price}
                                priceDescription={sold ? price : 'Verkaufspreis'}
                                contactButtonText={
                                    getSellerType(advertDetails.attributes) === SellerType.DEALER ? 'Händler kontaktieren' : 'Nachricht'
                                }
                            />

                            {isContactPossible(advertDetails.advertStatus) && (
                                <CallAndContactAdDetailStickyFooter
                                    contactOption={advertDetails.contactOption}
                                    adId={advertDetails.id}
                                    advertContactDetails={advertDetails.advertContactDetails}
                                    adStatus={advertDetails.advertStatus}
                                    taggingData={advertDetails.taggingData}
                                    stickyFooterVisibilityTrackingRef={adContactVisibilityTrackingRef}
                                />
                            )}

                            <TwoColumnRow
                                order={0}
                                display={{ phone: 'none', tablet: 'flex', print: 'none' }}
                                marginBottom="sm"
                                left={<Breadcrumbs breadcrumbsList={advertDetails.breadcrumbs} />}
                                right={<PreviousBackNext adId={advertDetails.id} taggingData={advertDetails.taggingData} />}
                            />

                            <FullWidthRow order={0} display={{ phone: 'block', tablet: 'none' }} marginBottom="0">
                                <PreviousBackNext adId={advertDetails.id} taggingData={advertDetails.taggingData} />
                            </FullWidthRow>

                            <TRow
                                order={1}
                                internalOrder={{
                                    posTop: { phone: 1, tablet: 0 },
                                    posLeft: { phone: 0, tablet: 1 },
                                    posRight: { phone: 2 },
                                }}
                                top={
                                    <AdHeader
                                        advertDetails={advertDetails}
                                        teaserAttributes={
                                            <TeaserAttributes teaserAttributes={teaserAttributes} testId="ad-detail-teaser-attribute" />
                                        }
                                    />
                                }
                                left={
                                    <Box ref={stickyHeaderVisibilityTrackingRef} height="100%">
                                        <ImageGallery
                                            // setting the key property forces an unmount of the component when the key changes (used for client-side routing)
                                            key={advertDetails.id}
                                            height={{ phone: '75vw', tablet: '466px' }}
                                            vertical={inverseVerticalIdMap[advertDetails.verticalId]}
                                            adType={inverseAdTypeIdMap[advertDetails.adTypeId]}
                                            imageData={imageData}
                                            carouselOverlay={() => (
                                                <Open360Button
                                                    adDescription={advertDetails.description}
                                                    attributes={advertDetails.attributes.attribute}
                                                    adId={advertDetails.id}
                                                    taggingData={advertDetails.taggingData}
                                                    position="absolute"
                                                    right="m"
                                                    bottom="m"
                                                />
                                            )}
                                        />
                                    </Box>
                                }
                                right={
                                    sellerType === 'dealer' ? (
                                        <AutoMotorContactBoxDealerTop advertDetails={advertDetails} height="100%" />
                                    ) : (
                                        <AutoMotorContactBoxPrivateTop advertDetails={advertDetails} height="100%" />
                                    )
                                }
                            />

                            <TwoColumnRow
                                order={2}
                                marginBottom={{ phone: 'm', tablet: 'l' }}
                                left={
                                    <Box paddingHorizontal={{ phone: 'm', tablet: '0' }}>
                                        <AutoMotorAttributesBox
                                            adTypeId={advertDetails.adTypeId}
                                            formattedAttributes={advertDetails.formattedAttributes}
                                        />
                                        <CollapsibleDescription
                                            key={advertDetails.id}
                                            heading="Beschreibung"
                                            description={getAttributeValue(advertDetails.formattedAttributes.attribute, 'DESCRIPTION')}
                                            stripLinks={true}
                                            taggingData={advertDetails.taggingData}
                                            taggingEvent="addetail_expand_description_click"
                                        />
                                    </Box>
                                }
                                right={
                                    <Box
                                        display={{ phone: 'block', print: 'none' }}
                                        css={css`
                                            & > div {
                                                margin-bottom: ${(p) => p.theme.space.m}px;

                                                &:last-of-type {
                                                    margin-bottom: 0;
                                                }
                                            }
                                        `}
                                    >
                                        <AdDetailLargeRenderSlot />
                                        <AdDetailMediumRenderSlot />
                                        <AdDetailSmallRenderSlot />
                                        {advertDetails.daWidget && (
                                            <DaPromotionWidget
                                                price={getAttribute(advertDetails.formattedAttributes.attribute, 'PRICE')}
                                                daWidget={advertDetails.daWidget}
                                            />
                                        )}
                                        <LeasingInfoAttributesBox formattedAttributes={advertDetails.formattedAttributes} />
                                        <WarrantyBox
                                            attributes={advertDetails.formattedAttributes.attribute}
                                            taggingData={advertDetails.taggingData}
                                        />
                                    </Box>
                                }
                            />

                            <FullWidthRow order={3} marginBottom={{ phone: 'm', tablet: 'l' }}>
                                <Equipments adTypeId={advertDetails.adTypeId} attributes={advertDetails.formattedAttributes.attribute} />
                            </FullWidthRow>

                            <FullWidthRow order={4} marginBottom={{ phone: 'm', tablet: 'l' }} display={{ print: 'none', phone: 'block' }}>
                                <ServiceBox
                                    title="Services zu diesem Fahrzeug"
                                    serviceBoxGroups={advertDetails.serviceBoxGroups}
                                    paddingBottom="m"
                                />
                                <MoreInfoLinksBox advertDetails={advertDetails} marginBottom="m" />
                                <LegalDisclaimerInfoBox advertDetails={advertDetails} />
                                <BumpedBadgeText advertDetails={advertDetails} marginTop="m" />
                            </FullWidthRow>

                            {similarAdsContextLink && (
                                <FullWidthRow
                                    order={5}
                                    marginBottom={{ phone: 'm', tablet: 'l' }}
                                    display={{ print: 'none', phone: 'block' }}
                                >
                                    <AutoMotorSimilarAds
                                        similarAdsRecommendationContextLink={similarAdsContextLink as SimilarAdsContextLink}
                                        taggingData={advertDetails.taggingData}
                                    />
                                </FullWidthRow>
                            )}

                            <FullWidthRow order={6} marginBottom={{ tablet: 's' }}>
                                <AdBoxHeading
                                    text={sellerType === 'dealer' ? 'Händlerdetails' : 'Verkäuferdetails'}
                                    paddingHorizontal={{ phone: 'm', tablet: 0 }}
                                />
                            </FullWidthRow>

                            <TwoColumnRow
                                order={7}
                                layout="300px 1fr"
                                left={
                                    <Fragment>
                                        {sellerType === 'dealer' ? (
                                            <AutoMotorContactBoxDealerBottom advertDetails={advertDetails} />
                                        ) : (
                                            <AutoMotorContactBoxPrivateBottom advertDetails={advertDetails} />
                                        )}
                                        <Box paddingHorizontal={{ phone: 'm', tablet: '0' }} marginTop={{ tablet: 's' }}>
                                            <OpeningHoursBox
                                                collapsed={openingHoursCollapsed}
                                                setCollapsed={setOpeningHoursCollapsed}
                                                openingHours={advertDetails.organisationDetails.openingHours}
                                                taggingData={advertDetails.taggingData}
                                            />
                                        </Box>
                                    </Fragment>
                                }
                                right={
                                    <Fragment>
                                        {geoData.type !== 'none' && (
                                            <DynamicRendering<HTMLDivElement>
                                                key="ad-detail-map"
                                                offset="300px"
                                                renderPlaceholder={(ref) => (
                                                    <Box ref={ref} height={200} testId="ad-detail-map-dynamic-placeholder" />
                                                )}
                                            >
                                                <Box marginBottom="m" paddingHorizontal={{ phone: 'm', tablet: 0 }}>
                                                    <AdDetailMap
                                                        height={200}
                                                        center={geoData.coordinates}
                                                        adId={advertDetails.id}
                                                        taggingData={advertDetails.taggingData}
                                                        circle={geoData.type === 'radius' ? geoData.radius : undefined}
                                                        geoJSON={geoData.type === 'geoJson' ? geoData.geoJson : undefined}
                                                    />
                                                </Box>
                                            </DynamicRendering>
                                        )}
                                        <AdContactFormMotor
                                            setAdvertisingStateContacted={setAdvertisingStateContacted}
                                            advertDetails={advertDetails}
                                            profileData={profileData}
                                            adContactVisibilityTrackingRef={adContactVisibilityTrackingRef}
                                        />
                                    </Fragment>
                                }
                            />

                            <FullWidthRow order={8}>
                                <ReportAdBox>
                                    <WhCodeAndLastChangedDate adId={advertDetails.id} changedDate={advertDetails.changedDate} />
                                    <ReportAdButton adId={advertDetails.id} />
                                </ReportAdBox>
                            </FullWidthRow>

                            {lastViewedAdsWidget && lastViewedAdsWidget.ads?.length > 0 && (
                                <FullWidthRow order={9} marginBottom="m" display={{ print: 'none', phone: 'block' }}>
                                    <AdDetailLastViewedAdsWidget
                                        lastViewedAdsSlider={lastViewedAdsWidget}
                                        taggingData={advertDetails.taggingData}
                                    />
                                </FullWidthRow>
                            )}

                            <FullWidthRow order={10} marginBottom={{ phone: 'm', tablet: 'm' }} display={{ print: 'none', phone: 'block' }}>
                                <SellerHints sellerType={sellerType} securityHintsRef={securityHintsRef} />
                            </FullWidthRow>

                            <FullWidthRow order={11} display={{ phone: 'block', tablet: 'none' }}>
                                <Box paddingHorizontal={{ phone: 'm', tablet: '0' }}>
                                    <Breadcrumbs breadcrumbsList={advertDetails.breadcrumbs} hideJsonLd={true} />
                                </Box>
                            </FullWidthRow>

                            {seoSearchTerms.length > 0 && (
                                <FullWidthRow order={12} display={{ print: 'none' }} marginBottom={{ phone: 'm', tablet: 0 }}>
                                    <Divider marginBottom={{ tablet: 'm' }} />
                                    <SearchTermsLinkVomit searchTermsList={seoSearchTerms} alignLinks={{ tablet: 'column' }} />
                                </FullWidthRow>
                            )}
                        </Box>
                    </PhoneNumberContext.Provider>
                </OpeningHoursContext.Provider>
            </AdDetailSavedAdButtonContext.Provider>
        </DmpStateProvider>
    )
}

const getImageData = (advertDetails: AdvertDetails) => {
    return advertDetails.advertImageList.advertImage.map((advertImage: AdvertImage, index) => ({
        alt: `Bild ${index + 1} von ${advertDetails.advertImageList.advertImage.length}${
            advertImage.description ? ` - ${advertImage.description}` : ''
        }`,
        imageUrl: advertImage.referenceImageUrl,
        thumbnailUrl: advertImage.thumbnailImageUrl,
    }))
}

export const SellerHints: FunctionComponent<{ sellerType: SellerType; securityHintsRef?: RefObject<HTMLAnchorElement> }> = ({
    sellerType,
    securityHintsRef,
}) => {
    switch (sellerType) {
        case SellerType.PRIVATE:
            return <AutoMotorSecurityHintsPrivate securityHintsRef={securityHintsRef} />
        case SellerType.COMMERCIAL:
        case SellerType.DEALER:
            return <SecurityHintsDealer securityHintsRef={securityHintsRef} />
    }
}

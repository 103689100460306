import { AutoMotorAdDetailUserZoom } from '@bbx/common/components/UserZoom/UserZoom'
import { formatAdvertDetails } from '@bbx/common/lib/formatAdvertDetails'
import { getSellerType } from '@bbx/common/lib/getSellerType'
import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { fetchFromContextLink } from '@bbx/search-journey/common/api/searchApiClient'
import { SeoSearchTerms } from '@bbx/search-journey/common/SeoSearchTerms'
import { getAdDetails, getAdSimilarAdsOrCategory, getFreshAdDetails } from '@bbx/search-journey/sub-domains/ad-detail/api/addetailApiClient'
import { AdIdNotFoundAlert } from '@bbx/search-journey/sub-domains/ad-detail/components/common/AdIdNotFoundAlert/AdIdNotFoundAlert'
import { AutoMotorAdDetailContainer } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/auto-motor/AutoMotorAdDetailContainer'
import { AutoJsonLd } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/auto-motor/JsonLD/AutoJsonLd'
import { LeaderboardAndSkyscraperLayout } from '@wh/common/chapter/components/Layouts/LeaderboardAndSkyscraperLayout'
import { SeoMetaTagsForSeoMetaData } from '@wh/common/chapter/components/SeoMetaTags/SeoMetaTags'
import { wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import { redirectTo } from '@wh/common/chapter/lib/routing/bbxRouter'
import { callPageView } from '@wh/common/chapter/lib/tagging/tagging'
import { adTypeIdMap } from '@wh/common/chapter/types/AdType'
import { ExpressNextContextWithUserData, OptionalPageOptions } from '@wh/common/chapter/types/nextJS'
import { UserProfileData } from '@wh/common/chapter/types/user'
import { VerticalId, verticalIdMap } from '@wh/common/chapter/types/verticals'
import { Response } from 'express'
import { NextPage } from 'next'
import React, { Fragment, FunctionComponent, useEffect } from 'react'

type ErrorProps =
    | {
          is404: true
      }
    | {
          isNotAvailable: true
      }

type AdDetailProps = {
    advertDetails: AdvertDetails
    profileData: UserProfileData | undefined
    seoSearchTerms: SeoSearchTerms[] | undefined
}

const AutoMotorAdDetail: NextPage<AdDetailProps | ErrorProps> & OptionalPageOptions = (props) => {
    useEffect(() => {
        if ('isNotAvailable' in props || 'is404' in props) {
            return
        }
    }, [props])

    if ('isNotAvailable' in props) {
        return null
    }

    if ('is404' in props) {
        return <AdIdNotFoundAlert />
    }

    const { advertDetails, profileData, seoSearchTerms } = props

    return (
        <Fragment>
            <AdDetailTagger advertDetails={advertDetails} />

            <SeoMetaTagsForSeoMetaData
                seoMetaData={advertDetails.seoMetaData}
                ogSiteImageUrl={advertDetails.advertImageList.advertImage[0]?.referenceImageUrl}
            />
            <AutoMotorAdDetailUserZoom />
            <AutoJsonLd advertDetails={advertDetails} />
            <AutoMotorAdDetailContainer
                advertDetails={formatAdvertDetails(advertDetails)}
                sellerType={getSellerType(advertDetails.attributes)}
                profileData={profileData}
                key={advertDetails.id}
                seoSearchTerms={seoSearchTerms ?? []}
            />
        </Fragment>
    )
}

export const adDetailGetInitialProps =
    (verticalId: VerticalId, isNcPage: boolean = false) =>
    async ({ query, req, res, profileData }: ExpressNextContextWithUserData): Promise<AdDetailProps | ErrorProps> => {
        const adId = adIdFromPathParameters(query.seopath as string[])

        if (!adId) {
            if (res) {
                res.statusCode = 404
            }
            return { is404: true }
        }

        try {
            const isLoggedIn = profileData !== undefined
            const advertDetails = isLoggedIn ? await getFreshAdDetails(adId, req) : await getAdDetails(adId, req)

            const seoBoxContextLink = findContextLinkWithId('seoLinkBoxLink', advertDetails.contextLinkList)
            const seoSearchTerms = seoBoxContextLink ? await fetchFromContextLink<SeoSearchTerms[]>(seoBoxContextLink) : undefined

            if (verticalId !== advertDetails.verticalId || redirectBetweenRealEstateAndNc(advertDetails, isNcPage)) {
                redirectTo(`/iad/object?adId=${adId}`, res, 301)
                return { isNotAvailable: true }
            }

            return { advertDetails, profileData, seoSearchTerms }
        } catch (error) {
            const apiError = wrapInApiErrorIfNecessary(error)

            if (apiError.statusCode && apiError.statusCode >= 400 && apiError.statusCode <= 499) {
                return await redirectToSimilarCategory(adId, res)
            }

            throw error
        }
    }

const redirectBetweenRealEstateAndNc = (advertDetails: AdvertDetails, isNcPage: boolean) => {
    return (
        (advertDetails.adTypeId === adTypeIdMap.ESTATE_NEW_CONSTRUCTION && !isNcPage) ||
        (advertDetails.adTypeId !== adTypeIdMap.ESTATE_NEW_CONSTRUCTION && isNcPage)
    )
}

const adIdPathSegmentRegex = /^.*-(\d+)$/

export const adIdFromPathParameters = (seopath: string | string[]): number | undefined => {
    const pathSegments = Array.isArray(seopath) ? seopath : [seopath]
    const lastMatchingSegment = pathSegments.reverse().find((segment) => adIdPathSegmentRegex.test(segment))
    if (!lastMatchingSegment) {
        return undefined
    }
    const matchResult = adIdPathSegmentRegex.exec(lastMatchingSegment)
    const adIdString = matchResult?.[1]
    if (!adIdString) {
        return undefined
    }
    const adIdNumber = parseInt(adIdString, 10)
    return isNaN(adIdNumber) ? undefined : adIdNumber
}

const redirectToSimilarCategory = async (adId: number, res?: Response) => {
    try {
        const contextLink = await getAdSimilarAdsOrCategory(adId)

        if (contextLink) {
            res?.setHeader('Cache-Control', 'no-cache')
            redirectTo(`${contextLink.uri}?fromExpiredAdId=${adId}`, res, 301)
            return { isNotAvailable: true as const }
        }
    } catch (_error) {}

    if (res) {
        res.statusCode = 404
    }
    return { is404: true as const }
}

const AdDetailTagger: FunctionComponent<{ advertDetails: AdvertDetails }> = ({ advertDetails }) => {
    useEffect(() => {
        callPageView('adview', { taggingData: advertDetails.taggingData })
    }, [advertDetails, advertDetails.id])
    return null
}

AutoMotorAdDetail.getInitialProps = adDetailGetInitialProps(verticalIdMap.MOTOR)
AutoMotorAdDetail.Layout = LeaderboardAndSkyscraperLayout
AutoMotorAdDetail.allowAndroidBanner = false

export default AutoMotorAdDetail

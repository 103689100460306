import { inverseAdTypeMap } from '@wh/common/chapter/types/AdType'
import { ContextLinkList, findContextLinkWithId } from '@bbx/common/types/contextLinks'

export const getLinkToDealerProfile = (adTypeId: number, orgId?: number) => {
    const adTypeSuffix = adTypeToProfileSuffix[adTypeId] ?? ''
    return `/iad/gebrauchtwagen/gebrauchtwagenhaendler-detail${adTypeSuffix}?orgId=${orgId}`
}

export const getOrganisationProfileWeblink = (contextLinks: ContextLinkList) => {
    return findContextLinkWithId('organisation.profileWeblink', contextLinks)?.relativePath
}

const adTypeToProfileSuffix: Record<number, string | undefined> = {
    [inverseAdTypeMap.MotorCar]: '-auto',
    [inverseAdTypeMap.MotorMc]: '-motorrad',
    [inverseAdTypeMap.MotorVanTruck]: '-nutzfahrzeuge',
    [inverseAdTypeMap.MotorCaravan]: '-wohnwagen-wohnmobile',
}

import React, { FunctionComponent } from 'react'
import { Modal } from '@wh-components/core/Modal/Modal'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { Box } from '@wh-components/core/Box/Box'

interface ModalType {
    isOpen: boolean
    onRequestClose: () => void
}

export const DefectsLiabilityModal: FunctionComponent<ModalType> = ({ isOpen, onRequestClose }) => {
    return (
        <Modal header="Gewährleistung" onRequestClose={onRequestClose} isOpen={isOpen} fullScreen={{ phone: true, tablet: false }}>
            <Box maxWidth="536px">
                <b>Gewährleistung</b> ist die gesetzlich geregelte, verschuldensunabhängige Haftung für Mängel, die zum Übergabepunkt
                bereits bestehen, und innerhalb von zwei Jahren geltend gemacht werden kann. Ein Händler kann beim Gebrauchtwagenkauf die
                Gewährleistungsfrist auf ein Jahr - ab Übergabedatum - reduzieren, dies muss jedoch einvernehmlich geschehen und
                ausdrücklich am Kaufvertrag vermerkt werden. Ganz ausgeschlossen werden kann die Gewährleistung bei einem Händlerkauf nicht.
                Bei einem Privatkauf ist ein gänzlicher Ausschluss der Gewährleistung möglich.{' '}
                <ServerRoutingAnchorLink
                    type="anchor"
                    href="https://www.wko.at/branchen/handel/fahrzeughandel/gewaehrleistung-garantie-im-fahrzeughandel.html"
                    target="_blank"
                    rel="nofollow noopener"
                    testId="warrant-more-link"
                >
                    Mehr
                </ServerRoutingAnchorLink>
            </Box>
        </Modal>
    )
}

export const WarrantyModal: FunctionComponent<ModalType> = ({ isOpen, onRequestClose }) => {
    return (
        <Modal header="Garantie" onRequestClose={onRequestClose} isOpen={isOpen} fullScreen={{ phone: true, tablet: false }}>
            <Box maxWidth="536px">
                <b>Garantie</b> ist eine freiwillige, vertragliche Vereinbarung zwischen Hersteller/Verkäufer und Käufer. Mängel, die
                innerhalb der Garantiefrist auftreten und vom Garantieinhalt umfasst sind, werden kostenlos repariert. Man unterscheidet
                zwei Formen der Garantie: Herstellergarantie (bei weitem am häufigsten; Voraussetzung: regelmäßige Services) und
                Händlergarantie.{' '}
                <ServerRoutingAnchorLink
                    type="anchor"
                    href="https://www.wko.at/branchen/handel/fahrzeughandel/gewaehrleistung-garantie-im-fahrzeughandel.html"
                    target="_blank"
                    rel="nofollow noopener"
                    testId="guarantee-more-link"
                >
                    Mehr
                </ServerRoutingAnchorLink>
            </Box>
        </Modal>
    )
}

import { IconButton } from '@wh-components/core/Button/Button'
import React, { FunctionComponent } from 'react'
import { callActionEvent } from '../lib/tagging/tagging'
import { TaggingData } from '../types/taggingData'
import PrintIcon from '@wh-components/icons/Print'
import { TestProps } from '@wh-components/core/common'
import { TaggingActionEvent } from '../lib/tagging/taggingTypes'

interface PrintButtonProps extends Required<TestProps> {
    taggingData?: TaggingData
    id: string
    eventName?: TaggingActionEvent
}

export const PrintButton: FunctionComponent<PrintButtonProps> = ({ taggingData, testId, id, eventName = 'addetail_print_click' }) => {
    return (
        <IconButton
            name="PrintButton"
            variant="outline"
            Icon={PrintIcon}
            size="small"
            testId={testId}
            display={{ phone: 'none', tablet: 'flex' }}
            id={id}
            aria-label="Drucken"
            onClick={async () => {
                callActionEvent(eventName, taggingData)
                window.print()
            }}
        />
    )
}

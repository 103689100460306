import React, { PropsWithChildren } from 'react'
import { FormikProps } from 'formik'

export type FormikSubmitValidationErrorHandlerProps<Values> = FormikProps<Values> &
    PropsWithChildren & {
        callback: () => void
    }

// INFO: It would be easier to solve when `submitForm` would throw an error when validation failed. Since it does not, we need this workaround.
// Unfortunately this was implemented and later reverted in Formik: https://github.com/jaredpalmer/formik/issues/1580#issuecomment-616981261
export class FormikSubmitValidationErrorHandler<Values> extends React.Component<FormikSubmitValidationErrorHandlerProps<Values>> {
    // we could solve that with react hooks on the ContactForm instead once hooks are released
    componentDidUpdate(prevProps: FormikSubmitValidationErrorHandlerProps<Values>) {
        // taken from https://github.com/jaredpalmer/formik/issues/146#issuecomment-327222858
        // formik currently does not call any callback in case submitting the form was aborted due to client validations, therefore we need a workaround
        if (prevProps.isSubmitting && !this.props.isSubmitting && !this.props.isValid) {
            this.props.callback()
        }
    }

    render() {
        return this.props.children
    }
}

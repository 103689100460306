import React, { Fragment, FunctionComponent, PropsWithChildren } from 'react'
import { BbxRouter } from '@wh/common/chapter/lib/routing/bbxRouter'
import { css } from 'styled-components'
import { Card } from '@wh-components/core/Card/Card'
import { getBaseSearchResultPath } from '@bbx/common/lib/seo'
import { Box } from '@wh-components/core/Box/Box'
import { Heading } from '@wh-components/core/Heading/Heading'
import { Text } from '@wh-components/core/Text/Text'
import { Button } from '@wh-components/core/Button/Button'
import Messaging from '@wh-components/icons/Messaging'
import { getSearchResultFromSessionStorage } from '@bbx/common/lib/searchResult-sessionStorage-reader'
import { SuccessAlert } from '@wh/common/chapter/components/Alerts/SuccessAlert'
import { ApiErrorAlert } from '@wh/common/chapter/components/Alerts/ApiErrorAlert'
import { ApiError } from '@wh/common/chapter/lib/errors'

interface RequestSendSuccessProps {
    adId: string
    adTypeId: number
    isHouse: boolean
}

interface RequestSendSuccessMessagingProps extends RequestSendSuccessProps {
    infoTitle: string
    infoBody: string
}

export const RequestSendSuccessMessaging: FunctionComponent<RequestSendSuccessMessagingProps> = ({
    adId,
    adTypeId,
    isHouse,
    infoBody,
    infoTitle,
}) => {
    const savedSearchResult = getSearchResultFromSessionStorage()
    const savedSearchResultResultListLocation = savedSearchResult?.detailLinks.find((l) => l.adId === adId)?.searchResultLocation
    const path = savedSearchResultResultListLocation ?? getBaseSearchResultPath(adTypeId, isHouse)

    const goToSearchResultPage = () => {
        BbxRouter.push({ href: path, clientSideRouting: false })
    }

    return (
        <RequestSendSuccessLayout
            alertSuccessMessage="Deine Nachricht wurde erfolgreich versandt."
            infoTitle={infoTitle}
            infoBody={infoBody}
            goBackToSearchButton={{ onClick: goToSearchResultPage }}
            goToMyMessagesButton={{
                onClick: () => {
                    BbxRouter.push({ href: '/iad/myprofile/chat', clientSideRouting: true })
                },
            }}
        />
    )
}

export const RequestSendSuccessEmail: FunctionComponent<RequestSendSuccessProps> = (props) => {
    const savedSearchResult = getSearchResultFromSessionStorage()
    const savedSearchResultResultListLocation = savedSearchResult?.detailLinks.find((l) => l.adId === props.adId)?.searchResultLocation
    const path = savedSearchResultResultListLocation ?? getBaseSearchResultPath(props.adTypeId, props.isHouse)

    const goToSearchResultPage = () => {
        BbxRouter.push({ href: path, clientSideRouting: false })
    }

    return (
        <RequestSendSuccessLayout
            alertSuccessMessage="Deine E-Mail wurde erfolgreich an den Empfänger versandt."
            infoTitle="Gewerbliche Anfragen per E-Mail"
            infoBody="Anfragen bei gewerblichen Anbietern (z.B. Händler oder Makler) erfolgen per E-Mail und werden nicht in deinen willhaben-Nachrichten angezeigt.
                    Solltest du keine Antwort erhalten, wirf auch einen Blick in den Spam-Ordner deines E-Mail Programms."
            goBackToSearchButton={{ onClick: goToSearchResultPage }}
            goToMyMessagesButton={undefined}
        />
    )
}

interface RequestSendSuccessLayoutProps {
    alertSuccessMessage: string
    infoTitle: string
    infoBody: string
    goBackToSearchButton: {
        onClick: () => void
    }
    goToMyMessagesButton:
        | undefined
        | {
              onClick: () => void
          }
}

const RequestSendSuccessLayout: FunctionComponent<RequestSendSuccessLayoutProps> = (props) => (
    <Fragment>
        <Box marginHorizontal={{ phone: 'm', tablet: 0 }}>
            <SuccessAlert testId="ad-request-send-success" title={props.alertSuccessMessage} marginBottom="m" />
        </Box>
        <SendSuccessInfoBox {...props} />
    </Fragment>
)

type SendSuccessInfoBoxProps = Omit<RequestSendSuccessLayoutProps, 'alertSuccessMessage'>

const SendSuccessInfoBox: FunctionComponent<SendSuccessInfoBoxProps> = (props) => (
    <Card testId="ad-request-send-success-info-box">
        <Heading text={props.infoTitle} color="palette.primary.main" fontSize="m" marginBottom="s" />
        <Text fontSize="s">{props.infoBody}</Text>
        <Box display="flex" alignItems="stretch" marginTop="s">
            <ButtonFlexContainer>
                <Button
                    variant="outline"
                    size="small"
                    testId="ad-request-send-success-search-result-button"
                    onClick={props.goBackToSearchButton.onClick}
                >
                    Zurück zum Suchergebnis
                </Button>
                {props.goToMyMessagesButton && (
                    <Button
                        size="small"
                        Icon={Messaging}
                        testId="ad-request-send-success-messaging-button"
                        onClick={props.goToMyMessagesButton.onClick}
                    >
                        Zu meinen Nachrichten
                    </Button>
                )}
            </ButtonFlexContainer>
        </Box>
    </Card>
)

const ButtonFlexContainer: FunctionComponent<PropsWithChildren<{}>> = (props) => (
    <Box
        display="flex"
        flexDirection={{ phone: 'column', tablet: 'row' }}
        flex="1 1 0px"
        css={css`
            & > * {
                width: 100%;

                &:not(:last-child) {
                    margin-bottom: ${(p) => p.theme.space.s}px;
                }

                ${(p) => p.theme.media.tablet} {
                    max-width: 200px;
                    &:not(:last-child) {
                        margin-bottom: 0;
                        margin-right: ${(p) => p.theme.space.s}px;
                    }
                }
            }
        `}
    >
        {props.children}
    </Box>
)

export const RequestSendError: FunctionComponent<{ error: ApiError }> = (props) => (
    <Box marginHorizontal={{ phone: 'm', tablet: 0 }}>
        <ApiErrorAlert error={props.error.errorResponse} marginBottom="m" />
    </Box>
)

import { AttributeGroup, AttributesItem, FormattedAttributesItem } from '@wh/common/chapter/types/Attributes'
import { AttributeName } from '@wh/common/chapter/types/AttributeName'

export interface AttributeGroupDefinition {
    id: string
    heading: string
    includedAttributes: AttributeName[]
}

export const groupAttributes = (attributes: FormattedAttributesItem[], groupDefinitions: AttributeGroupDefinition[]): AttributeGroup[] => {
    return groupDefinitions.map((groupDefinition) => {
        const attributeList = attributes.filter((attribute) => groupDefinition.includedAttributes.includes(attribute.name))
        return { id: groupDefinition.id, heading: groupDefinition.heading, attribute: attributeList }
    })
}

export const groupAttributesWithSorting = (
    attributes: AttributesItem[],
    groupDefinitions: AttributeGroupDefinition[],
): AttributeGroup[] => {
    return groupDefinitions.map((groupDefinition) => {
        const attributeList = groupDefinition.includedAttributes
            .map((attributeName) => attributes.find((attribute) => attribute.name === attributeName))
            .filter((value) => value !== undefined) as FormattedAttributesItem[]

        return { id: groupDefinition.id, heading: groupDefinition.heading, attribute: attributeList }
    })
}

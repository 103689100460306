import React, { FunctionComponent } from 'react'
import { useDidomi } from '@wh/common/chapter/components/Didomi/useDidomi'

interface GoogleConversionTrackingPixelProps {
    conversionId: string
    conversionLabel: string
}

export const GoogleConversionTrackingPixel: FunctionComponent<GoogleConversionTrackingPixelProps> = ({ conversionId, conversionLabel }) => {
    const { isConsentGiven } = useDidomi('GoogleTrackingPixel')

    return isConsentGiven ? (
        <img
            src={`//www.googleadservices.com/pagead/conversion/${conversionId}/?label=${conversionLabel}&guid=ON&script=0`}
            height="1"
            width="1"
            style={{ display: 'none' }}
        />
    ) : null
}

import React, { FunctionComponent } from 'react'
import { css } from 'styled-components'
import { Box } from '@wh-components/core/Box/Box'
import { TestProps } from '@wh-components/core/common'
import { ImageGalleryData } from '@bbx/search-journey/common/components/ImageGallery/ImageGallery.settings'
import videoPlaceholder from '@bbx/static_hashed/img/adDetail/icon_video_placeholder.svg'
import { ResponsiveValue } from '@wh-components/system'

interface ThumbnailProps {
    image: ImageGalleryData
    isFlickityReady: boolean
    index: number
    lazyLoad?: boolean
    width: ResponsiveValue<string>
}

export const Thumbnail: FunctionComponent<ThumbnailProps & TestProps> = ({ image, isFlickityReady, index, lazyLoad, width, testId }) => {
    return (
        <Box
            className="thumbnail-cell"
            testId={`thumbnail-cell-${testId}`}
            height="58px"
            width={width}
            marginRight="xs"
            // The images need to be part of the dom when flickity is loaded, but displaying them beforehand breaks styles -> images are displayed if flickity is ready
            display={index === 0 || isFlickityReady ? 'flex' : 'none'}
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            css={css`
                opacity: 0.4;
                top: 0;

                ${(p) => p.theme.media.print},
                &:hover {
                    opacity: 1;
                }

                @supports (object-fit: cover) {
                    .thumbnail-cell {
                        top: unset;
                    }
                }

                &.is-selected {
                    opacity: 1;
                    border: 2px solid ${(p) => p.theme.colors.palette.primary.main};
                }
            `}
        >
            {image.mediaType === 'video' ? (
                <img
                    src={videoPlaceholder}
                    data-testid={`thumbnail-${testId}`}
                    alt="Video-Hinweis"
                    css={css`
                        flex-shrink: 0;
                        max-height: 50%;
                        max-width: 50%;

                        @supports (object-fit: cover) {
                            max-width: unset;
                            object-fit: cover;
                            width: 50%;
                            height: 50%;
                        }
                    `}
                />
            ) : (
                <img
                    {...(lazyLoad ? { 'data-flickity-lazyload': image.thumbnailUrl } : { src: image.thumbnailUrl })}
                    data-testid={`thumbnail-${testId}`}
                    alt={image.alt}
                    css={css`
                        flex-shrink: 0;
                        max-height: 100%;
                        max-width: 100%;

                        @supports (object-fit: cover) {
                            max-width: unset;
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }
                    `}
                />
            )}
        </Box>
    )
}

import React, { FunctionComponent, useEffect, useState } from 'react'
import { css } from 'styled-components'
import { Box } from '@wh-components/core/Box/Box'
import { ClientRoutingAnchorLink, ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { Text } from '@wh-components/core/Text/Text'
import ArrowTinyLeftIcon from '@wh-components/icons/ArrowtinyLeft'
import ArrowTinyRightIcon from '@wh-components/icons/ArrowtinyRight'
import {
    appendNextPageSearchResultAndSaveIntoSessionStorage,
    getSearchResultFromSessionStorage,
    prependPreviousPageSearchResultAndSaveIntoSessionStorage,
} from '@bbx/search-journey/common/lib/searchResult-sessionStorage'
import { AdDetailLink, SavedSearchResult } from '@bbx/common/lib/searchResult-sessionStorage-types'
import { ButtonWithLinkStyle } from '@wh-components/core/Button/ButtonWithLinkStyle'
import { BbxRouter } from '@wh/common/chapter/lib/routing/bbxRouter'
import { ContextLink } from '@bbx/common/types/contextLinks'
import { fetchFromContextLink } from '@bbx/search-journey/common/api/searchApiClient'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'

export interface PreviousBackNextProps {
    adId: string
    taggingData: TaggingData
}

export const PreviousBackNext: FunctionComponent<PreviousBackNextProps> = ({ adId, taggingData }) => {
    const [savedSearchResult, setSavedSearchResult] = useState<SavedSearchResult | undefined>(undefined)

    useEffect(() => {
        setSavedSearchResult(getSearchResultFromSessionStorage())
    }, [adId])

    const placeHolder = <Box height="34px" display={{ tablet: 'none' }} />

    if (!savedSearchResult) {
        return placeHolder
    }

    const result = getCurrentAndNextAndPreviousAdWithPagingIfNecessary(savedSearchResult, adId)

    if (!result) {
        return placeHolder
    }

    const { previousAd, currentAd, nextAd } = result

    return (
        <Box display="flex" justifyContent={{ phone: 'space-between', tablet: 'flex-end' }} padding={{ phone: 's', tablet: '0' }}>
            <Box key={1} display="flex" alignItems="center" marginRight="m">
                <ArrowTinyLeftIcon size="xxsmall" marginRight="xs" />
                <ServerRoutingAnchorLink
                    type="anchor"
                    href={`${currentAd.searchResultLocation}#${adId}`}
                    testId="back-to-search-result"
                    aria-label="zurück zum Suchergebnis"
                    color="palette.verydarkgrey"
                >
                    <Text as="p" fontSize="s">
                        zurück zum Suchergebnis
                    </Text>
                </ServerRoutingAnchorLink>
            </Box>

            <Box key={2} display="flex">
                {previousAd && (
                    <Box key={3} display="flex" alignItems="center">
                        <ArrowTinyLeftIcon size="xxsmall" marginRight="xs" />
                        {typeof previousAd === 'function' ? (
                            <ButtonWithLinkStyle
                                testId="previous-ad"
                                aria-label="zur vorigen Anzeige"
                                onClick={async () => {
                                    callActionEvent('addetail_previous_ad_click', taggingData)
                                    const previous = await previousAd()
                                    if (previous) {
                                        BbxRouter.push({ href: previous.seoUrl, clientSideRouting: true })
                                    }
                                }}
                                css={css`
                                    color: ${(p) => p.theme.colors.palette.verydarkgrey};
                                `}
                            >
                                <Text as="p" fontSize="s">
                                    vorige
                                </Text>
                            </ButtonWithLinkStyle>
                        ) : (
                            <ClientRoutingAnchorLink
                                type="anchor"
                                href={previousAd.seoUrl}
                                testId="previous-ad"
                                aria-label="zur vorigen Anzeige"
                                onClick={() => callActionEvent('addetail_previous_ad_click', taggingData)}
                                color="palette.verydarkgrey"
                            >
                                <Text as="p" fontSize="s">
                                    vorige
                                </Text>
                            </ClientRoutingAnchorLink>
                        )}
                    </Box>
                )}

                {previousAd && nextAd && (
                    <Text key={4} as="p" fontSize="s" color="palette.koala">
                        &nbsp;|&nbsp;
                    </Text>
                )}

                {nextAd && (
                    <Box key={5} display="flex" alignItems="center">
                        {typeof nextAd === 'function' ? (
                            <ButtonWithLinkStyle
                                testId="next-ad"
                                aria-label="zur nächsten Anzeige"
                                onClick={async () => {
                                    callActionEvent('addetail_next_ad_click', taggingData)
                                    const next = await nextAd()
                                    if (next) {
                                        BbxRouter.push({ href: next.seoUrl, clientSideRouting: true })
                                    }
                                }}
                                css={css`
                                    color: ${(p) => p.theme.colors.palette.verydarkgrey};
                                `}
                            >
                                <Text as="p" fontSize="s">
                                    nächste
                                </Text>
                            </ButtonWithLinkStyle>
                        ) : (
                            <ClientRoutingAnchorLink
                                type="anchor"
                                href={nextAd.seoUrl}
                                testId="next-ad"
                                aria-label="zur nächsten Anzeige"
                                onClick={() => callActionEvent('addetail_next_ad_click', taggingData)}
                                color="palette.verydarkgrey"
                            >
                                <Text as="p" fontSize="s">
                                    nächste
                                </Text>
                            </ClientRoutingAnchorLink>
                        )}
                        <ArrowTinyRightIcon size="xxsmall" marginLeft="xs" />
                    </Box>
                )}
            </Box>
        </Box>
    )
}

const getCurrentAndNextAndPreviousAdWithPagingIfNecessary = (savedSearchResult: SavedSearchResult, adId: string) => {
    const currentAndNextAndPreviousAd = getCurrentAndNextAndPreviousAdWithoutPaging(savedSearchResult, adId)

    if (typeof currentAndNextAndPreviousAd === 'undefined') {
        return undefined
    }

    const previousSearchResultPageContextLink = savedSearchResult.previousSearchResultPageContextLink
    const previousPageFunction = previousSearchResultPageContextLink
        ? () =>
              fetchAndPersistPreviousPageAndGetLastAdOfPreviousSearchResultPage(
                  previousSearchResultPageContextLink,
                  savedSearchResult,
                  adId,
              ).catch(
                  () =>
                      // gracefully ignore error
                      undefined,
              )
        : undefined
    const nextSearchResultPageContextLink = savedSearchResult.nextSearchResultPageContextLink
    const nextPageFunction = nextSearchResultPageContextLink
        ? () =>
              fetchAndPersistNextPageAndGetFirstAdOfNextSearchResultPage(nextSearchResultPageContextLink, savedSearchResult, adId).catch(
                  () =>
                      // gracefully ignore error
                      undefined,
              )
        : undefined

    return {
        previousAd: currentAndNextAndPreviousAd.previousAd ?? previousPageFunction,
        currentAd: currentAndNextAndPreviousAd.currentAd,
        nextAd: currentAndNextAndPreviousAd.nextAd ?? nextPageFunction,
    }
}

const getCurrentAndNextAndPreviousAdWithoutPaging = (savedSearchResult: SavedSearchResult, adId: string) => {
    const currentIndex = savedSearchResult.detailLinks.findIndex((link) => link.adId === adId)

    if (currentIndex === -1) {
        return undefined
    }

    const previousDetailLink = savedSearchResult.detailLinks[currentIndex - 1] as AdDetailLink | undefined
    const currentDetailLink = savedSearchResult.detailLinks[currentIndex]
    const nextDetailLink = savedSearchResult.detailLinks[currentIndex + 1] as AdDetailLink | undefined

    return {
        previousAd: previousDetailLink,
        currentAd: currentDetailLink,
        nextAd: nextDetailLink,
    }
}

const fetchAndPersistPreviousPageAndGetLastAdOfPreviousSearchResultPage = async (
    previousSearchResultPageContextLink: ContextLink,
    existingSavedSearchResult: SavedSearchResult,
    adId: string,
): Promise<AdDetailLink | undefined> => {
    const searchResult = await fetchFromContextLink<SearchResult>(previousSearchResultPageContextLink)
    const newSavedSearchResult = prependPreviousPageSearchResultAndSaveIntoSessionStorage(searchResult, existingSavedSearchResult)
    return getCurrentAndNextAndPreviousAdWithoutPaging(newSavedSearchResult, adId)?.previousAd
}

const fetchAndPersistNextPageAndGetFirstAdOfNextSearchResultPage = async (
    nextSearchResultPageContextLink: ContextLink,
    existingSavedSearchResult: SavedSearchResult,
    adId: string,
): Promise<AdDetailLink | undefined> => {
    const searchResult = await fetchFromContextLink<SearchResult>(nextSearchResultPageContextLink)
    const newSavedSearchResult = appendNextPageSearchResultAndSaveIntoSessionStorage(searchResult, existingSavedSearchResult)
    return getCurrentAndNextAndPreviousAdWithoutPaging(newSavedSearchResult, adId)?.nextAd
}

import { fetcher } from '@wh/common/chapter/api/fetcher'
import { UserData, UserProfileData } from '@wh/common/chapter/types/user'

export const updateProfileData = (loginID: number, data: UserProfileData): Promise<UserData> => {
    return fetcher(`/iad/user/${loginID}`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
        body: JSON.stringify(data),
    })
}

export interface VerifyChangeCodeResponse {
    status?: string
}

export const verifyChangeCode = (userId: number, code: string): Promise<VerifyChangeCodeResponse> => {
    return fetcher('/iad/myprofile/password/change/validity', {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-type': 'application/json',
        },
        body: JSON.stringify({
            userId,
            code,
        }),
    })
}

export interface AdvertContactDetails {
    contactDetail: AdvertContactDetail[]
}

export interface AdvertContactDetail {
    id: ContactDetailKey
    contactDetailField: ContactDetailField[]
}

export interface ContactDetailField {
    id: string
    description: string
    value: string
}

type ContactDetailKey = ContactDetailValueKey | ContactDetailAddressKey

type ContactDetailValueKey =
    | 'contactName'
    | 'contactName/1'
    | 'contactName_vcard'
    | 'contactFirstName'
    | 'companyName'
    | 'companyName/1'
    | 'companyYears'
    | 'fax'
    | 'fax/1'
    | 'immoCardCompanyId'
    | 'immoCardCompanyId/1'
    | 'immoCardId'
    | 'immoCardId/1'
    | 'phoneNo'
    | 'phoneNo/1'
    | 'phoneNo2'
    | 'phoneNo2/1'
    | 'websiteUrl'
    | 'websiteUrl/1'

type ContactDetailAddressKey = 'address' | 'address_contact' | 'address_vcard' | 'address_location'

export interface ContactDetailAddress {
    country: string
    countryId: string
    municipality?: string
    postCode?: string
    postalName?: string
    region?: string
    state?: string
    street?: string
}

export const getAdvertContactValue = (advertContactDetails: AdvertContactDetails, key: ContactDetailValueKey): string | undefined => {
    const contactItem = advertContactDetails.contactDetail.find((detail) => detail.id === key)
    return contactItem?.contactDetailField[0].value
}

export const getAdvertContactAddress = (
    advertContactDetails: AdvertContactDetails,
    key: ContactDetailAddressKey,
): ContactDetailAddress | undefined => {
    const contactItem = advertContactDetails.contactDetail.find((detail) => detail.id === key)
    return (
        contactItem &&
        (Object.assign({}, ...contactItem.contactDetailField.map((item) => ({ [item.id]: item.value }))) as ContactDetailAddress)
    )
}

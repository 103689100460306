import React, { FunctionComponent, ReactNode } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { Divider } from '@wh-components/core/Divider/Divider'
import { ResponsiveValue } from '@wh-components/system'
import { FlexItemProps } from '@wh-components/system/flexbox'
import { DisplayProps } from '@wh-components/system/layout'
import { SpaceProps } from '@wh-components/system/space'

export interface TRowProps {
    separator?: boolean
    top: ReactNode
    left: ReactNode
    right: ReactNode
    internalOrder?: { posTop: ResponsiveValue<number>; posLeft: ResponsiveValue<number>; posRight: ResponsiveValue<number> }
}

export const TRow: FunctionComponent<TRowProps & Pick<FlexItemProps, 'order'> & DisplayProps & SpaceProps> = ({
    order,
    separator = false,
    internalOrder = { posTop: 0, posLeft: 1, posRight: 2 },
    top,
    left,
    right,
    display = 'flex',
    ...space
}) => {
    const style = (() => {
        return {
            left: { flex: { phone: '1 1 auto' }, width: { phone: '100%', tablet: 'calc(100% - 316px)' } },
            right: { flex: { phone: '1 1 auto', tablet: '0 0 300px' }, width: { tablet: '300px' } },
        }
    })()

    return (
        <Box as="section" order={order} display={display} flexDirection="column" {...space}>
            <Box
                marginBottom={separator ? 'l' : ''}
                display="flex"
                flexWrap={{ tablet: 'wrap' }}
                flexDirection={{ phone: 'column', tablet: 'row' }}
            >
                <Box width="100%" marginBottom="m" order={internalOrder.posTop}>
                    {top}
                </Box>
                <Box
                    flex={style.left.flex}
                    width={style.left.width}
                    marginBottom={{ phone: 'm', tablet: '0' }}
                    order={internalOrder.posLeft}
                >
                    {left}
                </Box>
                <Box
                    flex={style.right.flex}
                    width={style.right.width}
                    marginLeft={{ phone: '0', tablet: 'm' }}
                    order={internalOrder.posRight}
                >
                    {right}
                </Box>
            </Box>
            {separator && <Divider />}
        </Box>
    )
}

TRow.defaultProps = {
    marginBottom: 'm',
}

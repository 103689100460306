import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { SpaceProps } from '@wh-components/system/space'
import React, { FunctionComponent } from 'react'
import { css } from 'styled-components'
import { AdBoxHeading } from '@bbx/common/components/AdBoxHeading/AdBoxHeading'
import { AttributeName } from '@wh/common/chapter/types/AttributeName'
import { FormattedAttributesItem, FormattedAttributesList, getAttribute, getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { getBonusPriceLabel } from '@bbx/search-journey/common/lib/getBonusPriceLabel'
import { Divider } from '@wh-components/core/Divider/Divider'

export type LeasingInfoAttributesBoxProps = SpaceProps & {
    formattedAttributes: FormattedAttributesList
}

export const LeasingInfoAttributesBox: FunctionComponent<LeasingInfoAttributesBoxProps> = ({ formattedAttributes, ...props }) => {
    const attributes = addBonusPriceLabel(formattedAttributes)

    const prices = autoPriceInfoAttributes
        .map((attribute) => getAttribute(attributes, attribute))
        .filter(Boolean) as FormattedAttributesItem[]

    const detailInformation = autoPriceDetailAttributes
        .map((attribute) => getAttribute(attributes, attribute))
        .filter(Boolean) as FormattedAttributesItem[]

    const leasingDetails = getAttribute(formattedAttributes.attribute, 'LEASING/DETAILS')?.values

    if ((!prices || prices.length < 2) && !getAttributeValue(formattedAttributes.attribute, 'LEASING/MONTHLY_RATE')) {
        return null
    }

    return (
        <Box testId="leasing-information-box" id="leasing-information-box" paddingHorizontal={{ phone: 'm', tablet: 0 }} {...props}>
            <AdBoxHeading text="Preisinformationen" marginBottom="s" />
            <Box display="flex" flexDirection="column">
                {prices.map(({ description, formattedValues, postfix }, index) => (
                    <TextRow key={index} left={description} right={[formattedValues[0], postfix].filter((p) => Boolean(p)).join(' ')} />
                ))}
                {(detailInformation.length || leasingDetails) && <Divider marginVertical="s" />}
                {detailInformation.map(({ description, formattedValues, postfix }, index) => (
                    <TextRow key={index} left={description} right={[formattedValues[0], postfix].filter((p) => Boolean(p)).join(' ')} />
                ))}
                {leasingDetails?.map((detailText, index) => <Text key={index}>{detailText}</Text>)}
            </Box>
        </Box>
    )
}

const TextRow: FunctionComponent<{ left?: string; right?: string }> = ({ left, right }) => (
    <Box display="flex" justifyContent="space-between" marginBottom="xs">
        <Text color="palette.elephant" breakWord={true} marginRight="xs">
            {left && capitalizeString(left)}
        </Text>
        <Text
            css={css`
                white-space: nowrap;
            `}
        >
            {right}
        </Text>
    </Box>
)

const addBonusPriceLabel = (formattedAttributes: FormattedAttributesList) => {
    const bonusPriceTradeIn = getAttributeValue(formattedAttributes.attribute, 'MOTOR_PRICE_BONUS/TRADE_IN')
    const bonusPriceFinance = getAttributeValue(formattedAttributes.attribute, 'MOTOR_PRICE_BONUS/FINANCE')
    const bonusPriceLabel = getBonusPriceLabel(bonusPriceTradeIn, bonusPriceFinance)

    return formattedAttributes.attribute.map((attribute) =>
        bonusPriceLabel && attribute.name === 'MOTOR_PRICE/BONUS' ? { ...attribute, description: bonusPriceLabel } : attribute,
    )
}

const capitalizeString = (s: string) => `${s?.charAt(0).toUpperCase()}${s?.slice(1)}`

export const autoPriceInfoAttributes: AttributeName[] = ['PRICE_FOR_DISPLAY', 'MOTOR_PRICE/BONUS', 'MOTOR_PRICE/NET']

export const autoPriceDetailAttributes: AttributeName[] = [
    'LEASING/MONTHLY_RATE',
    'LEASING/ADVANCE_PAYMENT',
    'LEASING/CONTRACT_DURATION_MONTHS',
    'LEASING/MILEAGE_PER_YEAR',
    'LEASING/RESIDUAL_VALUE',
]

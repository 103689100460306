import React, { FunctionComponent, useContext } from 'react'
import { SaveAdButton } from '@bbx/search-journey/common/components/SaveAd/SaveAdButton'
import { AdDetailSavedAdButtonContext } from './AdDetailSavedAdButtonContext'
import { Button } from '@wh-components/core/Button/Button'
import FavoriteIcon from '@wh-components/icons/Favorite'
import FavoriteEmptyIcon from '@wh-components/icons/FavoriteEmpty'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { Text } from '@wh-components/core/Text/Text'
import { css } from 'styled-components'
import { DmpStateContext } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'

interface AdDetailSaveAdButtonProps {
    adId: string
    adTitle?: string
    taggingData?: TaggingData
    testId?: string
    variant?: 'text' | 'icon'
    eventNameSaveAdClick?: TaggingActionEvent
    eventNameUnsaveAdClick?: TaggingActionEvent
}

export const AdDetailSaveAdButton: FunctionComponent<AdDetailSaveAdButtonProps> = ({
    adId,
    adTitle,
    taggingData,
    testId,
    eventNameSaveAdClick = 'addetail_favorite_ad_click_save',
    eventNameUnsaveAdClick = 'addetail_favorite_ad_click_unsave',
    variant = 'text',
}) => {
    const [savedInFolder, setSavedInFolder] = useContext(AdDetailSavedAdButtonContext)
    const { trackCustomDmpEvent } = useContext(DmpStateContext)

    return (
        <SaveAdButton
            adId={adId}
            adTitle={adTitle}
            isSaved={savedInFolder}
            setIsSaved={setSavedInFolder}
            onClickTaggingAction={() => {
                if (!savedInFolder) {
                    trackCustomDmpEvent('UserAction', { eventName: 'TapSaveAd' })
                }
                return callActionEvent(savedInFolder ? eventNameUnsaveAdClick : eventNameSaveAdClick, taggingData)
            }}
            onSavedTaggingAction={() => callActionEvent('addetail_favorite_ad_saved', taggingData)}
            onUnsavedTaggingAction={() => callActionEvent('addetail_favorite_ad_unsaved', taggingData)}
        >
            <Button
                size={{ phone: 'medium', tablet: 'small' }}
                variant={savedInFolder ? 'solid' : 'outline'}
                aria-label="Anzeige Merken"
                aria-pressed={savedInFolder ? true : false}
                name="SaveAdButton"
                testId={testId}
                css={css`
                    padding: ${variant === 'icon' ? '0' : undefined};

                    ${(p) => p.theme.media.only.phone} {
                        padding: 0;
                    }
                `}
            >
                {savedInFolder ? (
                    <FavoriteIcon
                        color="inherit"
                        size={{ phone: 'medium', tablet: variant === 'text' ? 'xsmall' : 'small' }}
                        marginRight={{ tablet: variant === 'text' ? 'xs' : '0' }}
                    />
                ) : (
                    <FavoriteEmptyIcon
                        color="inherit"
                        size={{ phone: 'medium', tablet: variant === 'text' ? 'xsmall' : 'small' }}
                        marginRight={{ tablet: variant === 'text' ? 'xs' : '0' }}
                    />
                )}
                {variant === 'text' && <Text display={{ phone: 'none', tablet: 'inline' }}>{savedInFolder ? 'Gemerkt' : 'Merken'}</Text>}
            </Button>
        </SaveAdButton>
    )
}

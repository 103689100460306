import React, { FunctionComponent, RefObject, ReactElement, useState, useRef, useEffect } from 'react'
import { ApiError, wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import {
    needsToAcceptSmsAllowance as defaultNeedsToAcceptSmsAllowance,
    setSmsAllowanceStatusAccepted as defaultSetSmsAllowanceStatusAccepted,
} from '@wh/common/sms-allowance/api/smsAllowanceApiClient'
import { scrollToElement } from '@wh/common/chapter/lib/commonHelpers'
import { ContactAdvertiser } from '@bbx/common/types/Messaging'
import { redirectToLoginPage } from '@wh/common/chapter/lib/routing/bbxRouter'
import { LoadingWash } from '@wh-components/core/LoadingWash/LoadingWash'
import { SmsAllowanceModal } from '@wh/common/sms-allowance/components/SmsAllowanceModal'
import { RequestSendError } from './AdContactFormRequestSendSuccess'
import { Box } from '@wh-components/core/Box/Box'
import { SmsAllowanceError } from './SmsAllowance'

interface AdContactFormRequestStateContainerProps<
    PendingData extends { data: ContactFormData },
    ContactFormData extends Record<string, unknown> = PendingData['data'],
> {
    adId: string
    adContactVisibilityTrackingRef: RefObject<HTMLDivElement>
    requestSendSuccess: () => ReactElement
    requestForm: (handleSend: (formData: ContactFormData, onRetrySendForm: () => void) => Promise<void>) => ReactElement
    preSendCheck: (
        formData: ContactFormData,
    ) => Promise<{ result: 'needs-login' } | { result: 'success'; pendingRequestData: PendingData; emailAddressForSmsAllowance: string }>
    sendNetworkRequest: (pendingRequestData: PendingData, emailAddress: string) => Promise<ContactAdvertiser>
    onSendSuccess: (pendingRequestData: PendingData) => void

    // mockable props that are provided with defaults
    needsToAcceptSmsAllowance?: typeof defaultNeedsToAcceptSmsAllowance
    setSmsAllowanceStatusAccepted?: typeof defaultSetSmsAllowanceStatusAccepted
}

type AdRequestState<PendingData> =
    | { type: 'idle' }
    | {
          type: 'sms-allowance-showing'
          emailAddress: string
          pendingRequestData: PendingData
          onRetrySendForm: () => void
      }
    | { type: 'sms-allowance-declined'; onRetrySendForm: () => void }
    | { type: 'request-sending' }
    | { type: 'request-send-error'; error: ApiError }
    | { type: 'request-send-success' }

const centerForm = () => scrollToElement(document.getElementById('ad-contact-form-container'), 'center')

export const AdContactFormRequestStateContainer = <
    PendingData extends { data: ContactFormData },
    ContactFormData extends Record<string, unknown> = PendingData['data'],
>({
    needsToAcceptSmsAllowance = defaultNeedsToAcceptSmsAllowance,
    setSmsAllowanceStatusAccepted = defaultSetSmsAllowanceStatusAccepted,
    ...props
}: AdContactFormRequestStateContainerProps<PendingData>) => {
    const [adRequestState, setAdRequestState] = useState<AdRequestState<PendingData>>({ type: 'idle' })

    const adId = props.adId
    const previousAdId = useRef(adId)
    useEffect(() => {
        if (previousAdId.current !== adId) {
            previousAdId.current = adId
            setAdRequestState({ type: 'idle' })
        }
    }, [adId])

    const handleSend = async (formData: ContactFormData, onRetrySendForm: () => void) => {
        setAdRequestState({ type: 'request-sending' })

        const preCheckResult = await props.preSendCheck(formData)
        if (preCheckResult.result === 'needs-login') {
            // should not happen, redirect to login for safety
            setAdRequestState({
                type: 'request-send-error',
                error: new ApiError('user not logged in unexpectedly when trying to send message'),
            })
            redirectToLoginPage()
            return
        }
        const { pendingRequestData, emailAddressForSmsAllowance } = preCheckResult

        try {
            if (await needsToAcceptSmsAllowance(props.adId, emailAddressForSmsAllowance)) {
                setAdRequestState({
                    type: 'sms-allowance-showing',
                    emailAddress: emailAddressForSmsAllowance,
                    pendingRequestData,
                    onRetrySendForm: onRetrySendForm,
                })
                return
            }
            send(pendingRequestData, emailAddressForSmsAllowance)
        } catch (error) {
            setAdRequestState({ type: 'request-send-error', error: wrapInApiErrorIfNecessary(error) })
            return
        }
    }

    const handleSmsAllowanceAccepted = async () => {
        if (adRequestState.type !== 'sms-allowance-showing') {
            // should not happen
            return
        }

        try {
            const response = await setSmsAllowanceStatusAccepted(adRequestState.emailAddress)
            if (!response.confirmed) {
                setAdRequestState({ type: 'request-send-error', error: new ApiError('sms allowance not confirmed after accepting') })
                return
            }
        } catch (error) {
            setAdRequestState({ type: 'request-send-error', error: wrapInApiErrorIfNecessary(error) })
            return
        }

        send(adRequestState.pendingRequestData, adRequestState.emailAddress)
    }

    const handleSmsAllowanceDeclined = async () => {
        if (adRequestState.type !== 'sms-allowance-showing') {
            // should not happen
            return
        }
        centerForm()
        setAdRequestState({ type: 'sms-allowance-declined', onRetrySendForm: adRequestState.onRetrySendForm })
    }

    const send = async (pendingRequestData: PendingData, emailAddress: string) => {
        setAdRequestState({ type: 'request-sending' })

        try {
            const response = await props.sendNetworkRequest(pendingRequestData, emailAddress)

            if (response.sent) {
                setAdRequestState({ type: 'request-send-success' })
                props.onSendSuccess(pendingRequestData)
            } else {
                setAdRequestState({ type: 'request-send-error', error: new ApiError('sending failed') })
            }
        } catch (error) {
            setAdRequestState({ type: 'request-send-error', error: wrapInApiErrorIfNecessary(error) })
            return
        } finally {
            centerForm()
        }
    }

    return (
        <AdContactFormTemplate
            adRequestState={adRequestState}
            adContactVisibilityTrackingRef={props.adContactVisibilityTrackingRef}
            handleSmsAllowanceAccepted={handleSmsAllowanceAccepted}
            handleSmsAllowanceDeclined={handleSmsAllowanceDeclined}
            requestSendSuccess={() => props.requestSendSuccess()}
            requestForm={() => props.requestForm(handleSend)}
        />
    )
}

const AdContactFormTemplate: FunctionComponent<{
    adRequestState: AdRequestState<unknown>
    adContactVisibilityTrackingRef: RefObject<HTMLDivElement>
    handleSmsAllowanceAccepted: () => Promise<void>
    handleSmsAllowanceDeclined: () => Promise<void>
    requestSendSuccess: () => ReactElement
    requestForm: () => ReactElement
}> = (props) => (
    <Box
        display={{ print: 'none', phone: 'block' }}
        id="ad-contact-form-container"
        testId="ad-contact-form-container"
        ref={props.adContactVisibilityTrackingRef}
    >
        <SmsAllowanceModal
            isOpen={props.adRequestState.type === 'sms-allowance-showing'}
            smsAllowanceType="ContactAdvertiser"
            onSmsAllowanceAccepted={props.handleSmsAllowanceAccepted}
            onSmsAllowanceDeclined={props.handleSmsAllowanceDeclined}
        />
        {props.adRequestState.type === 'request-sending' && <LoadingWash />}
        {props.adRequestState.type === 'request-send-error' && <RequestSendError error={props.adRequestState.error} />}
        {props.adRequestState.type === 'request-send-success' && props.requestSendSuccess()}
        {props.adRequestState.type === 'sms-allowance-declined' && (
            <SmsAllowanceError onRetrySendMessage={props.adRequestState.onRetrySendForm} />
        )}
        {props.adRequestState.type !== 'request-send-success' && props.requestForm()}
    </Box>
)

import { CarouselCell } from '@bbx/search-journey/common/components/ImageGallery/CarouselCell'
import { Counter } from '@bbx/search-journey/common/components/ImageGallery/Counter'
import { FlickityWrapper } from '@bbx/search-journey/common/components/ImageGallery/FlickityWrapper'
import { Box } from '@wh-components/core/Box/Box'
import { ResponsiveValue } from '@wh-components/system'
import { ImageScaleModes } from '@wh/common/chapter/components/AdImage'
import React, { FunctionComponent, ReactNode, useMemo } from 'react'
import { css } from 'styled-components'
import {
    FlickityOptionsExtended,
    FLICKITY_CAROUSEL_CELL_CLASSNAME,
    FLICKITY_CAROUSEL_CLASSNAME,
    ImageGalleryData,
} from './ImageGallery.settings'

interface CarouselProps {
    imageData: ImageGalleryData[]
    flickityOptions: FlickityOptionsExtended
    currentIndex: number
    onChange?: (index: number, isFullscreen: boolean) => void
    onFlickityReady: () => void
    isFlickityReady: boolean
    onFullscreenOpen?: () => void
    alwaysShowPrevNextButtons?: boolean
    height: ResponsiveValue<string>
    imageScaleMode?: ImageScaleModes
    onFlickityImageClick?: (imageGalleryData: ImageGalleryData) => void
    overlay?: (currentIndex: number) => ReactNode
}

export const Carousel: FunctionComponent<CarouselProps> = ({
    imageData,
    flickityOptions,
    currentIndex,
    onChange,
    onFlickityReady,
    isFlickityReady,
    onFullscreenOpen,
    alwaysShowPrevNextButtons,
    height,
    imageScaleMode,
    onFlickityImageClick,
    overlay,
}) => {
    const isFullscreen = (fullscreen: boolean | undefined) => {
        return typeof fullscreen !== 'undefined' ? fullscreen : true
    }

    const options: FlickityOptionsExtended = useMemo(() => {
        return {
            ...flickityOptions,
            prevNextButtons: imageData.length > 1,
        }
    }, [flickityOptions, imageData.length])

    return (
        <Box position="relative" testId="gallery-carousel" width="100%">
            <FlickityWrapper
                flickityOptions={options}
                requireImagesLoaded={true}
                requireFullscreen={isFullscreen(options.fullscreen)}
                onFlickityChange={onChange}
                onFlickityReady={onFlickityReady}
                onFullscreenOpen={onFullscreenOpen}
                currentIndex={currentIndex}
                className={FLICKITY_CAROUSEL_CLASSNAME}
                css={carouselCss(options.hideBackground, alwaysShowPrevNextButtons)}
            >
                {imageData.map((image, idx) => {
                    const lazyload = idx !== 0

                    return (
                        <CarouselCell
                            testId={`image-${idx}`}
                            key={`${image.imageUrl}${idx}`}
                            image={image}
                            index={idx}
                            lazyLoad={lazyload}
                            height={height}
                            onFlickityImageClick={onFlickityImageClick}
                            imageScaleMode={imageScaleMode}
                            showMultiImages={options.showMultiImages}
                            isSelected={currentIndex === idx}
                            isFlickityReady={isFlickityReady}
                        />
                    )
                })}
                {!options.hideCounter && isFlickityReady && currentIndex !== undefined && (
                    <Counter current={currentIndex + 1} total={imageData.length} />
                )}
            </FlickityWrapper>
            {overlay && (
                <Box
                    position="absolute"
                    top={0}
                    right={0}
                    bottom={0}
                    left={0}
                    css={css`
                        pointer-events: none;

                        > * {
                            pointer-events: all;
                        }
                    `}
                >
                    {overlay(currentIndex)}
                </Box>
            )}
        </Box>
    )
}

const carouselCss = (hideBackground?: boolean, alwaysShowPrevNextButtons?: boolean) => css`
    &.is-fullscreen {
        z-index: ${(p) => p.theme.zIndices.wash};

        .flickity-viewport {
            touch-action: pan-y pinch-zoom !important;
        }

        .${FLICKITY_CAROUSEL_CELL_CLASSNAME} {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            max-height: 100%;

            img {
                height: auto;
                max-height: 100%;
            }
        }

        .flickity-page-dots {
            display: block;
            line-height: 2;
        }
    }

    background-color: ${(p) => (hideBackground ? 'transparent' : p.theme.colors.palette.babyseal)};
    width: 100%;
    overflow: hidden;
    max-width: 100%;

    .flickity-prev-next-button {
        ${(p) => p.theme.media.only.phone} {
            display: none;
        }
        z-index: ${(p) => p.theme.zIndices.content + 1};
        height: 45%;
        border: 0;
        background-color: transparent;
        padding: 0;
        border-radius: 0;
        top: 50%;

        .flickity-button-icon {
            opacity: 0.4;
            background-color: ${(p) => p.theme.colors.palette.raccoon};
            transition: transform 0.5s;
            transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
            will-change: transform;
            fill: ${(p) => p.theme.colors.palette.white};

            height: 56px;
            width: 32px;
            left: auto;
            top: calc(50% - 28px);

            margin: 0;
            padding: ${(p) => p.theme.space.m}px ${(p) => p.theme.space.xs}px;
        }

        &.previous {
            left: 0;
            .flickity-button-icon {
                border-top-right-radius: ${(p) => p.theme.borderRadius}px;
                border-bottom-right-radius: ${(p) => p.theme.borderRadius}px;
                left: 0;
                transform: ${alwaysShowPrevNextButtons ? 'translateX(0)' : 'translateX(-32px)'};
            }
        }

        &.next {
            right: 0;
            .flickity-button-icon {
                border-top-left-radius: ${(p) => p.theme.borderRadius}px;
                border-bottom-left-radius: ${(p) => p.theme.borderRadius}px;
                right: 0;
                transform: ${alwaysShowPrevNextButtons ? 'translateX(0)' : 'translateX(32px)'};
            }
        }

        &:hover {
            .flickity-button-icon {
                opacity: 1;
            }
            background-color: transparent;
        }
    }

    &:hover {
        .flickity-prev-next-button {
            &.previous {
                .flickity-button-icon {
                    transform: translateX(0px);
                }
            }

            &.next {
                .flickity-button-icon {
                    transform: translateX(0px);
                }
            }
        }
    }

    .flickity-slider {
        display: flex;
        align-items: center;
    }

    .flickity-button {
        transition: background-color 0.25s;
    }

    .flickity-page-dots {
        display: none;
    }
`

import React, { FunctionComponent, Fragment, useState } from 'react'
import { AttributesItem, getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { Button } from '@wh-components/core/Button/Button'
import { Modal } from '@wh-components/core/Modal/Modal'
import { css, keyframes } from 'styled-components'
import { Box } from '@wh-components/core/Box/Box'
import { Spinner } from '@wh-components/core/Spinner/Spinner'
import { PositionProps } from '@wh-components/system/position'
import { logBdsEvent } from '@wh/common/chapter/api/bdsApiClient'
import icon360 from '@bbx/static_hashed/img/adDetail/icon_360view.svg'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'

interface Open360ButtonProps extends PositionProps {
    adDescription: string
    attributes: AttributesItem[]
    adId: string
    taggingData: TaggingData
}

const fadein = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`

export const Open360Button: FunctionComponent<Open360ButtonProps> = ({
    adDescription,
    attributes,
    adId,
    taggingData,
    ...positionProps
}) => {
    const iframeUrl = getAttributeValue(attributes, 'VIRTUAL_VIEW_LINK/URL')
    const [iframeLoaded, setIframeLoaded] = useState(false)
    const [showModal, setShowModal] = useState(false)

    if (!iframeUrl) {
        return null
    }

    return (
        <Fragment>
            <Button
                size={{ phone: 'small', tablet: 'medium' }}
                aria-label="360 Grad Tour starten"
                testId="360-tour-button"
                onClick={() => {
                    callActionEvent('addetail_virtual_tour_click', taggingData)
                    logBdsEvent(adId, 'virtual-tour-link-clicked')
                    setShowModal(true)
                }}
                {...positionProps}
            >
                <img
                    src={icon360}
                    alt=""
                    width="98"
                    height="32"
                    css={css`
                        width: 100%;
                        height: 100%;
                    `}
                />
            </Button>
            <Modal
                header={adDescription}
                isOpen={showModal}
                onRequestClose={() => {
                    setShowModal(false)
                    setIframeLoaded(false)
                }}
                fullScreen={{ phone: true, tablet: false }}
                fullHeightContent={true}
                width={{ tablet: '100%' }}
                height={{ tablet: '100%' }}
                testId="360-tour-modal"
            >
                <Box width="100%" height="100%" position="relative">
                    {!iframeLoaded && (
                        <Box
                            position="absolute"
                            top={0}
                            right={0}
                            bottom={0}
                            left={0}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            testId="360-tour-spinner"
                            css={css`
                                /* let spinner appear a bit later to avoid it flashing only for a fraction of a second in case the iframe loads fast */
                                animation: ${fadein} 0.25s ease-in 1s both;
                            `}
                        >
                            <Spinner />
                        </Box>
                    )}
                    <iframe
                        src={iframeUrl}
                        frameBorder="0"
                        title="360 Grad Tour"
                        allow="autoplay; fullscreen"
                        data-testid="360-tour-iframe"
                        onLoad={() => setIframeLoaded(true)}
                        css={css`
                            width: 100%;
                            height: 100%;
                            /* this avoids showing the spinner over partially loaded content, since the onLoad event will hide it only shortly after content has been loaded */
                            position: relative;
                        `}
                    />
                </Box>
            </Modal>
        </Fragment>
    )
}

import { PayliveryBadge } from '@wh/common/chapter/components/PayliveryBadge/PayliveryBadge'
import { Avatar } from '@wh-components/core/Avatar/Avatar'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { theme } from '@wh-components/core/theme'
import { SpaceProps } from '@wh-components/system/space'
import { formatIsoDate } from '@wh/common/chapter/lib/formatter'
import React, { FunctionComponent, ReactNode } from 'react'
import { PrivateProfilePayLiveryRatingsBadge } from '@bbx/search-journey/common/components/PayliveryTrustSignals/PrivateProfilePayliveryRatingsBadge'
import { PrivateProfilePayLiveryRatingsText } from '@bbx/search-journey/common/components/PayliveryTrustSignals/PrivateProfilePayLiveryRatingsText'
import { Feedback } from '@bbx/search-journey/common/TrustProfile'

interface PrivateProfileProps {
    name: string
    companyName?: string
    image?: string
    testIdPrefix?: string
    userSince?: string
    replyTime?: string
    payliverySales?: number
    presenceText?: string
    shouldShowPayLiveryRatings?: boolean
    feedback?: Pick<Feedback, 'overallScore' | 'receivedCount'>
}

const MAX_TRUST_SIGNALS = 3

interface TrustSignal {
    sort: number
    text?: string
    icon?: ReactNode
}

export const PrivateProfile: FunctionComponent<PrivateProfileProps & SpaceProps> = ({
    name,
    image,
    companyName,
    userSince,
    replyTime,
    testIdPrefix,
    payliverySales,
    presenceText,
    shouldShowPayLiveryRatings = false,
    feedback,
    ...props
}) => {
    /* Sort trust signals by importance here, give sort value for correct placement */
    const trustSignals: TrustSignal[] = [
        { text: userSince && `User:in seit ${formatIsoDate(userSince, 'MM/YYYY')}`, sort: 1 },
        {
            text:
                !feedback?.overallScore && payliverySales
                    ? `${payliverySales} ${payliverySales === 1 ? 'Verkauf' : 'Verkäufe'} mit`
                    : undefined,
            icon: (
                <Box marginLeft="-4px">
                    <PayliveryBadge id="profile-sales-counter" variant="transparent" marginBottom={0} />
                </Box>
            ),
            sort: 4,
        },
        { text: replyTime, sort: 2 },
        { text: presenceText, sort: 3 },
    ].filter(({ text }) => text)

    return (
        <Box padding="m" testId={`${testIdPrefix}-contact-box-private-profile`} {...props}>
            <Box display="flex">
                <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                    <Avatar src={image} alt={name} marginRight="m" testId={`${testIdPrefix}-contact-box-seller-image`} />
                    {feedback?.overallScore && shouldShowPayLiveryRatings && (
                        <PrivateProfilePayLiveryRatingsBadge overallScore={feedback.overallScore} marginRight="m" />
                    )}
                </Box>
                <Box display="flex" justifyContent="center" flexDirection="column" marginLeft={{ tablet: `-${theme.space.sm}px` }}>
                    <Box display="flex" flexDirection="column" flexGrow={1} justifyContent="center">
                        {companyName && (
                            <Text breakWord={true} testId={`${testIdPrefix}-contact-box-company-name`}>
                                {companyName}
                            </Text>
                        )}
                        <Text fontSize="l" fontWeight="semibold" breakWord={true} testId={`${testIdPrefix}-contact-box-seller-name`}>
                            {name}
                        </Text>
                        {trustSignals
                            /* Only show the most important signals */
                            .slice(0, MAX_TRUST_SIGNALS)
                            /* Sort the remaining signals by sort value */
                            .sort((a, b) => a.sort - b.sort)
                            .map(
                                ({ text, icon }, index) =>
                                    text && (
                                        <Box key={index} display="flex" alignItems="baseline" testId={`trust-signal-${index}`}>
                                            <Text fontSize="xs" color="palette.raccoon" testId={`${testIdPrefix}-contact-box-user-since`}>
                                                {text}
                                            </Text>
                                            {icon}
                                        </Box>
                                    ),
                            )}
                    </Box>
                    {shouldShowPayLiveryRatings && feedback?.receivedCount && (
                        <PrivateProfilePayLiveryRatingsText ratingsCount={feedback.receivedCount} />
                    )}
                </Box>
            </Box>
        </Box>
    )
}

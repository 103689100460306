import { getBbxCookiesFromRequest } from '@wh/common/chapter/types/cookies'
import { NextRequest } from '@wh/common/chapter/types/nextJS'
import type {
    CreateTenantProfileFromExchangeRequestType,
    CreateTenantProfileFromExchangeResponseType,
    ExchangeLessorNoteType,
    ExchangeLessorViewResponsePageType,
    ExchangeLessorViewType,
    ExchangeTenantViewResponsePageType,
    ExchangeTenantViewStatisticsType,
    ExpandedTenantProfileResponseType,
    ExpandedTenantProfileResponseWithCreditCheckFeatureToggleType,
    RentalAdvertType,
    RentalExchangeType,
    TenantProfileDTOType,
    TenantProfileExpandablesDeclarationType,
    TokenTenantProfileResponseType,
} from '@wh/common/rental/types'
import { ApiError } from '@wh/common/chapter/lib/errors'
import { defaultPagination, FESortOrder, Pagination, sortOrders } from '@wh/common/rental/lib/routing'
import { UUID } from '@wh/common/chapter/types/utilities'
import { fetcher, getXForwardForHeader } from '@wh/common/chapter/api/fetcher'
import { urlSearchParamsFromObject } from '@wh/common/chapter/api/urlSearchParams'
import { filterXSS } from 'xss'

const TENANT_PROFILE_ACCESS = true
export const TENANT_PROFILE_NOT_FOUND_CODE = 404

export const createTenantProfile = (tenantProfileDTO: TenantProfileDTOType, request?: NextRequest): Promise<TenantProfileDTOType> => {
    return fetcher<TenantProfileDTOType>(`/rental/tenant/profiles`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(tenantProfileDTO),
        cookies: getBbxCookiesFromRequest(request),
    })
}

export const updateTenantProfile = (
    tenantProfileDTO: TenantProfileDTOType,
    tenantProfileUuid: UUID,
    request?: NextRequest,
): Promise<TenantProfileDTOType> => {
    return fetcher<TenantProfileDTOType>(`/rental/tenant/profiles/${tenantProfileUuid}`, {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(tenantProfileDTO),
        cookies: getBbxCookiesFromRequest(request),
    })
}

export const requestTenantProfileForExchange = (exchangeUuid: UUID | undefined, request?: NextRequest) => {
    if (!exchangeUuid) {
        return Promise.reject(new ApiError('exchange missing'))
    }
    return fetcher<RentalExchangeType>(`/rental/exchanges/${exchangeUuid}/request`, {
        method: 'PUT',
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })
}

export const searchTenantRentalExchanges = (tenantUuid: UUID, pagination?: Partial<Pagination>, request?: NextRequest) => {
    if (!tenantUuid) {
        return Promise.reject(new ApiError('tenantUuid missing'))
    }

    const { page, rows } = { ...defaultPagination, ...pagination }
    const url = `/rental/exchanges/tenant/${tenantUuid}/search`
    const params = {
        // Note: API page starts at 0
        page: page - 1,
        rows,
        tenantProfileAccess: TENANT_PROFILE_ACCESS,
    }

    return fetcher<ExchangeTenantViewResponsePageType>(`${url}?${urlSearchParamsFromObject(params)}`, {
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })
}

export const editRentalExchangeNote = (exchangeUuid: UUID, rawNote: string, request?: NextRequest): Promise<ExchangeLessorNoteType> => {
    if (!exchangeUuid) {
        return Promise.reject(new ApiError('exchange missing'))
    }

    const note = filterXSS(rawNote)

    return fetcher<ExchangeLessorNoteType>(`/rental/bff/exchanges/${exchangeUuid}/note`, {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({ note }),
        cookies: getBbxCookiesFromRequest(request),
    })
}

export const revokeRentalExchangeAccess = (exchangeUuid: UUID, request?: NextRequest): Promise<void> => {
    if (!exchangeUuid) {
        return Promise.reject(new ApiError('exchange missing'))
    }

    return fetcher<void>(`/rental/exchanges/${exchangeUuid}/tenant/tenant-profile/access`, {
        method: 'DELETE',
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })
}

export const getExchangeLessorView = (exchangeUuid: UUID | undefined, request: NextRequest): Promise<ExchangeLessorViewType> => {
    if (!exchangeUuid) {
        return Promise.reject(new ApiError('exchangeUuid missing'))
    }

    return fetcher<ExchangeLessorViewType>(`/rental/exchanges/${exchangeUuid}/lessor`, {
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })
}

export const getRentalTokenTenantProfile = async (
    token: string | undefined,
    request: NextRequest,
): Promise<TokenTenantProfileResponseType> => {
    if (!token) {
        return Promise.reject(new ApiError('exchange token for commercial rental profile missing'))
    }

    return fetcher<TokenTenantProfileResponseType>(`/rental/exchanges/tenant-profile?${new URLSearchParams({ token })}`, {
        headers: getXForwardForHeader(request),
    })
}

export const deleteTenantProfile = (tenantUuid: UUID): Promise<void> => {
    if (!tenantUuid) {
        return Promise.reject(new ApiError('tenantUuid missing'))
    }

    return fetcher<void>(`/rental/tenant/profiles?${new URLSearchParams({ tenantUuid })}`, {
        method: 'DELETE',
        credentials: 'include',
    })
}

export const getExchangeTenantViewStatistics = (
    tenantUuid: UUID,
    request: NextRequest | undefined,
): Promise<ExchangeTenantViewStatisticsType> => {
    if (!tenantUuid) {
        return Promise.reject(new ApiError('tenantUuid missing'))
    }

    return fetcher<ExchangeTenantViewStatisticsType>(`/rental/exchanges/tenant/${tenantUuid}/statistics`, {
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })
}

// #region LESSOR API Layer
export const searchLessorRentalExchanges = async (
    lessorUuid: UUID,
    advertId: RentalAdvertType['adId'],
    queryParams?: Partial<Pagination> & { sort?: FESortOrder },
    request?: NextRequest,
    ignoreTenantProfileAccess?: boolean,
): Promise<ExchangeLessorViewResponsePageType> => {
    // Guards to follow tenant approach
    if (!lessorUuid) {
        return Promise.reject(new ApiError('lessorUuid missing'))
    }

    const { page, rows, sort } = { ...defaultPagination, ...queryParams }
    const url = `/rental/exchanges/lessor/${lessorUuid}/search`
    const params = {
        advertId,
        expand: 'rentalAdverts',
        // Note: API page starts at 0
        page: page - 1,
        rows,
        sort: sort ? sortOrders[sort] : undefined,
        tenantProfileAccess: ignoreTenantProfileAccess ? undefined : TENANT_PROFILE_ACCESS,
    }

    return fetcher<ExchangeLessorViewResponsePageType>(`${url}?${urlSearchParamsFromObject(params)}`, {
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })
}

// #region LESSOR API Layer
export const searchArchivedLessorRentalExchanges = async (
    lessorUuid: UUID,
    advertId: RentalAdvertType['adId'],
    queryParams?: Partial<Pagination> & { sort?: FESortOrder },
    request?: NextRequest,
    ignoreTenantProfileAccess?: boolean,
): Promise<ExchangeLessorViewResponsePageType> => {
    // Guards to follow tenant approach
    if (!lessorUuid) {
        return Promise.reject(new ApiError('lessorUuid missing'))
    }

    const { page, rows, sort } = { ...defaultPagination, ...queryParams }
    const url = `/rental/exchanges/lessor/${lessorUuid}/search`
    const params = {
        advertId,
        archived: true,
        expand: 'rentalAdverts',
        // Note: API page starts at 0
        page: page - 1,
        rows,
        sort: sort ? sortOrders[sort] : undefined,
        tenantProfileAccess: ignoreTenantProfileAccess ? undefined : TENANT_PROFILE_ACCESS,
    }

    return fetcher<ExchangeLessorViewResponsePageType>(`${url}?${urlSearchParamsFromObject(params)}`, {
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })
}

export const toggleFavoriteExchange = async (
    exchangeUuid: UUID,
    isFavorite: boolean,
    request?: NextRequest,
): Promise<ExchangeLessorViewType> => {
    if (!exchangeUuid) {
        return Promise.reject(new ApiError('exchangeUuid missing'))
    }
    const url = `/rental/exchanges/${exchangeUuid}/lessor/sort-position`

    return fetcher<ExchangeLessorViewType>(url, {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({ sortPosition: isFavorite ? 0 : 1 }),
        cookies: getBbxCookiesFromRequest(request),
    })
}

export const toggleArchiveExchange = async (
    exchangeUuid: UUID,
    isArchived: boolean,
    request?: NextRequest,
): Promise<ExchangeLessorViewType> => {
    if (!exchangeUuid) {
        return Promise.reject(new ApiError('exchangeUuid missing'))
    }
    const url = `/rental/exchanges/${exchangeUuid}/lessor/sort-position`

    return fetcher<ExchangeLessorViewType>(url, {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({ sortPosition: isArchived ? 0 : -1 }),
        cookies: getBbxCookiesFromRequest(request),
    })
}
// #endregion LESSOR API Layer

// #region RentalAdvert API Layer
export const getRentalAdvert = (adUuid: UUID, request?: NextRequest) => {
    if (!adUuid) {
        return Promise.reject(new ApiError('adUuid missing'))
    }

    return fetcher<RentalAdvertType>(`/rental/adverts/${adUuid}`, {
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })
}
// #endregion RentalAdvert API Layer

// #region BFF TenantProfile
export const getExpandedTenantProfileResponse = async (
    tenantUuid: UUID,
    expandParams: TenantProfileExpandablesDeclarationType = ['rentalOrders', 'creditChecks'],
    request?: NextRequest,
    abortSignal?: AbortSignal,
): Promise<ExpandedTenantProfileResponseWithCreditCheckFeatureToggleType> => {
    if (!tenantUuid) {
        return Promise.reject(new ApiError('tenantUuid missing'))
    }

    // The contextLinks here are different from the ones we have in BBX usually.
    // Also here their purpose is only to determine if the feature is enabled via the Feature Tag
    const { contextLinks, tenantCreditChecks, tenantOrders, tenantProfile } = await fetcher<
        ExpandedTenantProfileResponseType & { contextLinks: unknown[] }
    >(`/rental/bff/tenants/${tenantUuid}/profile/expanded?${urlSearchParamsFromObject({ expand: expandParams })}`, {
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
        signal: abortSignal,
    })

    return {
        isCreditCheckFeatureEnabled: contextLinks.length !== 0,
        tenantCreditChecks,
        tenantOrders,
        tenantProfile,
    }
}

export const createTenantProfileFromExchange = (payload: CreateTenantProfileFromExchangeRequestType, request?: NextRequest) => {
    return fetcher<CreateTenantProfileFromExchangeResponseType>('/rental/bff/tenant/profiles', {
        method: 'POST',
        body: JSON.stringify(payload),
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })
}
// #endregion BFF TenantProfile

// #region RentalOrder
export {
    acknowledgePaymentError,
    createPaymentForRentalOrder,
    createRentalOrder,
    deleteRentalOrder,
} from '../../../applications/lead/src/domains/rental/api/rentalorders'
// #endregion RentalOrder

// #region CreditCheck
export { createCreditCheck, deleteCreditCheck } from '../../../applications/lead/src/domains/rental/api/creditchecks'
// #endregion CreditCheck

import { ApiError } from '@wh/common/chapter/lib/errors'
import { UUID } from '@wh/common/chapter/types/utilities'
import { NextRequest } from '@wh/common/chapter/types/nextJS'
import type {
    BillingInformationType,
    PaymentCreatePayloadType,
    PaymentCreateResponseType,
    RentalOrderCreatePayloadType,
    RentalOrderResponseType,
} from '@wh/common/rental/types'
import { getBbxCookiesFromRequest } from '@wh/common/chapter/types/cookies'
import { fetcher } from '@wh/common/chapter/api/fetcher'

// RentalOrder Realm
// #region RentalOrder
export const createRentalOrder = (tenantUuid: UUID, request?: NextRequest) => {
    if (!tenantUuid) {
        return Promise.reject(new ApiError('tenantUuid missing'))
    }

    const payload: RentalOrderCreatePayloadType = { userUuid: tenantUuid, productType: 'SINGLE_TENANT_CREDIT_CHECK', originator: 'tenant' }

    return fetcher<RentalOrderResponseType>('/rental/orders', {
        method: 'POST',
        body: JSON.stringify(payload),
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })
}

export const deleteRentalOrder = (rentalOrderUuid: UUID, request?: NextRequest) => {
    if (!rentalOrderUuid) {
        return Promise.reject(new ApiError('rentalOrderUuid missing'))
    }

    return fetcher<void>(`/rental/orders/${rentalOrderUuid}`, {
        method: 'DELETE',
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })
}

export const createPaymentForRentalOrder = async (rentalOrderUuid: UUID, data: BillingInformationType, request?: NextRequest) => {
    if (!rentalOrderUuid) {
        return Promise.reject(new ApiError('rentalOrderUuid missing'))
    }

    const payload: PaymentCreatePayloadType = { billingInformation: data }

    return fetcher<PaymentCreateResponseType>(`/rental/orders/${rentalOrderUuid}/payment`, {
        method: 'POST',
        body: JSON.stringify(payload),
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })
}

export const acknowledgePaymentError = async (rentalOrderUuid: UUID) => {
    if (!rentalOrderUuid) {
        return Promise.reject(new ApiError('rentalOrderUuid missing'))
    }

    return fetcher(`/rental/orders/${rentalOrderUuid}/payment/error/acknowledge`, {
        method: 'POST',
        credentials: 'include',
    })
}
// #endregion RentalOrder

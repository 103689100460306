import { getUniversalBbxCookie } from '@wh/common/chapter/types/cookies'
import { useEffect } from 'react'
import { CdcLastViewedAds, CdcLastViewedAdsForAllVerticals } from '@bbx/common/types/UserFeed'
import { VerticalId, verticalIdMap } from '@wh/common/chapter/types/verticals'

const DAYS_IN_MS = 1000 * 60 * 60 * 24
const expiresInOneMonth = new Date(new Date().getTime() + DAYS_IN_MS * 30)

export const useLastViewedAds = (adId: number, verticalId: VerticalId) => {
    const lastViewedAdsAsString = getUniversalBbxCookie('bbxLastViewed', undefined) || '{}'

    const lastViewedAds = JSON.parse(lastViewedAdsAsString) as CdcLastViewedAdsForAllVerticals

    useEffect(() => {
        const key = getLastViewedAdsVerticalKey(verticalId)
        lastViewedAds[key] = appendNewLastViewedAd(lastViewedAds[key], adId, Date.now())

        const value = JSON.stringify(lastViewedAds)
        document.cookie = `bbxLastViewed=${value};path=/iad;expires=${expiresInOneMonth.toUTCString()}`
    }, [adId, verticalId, lastViewedAds])
}

export const appendNewLastViewedAd = (
    lastViewedAds: CdcLastViewedAds | undefined,
    adId: number,
    viewed: number,
    maxLength: number = 10,
): CdcLastViewedAds => {
    const existingAdListV2 = lastViewedAds?.adListV2 ? lastViewedAds.adListV2.filter((existingAdId) => existingAdId.adId !== adId) : []
    return { adListV2: [{ adId: adId, viewed: viewed }, ...existingAdListV2].slice(0, maxLength) }
}

export const getLastViewedAdsVerticalKey = (verticalId: VerticalId): 'bap' | 'estate' | 'motor' => {
    if (verticalId === verticalIdMap.BAP) {
        return 'bap'
    } else if (verticalId === verticalIdMap.MOTOR) {
        return 'motor'
    } else if (verticalId === verticalIdMap.ESTATE) {
        return 'estate'
    }

    return 'bap'
}

import { AdServiceLandingPageData } from '@bbx/common/types/ad-detail/AdServices'
import { fetcher } from '@wh/common/chapter/api/fetcher'
import { getBbxCookiesFromRequest } from '@wh/common/chapter/types/cookies'
import { getHeaderValue } from '@wh/common/chapter/types/headers'
import { NextRequest } from '@wh/common/chapter/types/nextJS'

export const getAdServiceLandingPageData = async (
    adId: number,
    groupId: number,
    request?: NextRequest,
): Promise<AdServiceLandingPageData> => {
    const xForwardedFor = getHeaderValue(request, 'X-Forwarded-For')
    return await fetcher<AdServiceLandingPageData>(`/iad/adservices/${adId}/${groupId}?formatEnableHtmlTags=true`, {
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
        headers: xForwardedFor ? { 'X-Forwarded-For': xForwardedFor } : undefined,
    })
}

export const postClickCounter = async (promotionId: number): Promise<AdServiceLandingPageData> => {
    return await fetcher<AdServiceLandingPageData>(`/iad/adservices/clickCounter/${promotionId}`, {
        method: 'POST',
        credentials: 'include',
    })
}

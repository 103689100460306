import { ApiError } from '@wh/common/chapter/lib/errors'
import { UUID } from '@wh/common/chapter/types/utilities'
import type { CreditCheckCreatePayloadType, CreditCheckResponseType } from '@wh/common/rental/types'
import { fetcher } from '@wh/common/chapter/api/fetcher'

// Realm of the RentalApiClient in order to avoid file size exceed
// #region CreditChecks
export const createCreditCheck = (userUuid: UUID, rentalOrderUuid: UUID) => {
    if (!userUuid) {
        return Promise.reject(new ApiError('userUuid missing'))
    }

    if (!rentalOrderUuid) {
        return Promise.reject(new ApiError('rentalOrderUuid missing'))
    }

    const payload: CreditCheckCreatePayloadType = { userUuid, rentalOrderUuid, originator: 'tenant' }

    return fetcher<CreditCheckResponseType>('/rental/creditchecks', {
        method: 'POST',
        body: JSON.stringify(payload),
        credentials: 'include',
    })
}

export const deleteCreditCheck = (creditCheckUuid: UUID) => {
    if (!creditCheckUuid) {
        return Promise.reject(new ApiError('creditCheckUuid missing'))
    }

    return fetcher<void>(`/rental/creditchecks/${creditCheckUuid}`, {
        method: 'DELETE',
        credentials: 'include',
    })
}
// #endregion CreditChecks

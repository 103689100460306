import React, { FunctionComponent, useContext } from 'react'
import { Card } from '@wh-components/core/Card/Card'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { AdServiceGroup, AdServicePromotion } from '@bbx/common/types/ad-detail/AdServices'
import { filterXSS } from 'xss'
import { Box } from '@wh-components/core/Box/Box'
import { Heading } from '@wh-components/core/Heading/Heading'
import { Text } from '@wh-components/core/Text/Text'
import ArrowTinyRightIcon from '@wh-components/icons/ArrowtinyRight'
import { DmpStateContext } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'
import { AdBoxHeading } from '@bbx/common/components/AdBoxHeading/AdBoxHeading'
import { SpaceProps } from '@wh-components/system/space'
import { DisplayProps } from '@wh-components/system/layout'
import { getRelativeUrl } from '@wh/common/chapter/lib/urlHelpers'
import { AdvertisementHint } from '@bbx/search-journey/sub-domains/ad-detail/components/common/AdvertismentHint/AdvertisementHint'
import { postClickCounter } from '@bbx/search-journey/sub-domains/ad-detail/api/adservicesApiClient'

interface ServiceBoxProps {
    serviceBoxGroups: AdServiceGroup[] | null
    title?: string
    showInColumns?: boolean
}

export const ServiceBox: FunctionComponent<ServiceBoxProps & SpaceProps & DisplayProps> = ({
    serviceBoxGroups,
    title,
    showInColumns = false,
    ...props
}) => {
    return serviceBoxGroups?.length ? (
        <Box {...props}>
            {title && (
                <Box display="flex" marginBottom="s" paddingHorizontal={{ phone: 'm', tablet: '0' }}>
                    <AdBoxHeading text={title} />
                </Box>
            )}
            <Box display="flex" flexDirection={showInColumns ? 'column' : { phone: 'column', tablet: 'row' }} rowGap="s" columnGap="m">
                {serviceBoxGroups.map((group) => (
                    <ServiceBoxGroup group={group} key={group.groupData.id} />
                ))}
            </Box>
        </Box>
    ) : null
}

interface ServiceBoxGroupProps {
    group: AdServiceGroup
}

const ServiceBoxGroup: FunctionComponent<ServiceBoxGroupProps> = ({ group }) => {
    return group.promotions?.length ? (
        <Card
            key={group.groupData.id}
            display="flex"
            flexDirection="column"
            flex={{ phone: '1 1 auto', tablet: '1 1 100%' }}
            testId={`serviceBox-group-${group.groupData.id}`}
        >
            <Heading level={3} fontSize="m" fontWeight="bold" color="palette.primary.main" text={group.groupData.detailHeading} />
            {group.promotions.map((promotion) => (
                <ServiceBoxPromotion
                    promotion={promotion}
                    textlinkDescription={group.groupData.dmpTextlinkDescription}
                    organisationName={promotion.organisationName}
                    key={promotion.id}
                />
            ))}
            <AdvertisementHint
                id={group.groupData.id}
                marginTop="sm"
                advertisers={group.promotions.map((promotion) => promotion.organisationName)}
            />
        </Card>
    ) : null
}

interface ServiceBoxPromotionProps {
    promotion: AdServicePromotion
    textlinkDescription: string
    organisationName: string
}

const ServiceBoxPromotion: FunctionComponent<ServiceBoxPromotionProps> = ({ promotion, textlinkDescription, organisationName }) => {
    const { trackCustomDmpEvent } = useContext(DmpStateContext)

    return promotion.detailLinkText && promotion.detailLink ? (
        <Box marginTop={{ phone: 'xs', tablet: 0 }} display="flex" testId={`serviceBox-promotion-${promotion.id}`}>
            <CountPixel countPixel={promotion.serviceBoxCountPixel} />
            <ServerRoutingAnchorLink
                type="anchor"
                color="palette.verydarkgrey"
                href={getRelativeUrl(promotion.detailLink)}
                target="_blank"
                rel="nofollow noopener"
                testId={`promotion-${promotion.id}`}
                onClick={() => {
                    postClickCounter(promotion.id)
                    trackCustomDmpEvent('UserAction', {
                        eventName: 'TapServicebox',
                        textlinkDescription: textlinkDescription,
                        advertiser: organisationName,
                    })
                }}
                onAuxClick={() => {
                    postClickCounter(promotion.id)
                    trackCustomDmpEvent('UserAction', {
                        eventName: 'TapServicebox',
                        textlinkDescription: textlinkDescription,
                        advertiser: organisationName,
                    })
                }}
            >
                <Text fontSize="s">{promotion.detailLinkText}</Text>
                <ArrowTinyRightIcon size="xxsmall" marginLeft="xs" />
            </ServerRoutingAnchorLink>
        </Box>
    ) : null
}

interface CountPixelProps {
    countPixel: string | null
}

const CountPixel: FunctionComponent<CountPixelProps> = ({ countPixel }) => {
    if (!countPixel) {
        return null
    }

    const sanitizedPixel = filterXSS(countPixel, {
        whiteList: {
            img: ['src', 'width', 'height', 'border', 'alt'],
        },
    })

    return <div aria-hidden="true" dangerouslySetInnerHTML={{ __html: sanitizedPixel }} />
}

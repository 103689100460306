import styled from 'styled-components'

export const ItemSpacing = styled.div`
    & > * {
        margin-bottom: ${(p) => p.theme.space.m}px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`

import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { prependHttpIfMissing } from '@wh/common/chapter/lib/urlHelpers'
import { logBdsEvent } from '@wh/common/chapter/api/bdsApiClient'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { display, DisplayProps } from '@wh-components/system/layout'

interface DealerProfileProps {
    name: string
    image?: string
    link?: string
    companyYears?: string
    adId: string
    taggingData: TaggingData
    testIdPrefix?: string
    dealerImageMaxHeight?: number
}

export const DealerProfile: FunctionComponent<DealerProfileProps> = ({
    image,
    name,
    link,
    companyYears,
    adId,
    taggingData,
    testIdPrefix,
    dealerImageMaxHeight = 80,
}) => {
    const dealerImage = (
        <DealerImage
            src={image}
            title={name}
            alt={name}
            data-testid={`${testIdPrefix}-contact-box-seller-image`}
            display="inline-block"
            dealerImageMaxHeight={dealerImageMaxHeight}
        />
    )

    return (
        <Box padding="m" backgroundColor="palette.white" testId={`${testIdPrefix}-contact-box-dealer-profile`}>
            {image && (
                // we cannot put the anchorlink and image into a flex box because IE11 and Safari will distort the aspect ratio of the image, especially with images that are shrunk to fit the max-width
                <Box marginBottom="s" maxWidth="100%" textAlign="center" lineHeight="0">
                    {link ? (
                        <ServerRoutingAnchorLink
                            type="anchor"
                            href={prependHttpIfMissing(link)}
                            rel="nofollow"
                            target="_blank"
                            display="inline-block"
                            maxWidth="100%"
                            onClick={() => {
                                logBdsEvent(adId, 'homepage-clicked')
                                return callActionEvent('addetail_dealer_website_click', taggingData)
                            }}
                        >
                            {dealerImage}
                        </ServerRoutingAnchorLink>
                    ) : (
                        dealerImage
                    )}
                </Box>
            )}
            <Box display="flex" flexDirection="column" alignItems="center">
                <Text
                    maxWidth="100%"
                    fontSize="l"
                    fontWeight="semibold"
                    textAlign="center"
                    breakWord={true}
                    testId={`${testIdPrefix}-contact-box-seller-name`}
                >
                    {name}
                </Text>
                {companyYears && Number(companyYears) > 7 && (
                    <CompanyYears marginTop="xs" testId={`${testIdPrefix}-contact-box-company-years`}>
                        Seit {companyYears} Jahren Händler bei willhaben
                    </CompanyYears>
                )}
                <Text as="p" color="palette.elephant" fontSize="xs">
                    Unternehmen
                </Text>
            </Box>
        </Box>
    )
}

const DealerImage = styled.img.attrs<Pick<DisplayProps, 'display'> & { dealerImageMaxHeight: number }>((props) => ({ ...props }))`
    max-height: ${(p) => p.dealerImageMaxHeight}px;
    max-width: 100%;
    user-select: none;
    ${display}
`

const CompanyYears = styled(Box)`
    padding: 2px 8px;
    border-radius: 16px;
    font-size: ${(p) => p.theme.fontSizes.xs};
    font-weight: ${(p) => p.theme.fontWeights.bold};
    color: ${(p) => p.theme.colors.palette.jungle};
    background-color: ${(p) => rgba(p.theme.colors.palette.jungle, 0.15)};
    user-select: none;
`

import { Place, WithContext } from 'schema-dts'
import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { getMapCoordinates } from '@bbx/search-journey/common/lib/getMapCoordinates'
import { getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { adTypeIdMap } from '@wh/common/chapter/types/AdType'

export const getPlace = (advertDetails: AdvertDetails): Place | undefined => {
    const mapCoordinates = getMapCoordinates(advertDetails)
    return {
        '@type': 'Place',
        address: {
            '@type': 'PostalAddress',
            addressCountry: advertDetails.advertAddressDetails?.country,
            addressLocality: advertDetails.advertAddressDetails?.district,
            addressRegion: advertDetails.advertAddressDetails?.province,
            postalCode: advertDetails.advertAddressDetails?.postCode,
            streetAddress: advertDetails.advertAddressDetails?.addressLines.value[0] ?? undefined,
        },
        geo: {
            '@type': 'GeoCoordinates',
            latitude: mapCoordinates ? mapCoordinates[0] : undefined,
            longitude: mapCoordinates ? mapCoordinates[1] : undefined,
        },
    }
}

export const getFullPlace = (advertDetails: AdvertDetails): Place | undefined => {
    const mapCoordinates = getMapCoordinates(advertDetails)
    let floorSize
    switch (advertDetails.adTypeId) {
        case adTypeIdMap.ESTATE_SALE:
        case adTypeIdMap.ESTATE_RENT:
        case adTypeIdMap.ESTATE_LEISURE_SALE:
        case adTypeIdMap.ESTATE_LEISURE_RENT:
            floorSize = getAttributeValue(advertDetails.attributes.attribute, 'ESTATE_SIZE/LIVING_AREA')
            break
        case adTypeIdMap.ESTATE_BUSINESS_SALE:
        case adTypeIdMap.ESTATE_BUSINESS_RENT:
            floorSize = getAttributeValue(advertDetails.attributes.attribute, 'ESTATE_SIZE/USEABLE_AREA')
            break
        case adTypeIdMap.ESTATE_PLOT_SALE:
            floorSize = getAttributeValue(advertDetails.attributes.attribute, 'PLOT/AREA')
            break
        case adTypeIdMap.ESTATE_MISC:
            floorSize = getAttributeValue(advertDetails.attributes.attribute, 'ESTATE_SIZE')
            break
    }

    const numberOfRooms = getAttributeValue(advertDetails.attributes.attribute, 'NO_OF_ROOMS')

    const accommodation: WithContext<Place> = {
        '@context': 'https://schema.org',
        '@type': 'Accommodation',
        numberOfRooms: numberOfRooms
            ? {
                  '@type': 'QuantitativeValue',
                  value: getAttributeValue(advertDetails.attributes.attribute, 'NO_OF_ROOMS'),
              }
            : undefined,
        floorLevel: getAttributeValue(advertDetails.attributes.attribute, 'FLOOR'),
        floorSize: {
            '@type': 'QuantitativeValue',
            unitCode: 'MTK',
            value: floorSize,
        },
        address: {
            '@type': 'PostalAddress',
            addressCountry: advertDetails.advertAddressDetails?.country,
            addressLocality: advertDetails.advertAddressDetails?.district,
            addressRegion: advertDetails.advertAddressDetails?.province,
            postalCode: advertDetails.advertAddressDetails?.postCode,
            streetAddress: advertDetails.advertAddressDetails?.addressLines.value[0] ?? undefined,
        },
        geo: {
            '@type': 'GeoCoordinates',
            latitude: mapCoordinates ? mapCoordinates[0] : undefined,
            longitude: mapCoordinates ? mapCoordinates[1] : undefined,
        },
    }
    return accommodation
}

import React, { FunctionComponent, ReactNode } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { DisplayProps } from '@wh-components/system/layout'
import { SpaceProps } from '@wh-components/system/space'
import { FlexItemProps } from '@wh-components/system/flexbox'
import { css } from 'styled-components'

export interface FullWidthRowProps {
    children: ReactNode
}

export const FullWidthRow: FunctionComponent<FullWidthRowProps & Pick<FlexItemProps, 'order'> & DisplayProps & SpaceProps> = ({
    order,
    children,
    display,
    ...space
}) => (
    <Box
        as="section"
        order={order}
        display={display}
        {...space}
        css={css`
            &:empty {
                display: none;
            }
        `}
    >
        {children}
    </Box>
)

FullWidthRow.defaultProps = {
    marginBottom: 'm',
}

import React, { FunctionComponent, PropsWithChildren, RefObject } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { Card } from '@wh-components/core/Card/Card'
import { Text } from '@wh-components/core/Text/Text'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { formatIsoDate } from '@wh/common/chapter/lib/formatter'

export const ReportAdBox: FunctionComponent<PropsWithChildren<{}>> = ({ children }) => (
    <Card
        paddingVertical="s"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection={{ phone: 'column', tablet: 'row' }}
    >
        {children}
    </Card>
)

export const WhCodeAndLastChangedDate: FunctionComponent<{
    adId: string
    changedDate: string
    lazyLoadingRef?: RefObject<HTMLAnchorElement>
}> = ({ adId, changedDate, lazyLoadingRef }) => (
    <Box display="flex" alignItems="center" flexDirection={{ phone: 'column', tablet: 'row' }} fontSize="s" color="palette.raccoon">
        <Text ref={lazyLoadingRef}>
            <Text>willhaben-Code: </Text>
            <Text testId="ad-detail-ad-id">{adId}</Text>
        </Text>
        <Text display={{ phone: 'none', tablet: 'flex' }}>&nbsp;|&nbsp;</Text>
        <Text>
            <Text>Zuletzt geändert: </Text>
            <Text testId="ad-detail-ad-edit-date">{formatIsoDate(changedDate, 'DD.MM.YYYY, HH:mm')} Uhr</Text>
        </Text>
    </Box>
)

export const ReportAdButton: FunctionComponent<{ adId: string }> = ({ adId }) => (
    <ServerRoutingAnchorLink
        type="anchor"
        href={`/iad/reportad?adId=${adId}`}
        display={{ print: 'none' }}
        color="palette.raccoon"
        marginTop={{ phone: 'm', tablet: 0 }}
        testId="ad-detail-report-ad"
    >
        <Text as="p" fontSize="s">
            <Text fontWeight="bold">!</Text>
            <Text> Anzeige melden</Text>
        </Text>
    </ServerRoutingAnchorLink>
)

import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { Box, BoxProps } from '@wh-components/core/Box/Box'
import React, { FunctionComponent } from 'react'
import { BadgeText } from '../BadgeText/BadgeText'

type Props = {
    advertDetails: AdvertDetails
} & BoxProps

export const BumpedBadgeText: FunctionComponent<Props> = ({ advertDetails, ...props }) => {
    const bumped = advertDetails?.upsellings?.find((upselling) => upselling.type === 'BUMPED')
    const svgIcon = bumped?.context?.iconSVG

    if (!bumped || !svgIcon) {
        return null
    }

    return (
        <Box paddingLeft={{ phone: 'm', tablet: '0' }} {...props}>
            <BadgeText icon={svgIcon} color="palette.raccoon" text="Vorgereihte Anzeige" url="/will/infos-zur-reihung" />
        </Box>
    )
}

import React, { FunctionComponent } from 'react'
import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { getAttribute } from '@wh/common/chapter/types/Attributes'
import { prependHttpIfMissing } from '@wh/common/chapter/lib/urlHelpers'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { Heading } from '@wh-components/core/Heading/Heading'
import { Box } from '@wh-components/core/Box/Box'
import { SpaceProps } from '@wh-components/system/space'

export const MoreInfoLinksBox: FunctionComponent<{ advertDetails: AdvertDetails } & SpaceProps> = ({ advertDetails, ...props }) => {
    const moreInfoTexts = getAttribute(advertDetails.attributes.attribute, 'MOREINFO/URLTEXT')
    const moreInfoUrls = getAttribute(advertDetails.attributes.attribute, 'MOREINFO/URL')

    if ((moreInfoTexts?.values?.length ?? 0) === 0 || (moreInfoUrls?.values?.length ?? 0) === 0) {
        return null
    }

    return (
        <Box {...props}>
            <Heading text="Mehr Informationen vom Anbieter" level={4} marginBottom="s" />

            {moreInfoTexts?.values.map((text, i) => (
                <Box key={i}>
                    <ServerRoutingAnchorLink
                        type="anchor"
                        href={prependHttpIfMissing(moreInfoUrls?.values[i] ?? '')}
                        rel="nofollow"
                        target="_blank"
                    >
                        {text}
                    </ServerRoutingAnchorLink>
                </Box>
            ))}
        </Box>
    )
}

import { getAreaShape } from '@bbx/common/api/hereApiClient'
import { ContactDetailAddress, getAdvertContactAddress } from '@bbx/common/types/ad-detail/AdvertContactDetails'
import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { useMapCoordinates } from '@bbx/search-journey/common/lib/useMapCoordinates'
import { verticalIdMap, verticals } from '@wh/common/chapter/types/verticals'
import { useEffect, useState } from 'react'
import { GeoJSONGeometry } from 'wellknown'

type GeoData =
    | {
          type: 'none'
      }
    | {
          type: 'exact'
          coordinates: [number, number]
      }
    | {
          type: 'radius'
          // center
          coordinates: [number, number]
          radius: number
      }
    | {
          type: 'geoJson'
          // focus point
          coordinates: [number, number]
          geoJson: GeoJSONGeometry
      }

export const useGeoData = (advertDetails: AdvertDetails): GeoData => {
    const address = getAdvertContactAddress(advertDetails.advertContactDetails, 'address')
    const isEstate = advertDetails.verticalId === verticalIdMap[verticals.ESTATE]

    const { coordinates, radius, areAccurate } = useMapCoordinates(advertDetails)
    const [geoJSON, setGeoJSON] = useState<GeoJSONGeometry | 'not-found' | null>(address?.postCode ? null : 'not-found')
    const [focusCoordinates, setFocusCoordinates] = useState(coordinates)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        if (loading) {
            return
        }

        setLoading(true)

        // fetching a fallback shape from the here api, only relevant for Estate
        if (!isEstate) {
            return
        }

        const callGetAreaShape = async () => {
            if (address?.postCode) {
                const searchText = buildSearchTextForAddress(address)
                const shapeInfo = await getAreaShape(searchText)
                setGeoJSON(shapeInfo.shape)
                if (!coordinates) {
                    setFocusCoordinates(shapeInfo.shapeFocusPoint ?? undefined)
                }
            } else {
                setGeoJSON('not-found')
            }
        }

        const shouldFetchFallbackShape = !coordinates || (!areAccurate && !radius)

        if (shouldFetchFallbackShape) {
            callGetAreaShape()
        }
    }, [address, advertDetails, areAccurate, coordinates, isEstate, radius, loading])

    if (isEstate) {
        if (coordinates && radius) {
            return {
                type: 'radius',
                coordinates: coordinates,
                radius: radius,
            }
        } else if (coordinates && areAccurate) {
            if (coordinates && areAccurate) {
                return {
                    type: 'exact',
                    coordinates: coordinates,
                }
            }
        } else if (geoJSON) {
            if (geoJSON !== 'not-found') {
                return { type: 'geoJson', coordinates: focusCoordinates!, geoJson: geoJSON }
            } else if (focusCoordinates) {
                return {
                    type: 'radius',
                    coordinates: focusCoordinates,
                    radius: 5000,
                }
            }
        }
    } else {
        // BAP and auto
        if (coordinates && areAccurate) {
            return {
                type: 'exact',
                coordinates: coordinates,
            }
        }
    }

    return { type: 'none' }
}

const buildSearchTextForAddress = (address: ContactDetailAddress): string => {
    const postalName = getFixedPostalName(address)

    return [`${address.postCode} ${postalName}`, address.country].filter(Boolean).join(',')
}

const getFixedPostalName = (address: ContactDetailAddress) => {
    if (address.postCode?.startsWith('1')) {
        // The shapes of Viennese districts are funny when using the full name, e.g. "Wien, 23. Bezirk, Liesing".
        // So we use only 'Wien', except for some districts:
        if (address.postCode === '1010') {
            return 'Wien, Innere Stadt'
        }

        if (address.postCode === '1140') {
            return 'Wien, Penzing'
        }

        if (address.postCode === '1190') {
            // Sadly, I haven't found a search text that will produce a correct shape of this district.
            // The shape of 'Wien, Döbling' will have a hole at Grinzing.
            return 'Wien'
        }

        if (address.postCode === '1220') {
            return 'Wien, Donaustadt'
        }

        if (address.postCode === '1230') {
            return 'Wien, Liesing'
        }

        return 'Wien'
    }

    return address.postalName
}

import { getAttribute } from '@wh/common/chapter/types/Attributes'
import React, { FunctionComponent, useState } from 'react'
import { Text } from '@wh-components/core/Text/Text'
import { getSellerType, SellerType } from '@bbx/common/lib/getSellerType'
import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { isPayliveryAd } from '@bbx/common/lib/isPayliveryAd'
import { SpaceProps } from '@wh-components/system/space'
import { css } from 'styled-components'
import TinyArrowDownIcon from '@wh-components/icons/ArrowtinyDown'
import { ButtonWithLinkStyle } from '@wh-components/core/Button/ButtonWithLinkStyle'

interface LegalDisclaimerInfoBoxProps {
    advertDetails: AdvertDetails
}

export const LegalDisclaimerInfoBox: FunctionComponent<LegalDisclaimerInfoBoxProps & SpaceProps> = ({ advertDetails, ...props }) => {
    const isAnimalAd = getAttribute(advertDetails.attributes.attribute, 'ANIMAL_AD') !== undefined
    const [collapsed, setCollapsed] = useState(true)

    let disclaimerText
    if (isPayliveryAd(advertDetails)) {
        disclaimerText = `Der Kaufvertrag über den Artikel kommt nicht mit willhaben, sondern mit dem Inserenten zustande. Der Inserent ist Verbraucher. Die im Verbraucherschutzrecht der Union verankerten Verbraucherrechte finden auf den Vertrag keine Anwendung. Im Falle der Nutzung von Paylivery umfassen die Leistungen von willhaben die Zurverfügungstellung des Käuferschutzes, die Zugänglichmachung zum Bezahlsystem von ADYEN sowie die Vermittlung des Paketversandes durch befugte Versanddienstleister.`
    } else if (getSellerType(advertDetails.attributes) === SellerType.PRIVATE) {
        disclaimerText = `Der Vertrag kommt nicht mit willhaben zustande. Allfällige aus dem Abschluss des Vertrages resultierende Rechte sind ausschließlich gegenüber dem Vertragspartner geltend zu machen. Der Inserent ist Verbraucher. Die im Verbraucherschutzrecht der Union verankerten Verbraucherrechte finden auf den Vertrag keine Anwendung.`
    } else {
        disclaimerText = `Der Vertrag kommt nicht mit willhaben zustande. Der Inserent ist Unternehmer. Allfällige aus dem Abschluss des Vertrages resultierende Rechte sind ausschließlich gegenüber dem Vertragspartner geltend zu machen.`
    }

    return isAnimalAd ? null : (
        <Text as="div" paddingHorizontal={{ phone: 'm', tablet: 0 }} fontSize="s" color="palette.raccoon" {...props}>
            <ButtonWithLinkStyle
                onClick={() => setCollapsed(!collapsed)}
                color="palette.raccoon"
                fontWeight="bold"
                aria-controls="legalDisclaimer"
                aria-expanded={!collapsed}
            >
                Rechtlicher Hinweis <TinyArrowDownIcon marginLeft="xs" display={collapsed ? 'block' : 'none'} />
            </ButtonWithLinkStyle>
            <Text
                id="legalDisclaimer"
                as="p"
                css={css`
                    display: ${collapsed ? 'none' : 'block'};
                `}
            >
                {disclaimerText}
            </Text>
        </Text>
    )
}

import React, { FunctionComponent } from 'react'
import { FacebookPixel } from '@wh/common/chapter/components/FacebookPixel/FacebookPixel'

interface AdDetailFacebookPixelProps {
    orgId?: number
    adId?: string
}

const pixelId = '696066345990667'
export const FacebookPixelEstateAdDetailViewed: FunctionComponent<AdDetailFacebookPixelProps> = ({ adId, orgId }) => (
    <FacebookPixel id={pixelId} eventName="ViewContent" content_ids={adId} content_category={String(orgId)} content_type="product" />
)

export const FacebookPixelEstateScrollToMessaging: FunctionComponent<AdDetailFacebookPixelProps> = ({ adId, orgId }) => (
    <FacebookPixel id={pixelId} eventName="AddToCart" content_ids={adId} content_category={String(orgId)} content_type="product" />
)

export const FacebookPixelEstateContacted: FunctionComponent<AdDetailFacebookPixelProps & { price: string | undefined }> = ({
    adId,
    orgId,
    price,
}) => (
    <FacebookPixel
        id={pixelId}
        eventName="Purchase"
        content_ids={adId}
        content_category={String(orgId)}
        content_type="product"
        value={price}
        currency="EUR"
    />
)

import React, { FunctionComponent, useContext } from 'react'
import { SpaceProps } from '@wh-components/system/space'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { OpeningHoursContext } from '@bbx/search-journey/sub-domains/ad-detail/components/verticals/auto-motor/OpeningHoursBox/OpeningHoursContext'
import { ButtonWithLinkStyle } from '@wh-components/core/Button/ButtonWithLinkStyle'
import { scrollToElement } from '@wh/common/chapter/lib/commonHelpers'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import ClockIcon from '@wh-components/icons/Searchhistory'

interface NowOpenButtonProps extends SpaceProps {
    taggingData: TaggingData
}

export const NowOpenButton: FunctionComponent<NowOpenButtonProps> = ({ taggingData, ...props }) => {
    const [openingHoursCollapsed, setOpeningHoursCollapsed] = useContext(OpeningHoursContext)

    return (
        <ButtonWithLinkStyle
            fontSize="s"
            fontWeight="semibold"
            color="adStatus.active"
            testId="contact-box-dealer-open-button"
            onClick={() => {
                openingHoursCollapsed && setOpeningHoursCollapsed(false)
                scrollToElement(document.getElementById('ad-detail-opening-times-container'), 'center')
                return callActionEvent('addetail_show_opening_hours_click', taggingData)
            }}
            {...props}
        >
            <ClockIcon color="adStatus.active" size="xsmall" marginRight="xs" />
            Jetzt geöffnet
        </ButtonWithLinkStyle>
    )
}

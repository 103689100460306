import { ContactAdvertiserRequest, ContactSuggestionId } from '@bbx/common/types/Messaging'
import { validationErrorMessages, emailValidationMessage, optionalPhoneNumberValidationMessage } from '@bbx/common/lib/validation'
import { removeUndefined } from '@wh/common/chapter/lib/functionalHelpers'
import { isNonEmpty } from '@wh/common/chapter/lib/validation'

export type SendEmailRequestFormDataMotor = Pick<ContactAdvertiserRequest, 'mailContent' | 'fromFullName' | 'from' | 'copyToSender'> & {
    selectedContactSuggestions: ContactSuggestionId[]
    telephone: string
}

export type SendEmailRequestFormValuesMotor = Omit<SendEmailRequestFormDataMotor, 'selectedContactSuggestions'> & {
    contactSuggestions: string[]
}

export const validateAdContactFormEmailMotor =
    (contactSuggestionsAvailable: boolean) =>
    (values: SendEmailRequestFormValuesMotor): Partial<Record<AdContactFormEmailMotorField, string>> => {
        const hasSelectedContactOption = values.contactSuggestions.length > 0
        const isContactOptionOrMessageFilledOut = hasSelectedContactOption || isNonEmpty(values.mailContent)
        const mailContentEmptyMessage = contactSuggestionsAvailable
            ? 'Bitte Antwortmöglichkeit wählen oder Kommentarfeld nutzen.'
            : 'Bitte das Nachrichtenfeld nutzen.'
        // this is a workaround to just show red borders around checkboxes, but no error message below
        const workaroundEmptyValidationError = ' '
        return removeUndefined({
            selectedContactSuggestions: !isContactOptionOrMessageFilledOut ? workaroundEmptyValidationError : undefined,
            mailContent: !isContactOptionOrMessageFilledOut ? mailContentEmptyMessage : undefined,
            fromFullName: !isNonEmpty(values.fromFullName) ? validationErrorMessages.EMPTY_ERROR : undefined,
            from: emailValidationMessage(values.from),
            telephone: optionalPhoneNumberValidationMessage(values.telephone),
        })
    }

export type AdContactFormEmailMotorField = keyof SendEmailRequestFormValuesMotor
export type AdContactFormEmailMotorFieldToLabelMap = Record<AdContactFormEmailMotorField, string>
export type ContactSuggestionsMotorFieldKeyType = keyof Pick<SendEmailRequestFormValuesMotor, 'contactSuggestions'>
export const contactSuggestionsMotorFieldKey: ContactSuggestionsMotorFieldKeyType = 'contactSuggestions'

export const adContactFormEmailMotorFieldToLabelMap: Record<AdContactFormEmailMotorField, string> = {
    contactSuggestions: 'Das will ich über das Fahrzeug wissen:',
    mailContent: '',
    fromFullName: 'Name',
    from: 'E-Mail-Adresse',
    telephone: 'Telefonnummer',
    copyToSender: 'Kopie dieser Anfrage an mich senden.',
}

export const adContactFormEmailMotorFieldRequiredness = (
    _values: SendEmailRequestFormValuesMotor,
): Record<AdContactFormEmailMotorField, boolean> => {
    return {
        contactSuggestions: false,
        mailContent: false,
        fromFullName: true,
        from: true,
        telephone: false,
        copyToSender: false,
    }
}

export const emailDataFromMotorFormValues = (values: SendEmailRequestFormValuesMotor): SendEmailRequestFormDataMotor => {
    const { contactSuggestions, ...remainingValues } = values
    const selectedContactSuggestions = contactSuggestions.map(Number)
    return { ...remainingValues, selectedContactSuggestions }
}

import { Box } from '@wh-components/core/Box/Box'
import React, { FunctionComponent, RefObject } from 'react'
import { AdvertSummarySkeletons } from '@bbx/search-journey/common/components/AdvertSummary/AdvertSummarySkeleton'
import { Divider } from '@wh-components/core/Divider/Divider'
import { HorizontalSlider, SliderAdImageVariant } from '@wh/common/chapter/components/HorizontalSlider/HorizontalSlider'
import { css } from 'styled-components'
import { Heading } from '@wh-components/core/Heading/Heading'

export interface SimilarAdsLayoutProps {
    advertSummaries: JSX.Element[] | undefined
    isLoading: boolean
    visibilityTrackingRef: RefObject<HTMLDivElement>
    heading: string
    moreAdsLink?: string
    onClickShowAllTagging: () => void
    onClickShowAllButtonTagging: () => void
    onArrowButtonTagging?: () => void
    variant?: SliderAdImageVariant
}

export const SimilarAdsLayout: FunctionComponent<SimilarAdsLayoutProps> = ({
    advertSummaries,
    isLoading,
    visibilityTrackingRef,
    heading,
    moreAdsLink,
    onClickShowAllTagging,
    onClickShowAllButtonTagging,
    onArrowButtonTagging,
    variant = 'landscape',
}) => {
    if (!isLoading && !advertSummaries?.length) {
        return null
    }

    return (
        <Box testId="ad-detail-similar-ads" ref={visibilityTrackingRef} paddingLeft={{ phone: 'm', tablet: 0 }}>
            {isLoading ? (
                <AdvertSummarySkeletons marginBottom="l" variant={variant} />
            ) : (
                <HorizontalSlider
                    heading={<Heading level={2} text={heading} fontSize={{ phone: 'l', tablet: 'xl' }} />}
                    linkText="Mehr anzeigen"
                    buttonText="Mehr anzeigen"
                    onClick={onClickShowAllTagging}
                    onClickButton={onClickShowAllButtonTagging}
                    onArrowBtnClick={onArrowButtonTagging}
                    href={moreAdsLink}
                    testIdPrefix="ad-detail-similar-ads"
                    marginBottom="l"
                    css={css`
                        ${(p) => p.theme.media.only.tablet} {
                            margin-right: -${(p) => p.theme.space.m}px;
                        }
                    `}
                    variant={variant}
                >
                    {advertSummaries?.map((advertSummary) => advertSummary) ?? []}
                </HorizontalSlider>
            )}
            <Divider />
        </Box>
    )
}

import React, { FunctionComponent, PropsWithChildren, SyntheticEvent } from 'react'
import { css } from 'styled-components'

export const ContentClickBlocker: FunctionComponent<
    PropsWithChildren<{ block: boolean; onClickOnBlockingDiv: (event: SyntheticEvent) => void }>
> = (props) => (
    <div onClick={props.block ? props.onClickOnBlockingDiv : undefined}>
        <div
            css={
                props.block
                    ? css`
                          pointer-events: none;
                      `
                    : undefined
            }
        >
            {props.children}
        </div>
    </div>
)

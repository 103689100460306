import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { ContextLink } from '@bbx/common/types/contextLinks'
import { getBbxCookiesFromRequest } from '@wh/common/chapter/types/cookies'
import { getHeaderValue } from '@wh/common/chapter/types/headers'
import { NextRequest } from '@wh/common/chapter/types/nextJS'
import { AdReportReasonList } from '@bbx/common/types/ad-detail/AdReportReasonList'
import { ReportResponse } from '@wh/common/chapter/types/ReportResponse'
import { fetcher } from '@wh/common/chapter/api/fetcher'
import { ReportFormSubmitValues } from '@wh/common/chapter/components/ReportForm/ReportForm'

export const getFreshAdDetails = async (adId: number, request?: NextRequest): Promise<AdvertDetails> => {
    return getAdDetailsInternal(adId, true, request)
}

export const getAdDetails = async (adId: number, request?: NextRequest): Promise<AdvertDetails> => {
    return getAdDetailsInternal(adId, false, request)
}

const getAdDetailsInternal = async (adId: number, noCache: boolean, request?: NextRequest): Promise<AdvertDetails> => {
    const xForwardedFor = getHeaderValue(request, 'X-Forwarded-For')
    const headers = {
        ...(noCache && { 'Cache-Control': 'no-cache' }),
        ...(xForwardedFor && { 'X-Forwarded-For': xForwardedFor }),
    }

    return await fetcher<AdvertDetails>(`/iad/atverz/${adId}?formatEnableHtmlTags=true`, {
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
        headers: headers,
    })
}

export const getAdSimilarAdsOrCategory = (adId: number): Promise<ContextLink | undefined> => {
    return fetcher<ContextLink | undefined>(`/iad/atverz/${adId}/category`, {
        credentials: 'include',
    })
}

export const getNcAdDetailUrl = (externalAdId: string): Promise<string | undefined> => {
    return fetcher<string | undefined>(
        `/iad/new-constructions/seo-url/external/${externalAdId}`,
        {
            credentials: 'include',
        },
        (response) => response.text(),
    )
}

export const getOpenImmoAdDetailUrl = (openImmoObId: string, openImmoAnId: string): Promise<string | undefined> => {
    return fetcher<string | undefined>(
        `/iad/open-immo/seo-url?${new URLSearchParams({
            openImmoObId: openImmoObId,
            openImmoAnId: openImmoAnId,
        })}`,
        {
            credentials: 'include',
        },
        (response) => response.text(),
    )
}

export const reportAd = (adId: string, reason: string, emailAddress: string, message: string): Promise<void> => {
    return fetcher<void>(`/iad/reportad/report`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
            adId,
            reason,
            emailAddress,
            message,
        }),
    })
}

export const getDsaReportingReasons = (adId: number): Promise<AdReportReasonList | undefined> => {
    return fetcher<AdReportReasonList | undefined>(`/iad/dsa/reportad/${adId}/reasons`, {
        credentials: 'include',
    })
}

export const dsaReportAd = (adId: string, reportFormSubmitValues: ReportFormSubmitValues) => {
    return fetcher<ReportResponse>(`/iad/dsa/reportad/${adId}/report`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(reportFormSubmitValues),
    })
}

import React, { Fragment, FunctionComponent } from 'react'
import { useResponsiveValue } from '@wh-components/core/utilities/responsive'
import { Tooltip } from '@wh-components/core/Tooltip/Tooltip'
import { Text } from '@wh-components/core/Text/Text'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { scrollToElement } from '@wh/common/chapter/lib/commonHelpers'
import { Box } from '@wh-components/core/Box/Box'
import TooltipQuestionmark from '@wh-components/icons/TooltipQuestionmark'
import { css } from 'styled-components'

export const RentTotalLoadTooltip: FunctionComponent = () => {
    const offset = useResponsiveValue({ phone: 60, tablet: 100 }, 100)

    return (
        <Tooltip
            interactive={true}
            content={
                <Fragment>
                    <Text as="p">
                        Unter <b>Gesamtbelastung</b> versteht man die tatsächliche monatliche finanzielle Belastung, die für eine:n
                        Mieter:in beim Anmieten einer Immobilie entsteht. Dabei werden u.a. folgende Kosten berücksichtigt:{' '}
                        <b>Miete, Betriebskosten, Heizkosten, sonstige Kosten</b> sowie die <b>Mehrwertsteuer</b>,{' '}
                        <b>sofern diese direkt an den/die Vermieter:in abgeführt werden.</b>
                    </Text>
                    <Text as="p">
                        Mehr Informationen zu den einzelnen Kosten findest du unter&nbsp;
                        <ServerRoutingAnchorLink
                            type="anchor"
                            target="_blank"
                            onClick={() => {
                                scrollToElement(document.getElementById('price-information-box'), 'start', 'if-needed', offset)
                            }}
                        >
                            Preisinformation
                        </ServerRoutingAnchorLink>
                        .
                    </Text>
                </Fragment>
            }
        >
            <Box
                testId="totalLoadTooltip"
                display="inline-flex"
                marginLeft="xs"
                css={css`
                    vertical-align: middle;
                `}
            >
                <TooltipQuestionmark color="palette.raccoon" size="xsmall" aria-hidden={true} />
            </Box>
        </Tooltip>
    )
}

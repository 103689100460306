import React, { FunctionComponent } from 'react'
import { useDidomi } from '@wh/common/chapter/components/Didomi/useDidomi'
import { concatPathWithQueryParams } from '../../lib/urlHelpers'
import { removeUndefined } from '../../lib/functionalHelpers'

interface FacebookPixelProps {
    id: string
    eventName: string
    content_category?: string
    content_type?: string
    content_name?: string
    content_ids?: string
    value?: string
    currency?: string
}

export const FacebookPixel: FunctionComponent<FacebookPixelProps> = ({
    id,
    eventName,
    content_category,
    content_type,
    content_name,
    content_ids,
    value,
    currency,
}) => {
    const { isConsentGiven } = useDidomi('FacebookTrackingPixel')

    return isConsentGiven ? (
        <img
            src={concatPathWithQueryParams(
                'https://www.facebook.com/tr',
                removeUndefined({
                    id: id,
                    ev: eventName,
                    'cd[content_category]': content_category,
                    'cd[content_type]': content_type,
                    'cd[content_name]': content_name,
                    'cd[content_ids]': content_ids,
                    'cd[value]': value,
                    'cd[currency]': currency,
                }),
            )}
            height="1"
            width="1"
            style={{ display: 'none' }}
        />
    ) : null
}
